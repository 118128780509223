import React, { useState, useEffect } from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";

const COLORS = ["rgba(255,99,99)", "#ffd634", "rgba(67, 160, 71)"]; // Colors for failure, missing, and success

const CustomPieChart = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const onPieClick = (_, index) => {
    setActiveIndex(index);
    const clickedData = data[index];
    alert(`You clicked on ${clickedData.name}: ${clickedData.value} items`);
    // Here you can handle the display of items based on the clickedData
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text x={x} y={y} fill="black" textAnchor={x > cx ? "start" : "end"}>
        {data[index]?.value}
      </text>
    );
  };

  return (
    <PieChart width={300} height={200}>
      <Pie
        activeIndex={activeIndex}
        data={data}
        dataKey="value"
        onMouseEnter={onPieEnter}
        onClick={onPieClick}
        label={renderCustomizedLabel}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default CustomPieChart;
