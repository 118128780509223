import {
  Badge,
  Card,
  Col,
  ListGroup,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import BackupSummaryChart from "./BackupSummaryChart";
import useActivityLogger from "hooks/useActivity";
import Loader from "components/Loader";
import CustomPieChart from "components/Charts/CustomPieChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const BackupSummary = ({ isDashboard = false, navigateToPage }) => {
  const { summary, loading } = useSelector((state) => state.backup);
  const logActivity = useActivityLogger();
  const badges = {
    failed: "danger",
    success: "success",
    missing: "warning",
  };

  const getDeviceTypeCount = (data) => {
    const counts = {};
    const uniqueServers = data?.reduce((acc, current) => {
      const x = acc.find((item) => item.serverName === current.serverName);
      if (!x) {
        acc.push(current);
      }
      return acc;
    }, []);

    uniqueServers?.forEach((item) => {
      if (!counts[item.type]) {
        counts[item.type] = 0;
      }
      counts[item.type]++;
    });
    return counts;
  };

  const sendLog = (type) => {
    logActivity({
      page: "dashboard",
      activity: "backup-type-click",
      name: type,
    });
  };

  const renderBackupData = (fieldText, fieldVal, data) => {
    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom"
        overlay={
          <Popover
            id="popover-trigger-click-root-close"
            title="Popover"
            style={{ minWidth: "60vw", padding: "15px" }}
          >
            <div className="mb-3">
              <h5>
                <b>{fieldText} Summary</b>
              </h5>
              <small className="sub-info">List of all failed backups</small>
            </div>
            <Row className="mb-3">
              {Object.entries(getDeviceTypeCount(data))?.map(([key, val]) => (
                <Col key={`${key}_${val}`}>
                  <Card className={`${fieldText.toLowerCase()} bg`}>
                    <Card.Body>
                      <h6 className="capitalize">{key}</h6>
                      <h2>
                        <b>
                          <Card.Text>{val}</Card.Text>
                        </b>
                      </h2>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>

            <PaginatedTable
              data={data}
              columns={COLUMNS["backupSummary"]}
              perPage={15}
            />
          </Popover>
        }
      >
        <ListGroup.Item
          className="mb-1 hover-item"
          style={{ cursor: "pointer" }}
          onClick={() => sendLog(fieldText)}
        >
          <span className="link" style={{ textTransform: "capitalize" }}>
            <b>{fieldText}</b>
          </span>

          <b style={{ fontSize: "0.9rem" }}>
            {loading["fetchBackupSummary"] ? (
              <Loader />
            ) : (
              <Badge bg={badges[fieldText.toLowerCase()]}>{fieldVal}</Badge>
            )}
          </b>
        </ListGroup.Item>
      </OverlayTrigger>
    );
    // ));
  };

  const createPieChartData = (data) => {
    return [
      { name: "Failure", value: data?.failureCount, key: "failureCount" },
      { name: "Missing", value: data?.missingCount, key: "missingCount" },
      { name: "Success", value: data?.successCount, key: "successCount" },
    ];
  };

  const TitleComponent = () => (
    <Row
      onClick={() =>
        isDashboard && navigateToPage("/backups", "backup-audit-report")
      }
      className="mb-3"
    >
      <Col>
        <div className="mt-1">
          <h5>
            <b>Backup Summary</b>
          </h5>
          <small className="sub-info">Check latest report</small>
        </div>
      </Col>
      {isDashboard && (
        <Col sm={2} style={{ fontSize: "2rem", cursor: "pointer" }}>
          <FontAwesomeIcon icon={faArrowRight} />
        </Col>
      )}
    </Row>
  );

  return (
    <>
      {!isDashboard && <TitleComponent />}
      <Row>
        <Col md={isDashboard ? 12 : 3}>
          <Card className="">
            <Card.Body>
              {isDashboard && <TitleComponent />}
              {!isDashboard && (
                <div className="mt-2 mb-3">
                  <h6>
                    <b>Today</b>
                  </h6>
                  <small className="sub-info">Todays failed backups</small>
                </div>
              )}
              <div>
                <center>
                  <CustomPieChart data={createPieChartData(summary?.today)} />
                </center>{" "}
              </div>

              {summary && (
                <ListGroup variant="flush">
                  {renderBackupData(
                    "Failed",
                    summary?.today?.failureCount,
                    summary?.today?.failures
                  )}
                  {renderBackupData(
                    "Missing",
                    summary?.today?.missingCount,
                    summary?.today?.missing
                  )}
                  {renderBackupData(
                    "Success",
                    summary?.today?.successCount,
                    summary?.today?.successes
                  )}
                </ListGroup>
              )}
            </Card.Body>
          </Card>
        </Col>
        {!isDashboard && (
          <Col md={3}>
            <Card>
              <Card.Body>
                <div className="mt-2 mb-3">
                  <h6>
                    <b>Yesterday</b>
                  </h6>
                  <small className="sub-info">Yesterdays failed backups</small>
                </div>
                <div>
                  <center>
                    <CustomPieChart
                      data={createPieChartData(summary?.yesterday)}
                    />
                  </center>{" "}
                </div>
                {summary && (
                  <ListGroup variant="flush">
                    {renderBackupData(
                      "Failed",
                      summary?.yesterday?.failureCount,
                      summary?.yesterday?.failures
                    )}
                    {renderBackupData(
                      "Missing",
                      summary?.yesterday?.missingCount,
                      summary?.yesterday?.missing
                    )}
                    {renderBackupData(
                      "Success",
                      summary?.yesterday?.successCount,
                      summary?.yesterday?.successes
                    )}
                  </ListGroup>
                )}
              </Card.Body>
            </Card>
          </Col>
        )}
        {!isDashboard && (
          <Col>
            <div>
              {loading["fetchBackupSummary"] ? (
                <div className="p-4">
                  <Loader />
                </div>
              ) : (
                <Card>
                  <Card.Body>
                    <BackupSummaryChart />
                  </Card.Body>
                </Card>
              )}
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};

export default BackupSummary;
