import React, { useEffect, useState } from "react";
import { Form, Button, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import useActivityLogger from "hooks/useActivity";
import { onboardConcerto } from "stores/actions";

const ONBOARD_INIT = {
  name: "",
  url: "",
  username: "",
  password: "",
};

const InputField = ({ label, type, name, value, handleChange }) => (
  <Form.Group controlId={name}>
    <Form.Label>{label}</Form.Label>
    <Form.Control
      type={type}
      name={name}
      value={value}
      onChange={handleChange}
      required
    />
  </Form.Group>
);

const OnboardConcerto = ({ showModal, closeModal }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(ONBOARD_INIT);
  const { loading } = useSelector((state) => state.orchestrators);
  const logActivity = useActivityLogger();

  const sendLog = (page, activity, name) => {
    logActivity({ page, activity, name });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    sendLog("Orchestrators", "onboard-concerto", formData?.name);
    try {
      const res = await dispatch(onboardConcerto(formData));
      if (res.status === 200) {
        setFormData(ONBOARD_INIT);
        closeModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal show={showModal} onHide={closeModal} size="sm">
      <Modal.Header closeButton>
        <Modal.Title>Onboard Concerto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {Object.keys(ONBOARD_INIT).map((field) => (
            <InputField
              key={field}
              label={field.charAt(0).toUpperCase() + field.slice(1)}
              type={field === "password" ? "password" : "text"}
              name={field}
              value={formData[field] || ""}
              handleChange={handleInputChange}
            />
          ))}

          <Button
            className="w-100 mb-3 mt-3 p-2"
            variant="primary"
            disabled={loading?.onboardConcerto}
            type="submit"
          >
            {loading?.onboardConcerto ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {"  "}
                Onboarding...
              </>
            ) : (
              "Onboard"
            )}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default OnboardConcerto;
