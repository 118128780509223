// pages/Unauthorized.js
import useActivityLogger from "hooks/useActivity";
import React, { useEffect } from "react";
import { Card } from "react-bootstrap";

const Unauthorized = () => {
  const logActivity = useActivityLogger();

  useEffect(() => {
    // logActivity({
    //   page: "unauthorised-access",
    //   activity: "access-unathorised-page",
    // });
  }, []);
  return (
    <div
      className="bg-image"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Card className="p-4 w-50">
        <Card.Body>
          <h1>Unauthorized</h1>
          <p>You do not have permission to view this page.</p>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Unauthorized;
