// src/services/ws.js
const WS_SERVER = `${window?.appConfig?.REACT_APP_WS_SERVER}`;

class WebSocketService {
  static instance = null;
  callbacks = {};
  room = null;
  reconnectInterval = 5000;
  url = WS_SERVER;

  static getInstance() {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  connect() {
    if (!this.ws || this.ws.readyState === WebSocket.CLOSED) {
      this.ws = new WebSocket(this.url);

      this.ws.onopen = () => {
        console.log("WebSocket connection opened");
      };

      this.ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log("WS", data);
        if (this.callbacks[data.type]) {
          this.callbacks[data.type](data);
        }
      };

      this.ws.onclose = () => {
        console.log("WebSocket connection closed");
        setTimeout(() => {
          this.connect(this.url);
        }, this.reconnectInterval);
      };
    }
  }

  joinRoom(room) {
    this.room = room;
    this.sendMessage({ type: "join", room });
  }

  leaveRoom() {
    if (this.room) {
      this.sendMessage({ type: "leave", room: this.room });
      this.room = null;
    }
  }

  sendMessage(message) {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify(message));
    }
  }

  registerCallback(type, callback) {
    this.callbacks[type] = callback;
  }
}

export default WebSocketService.getInstance();
