import { useMemo } from "react";
import { Card, Col, ListGroup, Row } from "react-bootstrap";
import capitalize from "utils/capitalize";
import maskPassword from "utils/maskPassword";

const HostView = ({ host }) => {
  const isDirector = useMemo(() => {
    const categoryName = host?.category?.name || host?.category;
    return categoryName?.toLowerCase() === "director";
  }, [host]);

  return (
    <>
      <Row className="mb-3">
        <Col md={6}>
          <Card className="mb-3 ">
            <Row>
              <Col md={4}>
                <Card.Body>
                  <Card.Title>
                    <b>Host Info</b>
                  </Card.Title>
                  <Card.Text></Card.Text>
                </Card.Body>
              </Col>
              <Col md={8}>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <span>Headend</span>

                    <b>{host?.headendName}</b>
                  </ListGroup.Item>
                  {host?.analyticName && (
                    <ListGroup.Item>
                      <span>Cluster</span>

                      <b>{host?.analyticName}</b>
                    </ListGroup.Item>
                  )}
                  <ListGroup.Item>
                    <span>Category</span>

                    <b>{host?.category?.name}</b>
                  </ListGroup.Item>
                  {host?.fqdn && (
                    <ListGroup.Item>
                      <span>FQDN</span>

                      <b>{host?.fqdn}</b>
                    </ListGroup.Item>
                  )}
                  {host?.ipAddress && (
                    <ListGroup.Item>
                      <span>IP</span>

                      <b>{host?.ipAddress}</b>
                    </ListGroup.Item>
                  )}
                  <ListGroup.Item>
                    <span>Owner Org</span>

                    <b>{host?.ownerOrgName}</b>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span>Location </span>
                    <b>{host?.location}</b>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span>Region </span>
                    <b>{host?.region}</b>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </Card>
          {host?.guiCredentials?.length > 0 && (
            <Card className="mb-3 ">
              <Card.Body>
                <Card.Title>
                  <b>GUI Credentials</b>
                </Card.Title>
                <Card.Text>GUI credentials to logon to the device</Card.Text>
                <Row>
                  {host?.guiCredentials?.map((credentials, credIndex) =>
                    Object.entries(credentials).map(
                      ([key, value], index) =>
                        key !== "_id" && (
                          <Col key={`${credIndex}-${index}`}>
                            <span className="capitalize">{key}</span>
                            <br />{" "}
                            <b>
                              {key === "password" ? maskPassword(value) : value}
                            </b>
                          </Col>
                        )
                    )
                  )}
                </Row>
              </Card.Body>
            </Card>
          )}
        </Col>
        <Col md={6}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>
                <b>IPs</b>
              </Card.Title>
              <Card.Text>All IPs related to device will be displayed</Card.Text>
              <Row className="details mb-2">
                {host?.ips?.map((credentials, credIndex) =>
                  Object.entries(credentials).map(
                    ([key, value], index) =>
                      key !== "_id" && (
                        <Col key={`${credIndex}-${index}`}>
                          <ListGroup.Item>
                            <span className="capitalize">
                              {key.replace("_", " ")}
                            </span>
                            :{"  "}
                            <b>{value}</b>
                          </ListGroup.Item>
                        </Col>
                      )
                  )
                )}
              </Row>

              {host?.wanIps?.map((credentials, credIndex) => (
                <Row className="details">
                  {Object.entries(credentials).map(
                    ([key, value], index) =>
                      key !== "_id" && (
                        <Col key={`${credIndex}-${index}`}>
                          <ListGroup.Item>
                            <span className="capitalize">
                              {key.replace("_", " ")}
                            </span>
                            :{"  "}
                            <b>{value}</b>
                          </ListGroup.Item>
                        </Col>
                      )
                  )}
                </Row>
              ))}
            </Card.Body>
          </Card>
          <Card className="mb-3 ">
            <Card.Body>
              <Card.Title>
                <b>SSH Credentials</b>
              </Card.Title>
              <Card.Text>SSH credentials to logon to the device</Card.Text>
              <Row>
                {host?.sshCredentials?.map((credentials, credIndex) =>
                  Object.entries(credentials).map(
                    ([key, value], index) =>
                      key !== "_id" && (
                        <Col key={`${credIndex}-${index}`}>
                          <span className="capitalize">{key}</span>
                          <br />{" "}
                          <b>
                            {key === "password" ? maskPassword(value) : value}
                          </b>
                        </Col>
                      )
                  )
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} sm={12}>
          <Card>
            <Card.Body>
              <Card.Title>
                <b>License Details</b>
              </Card.Title>
              <Card.Text>
                Information about the solution tiers added to the device
              </Card.Text>
            </Card.Body>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <span>Solution Tier</span>

                <b>{host?.license?.solutionTier}</b>
              </ListGroup.Item>
              <ListGroup.Item>
                <span>Bandwidth</span>

                <b>{host?.license?.bandwidth}</b>
              </ListGroup.Item>
              <ListGroup.Item>
                <span>Start Date</span>

                <b>{host?.license?.startDate}</b>
              </ListGroup.Item>
              <ListGroup.Item>
                {" "}
                <span>End Date</span>
                <b>{host?.license?.endDate}</b>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>

        <Col md={isDirector ? 4 : 8} sm={12} lg={isDirector ? 4 : 8}>
          <Card>
            <Row>
              <Col>
                <Card.Body>
                  <Card.Title>
                    <b>Other Details</b>
                  </Card.Title>
                  <Card.Text>
                    Information about the device's softwares and hardwares
                  </Card.Text>
                </Card.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <span>CPU </span>
                    <b>{host?.hardware?.cpu || host?.cpu}</b>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span> No of CPUs </span>
                    <b>{host?.hardware?.cpuCount || host?.no_of_cpu}</b>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span>Memory </span>
                    <b>{host?.hardware?.memory || host?.memory}</b>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span>Hard Disk </span>
                    <b>{host?.hardware?.disk || host?.disk}</b>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              {!isDirector && (
                <Col>
                  <ListGroup variant="flush">
                    <>
                      <ListGroup.Item>
                        <span>Software Version </span>
                        <b>{host?.softwareVersion}</b>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <span>OSS Pack Version </span>
                        <b>{host?.osspackVersion}</b>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <span>SPack Version </span>
                        <b>{host?.spackVersion}</b>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <span>Serial No </span>
                        <b>{host?.hardware?.serialNo}</b>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <span>Model </span>
                        <b>{host?.hardware?.model}</b>
                      </ListGroup.Item>
                    </>
                  </ListGroup>
                </Col>
              )}
            </Row>
          </Card>
        </Col>
        {isDirector && (
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>
                  <b>Package Details</b>
                </Card.Title>
                <Card.Text>
                  Information about the device's softwares and hardwares
                </Card.Text>
              </Card.Body>
              <ListGroup variant="flush">
                {Object.entries(host?.packageInfo).map(([key, value]) => (
                  <ListGroup.Item key={key}>
                    <span>{capitalize(key)}</span>
                    <b>{value}</b>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};

export default HostView;
