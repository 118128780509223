import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Row, Tab, Tabs } from "react-bootstrap";
import { useBreadcrumbs } from "contexts";
import UserManagement from "./Tabs/Users";
import RoleManagement from "./Tabs/Roles";
import { fetchUsers } from "stores/actions";
import PermissionManagement from "./Tabs/Permission";
import Pages from "./Tabs/Pages";
import { usePermissions } from "hooks/usePermission";
import Activities from "pages/Activities";
import Categories from "pages/Categories";

const Manage = () => {
  const dispatch = useDispatch();
  const [key, setKey] = useState(localStorage.getItem("manageTab") || "users");

  const { updateBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    dispatch(fetchUsers());
    updateBreadcrumbs([{ text: "Home", url: "/dashboard" }]);
  }, []);

  const setTabKey = (selectedKey) => {
    localStorage.setItem("manageTab", selectedKey);
    setKey(selectedKey);
  };

  return (
    <>
      <div className="middle-section">
        <div className="my-2">
          <h5>
            <b>Manage Users & Roles</b>
          </h5>
          <small className="sub-info">
            Manage all your users, their roles and permissions
          </small>
        </div>
        <Row className="manage-tabs my-2">
          <Tabs
            id="controlled-tabs"
            activeKey={key}
            onSelect={setTabKey}
            className="mb-3  mr-3 p-0"
            // as={Card}
          >
            {usePermissions(["USERS_VIEW"]) && (
              <Tab eventKey="users" title="Users">
                <UserManagement />
              </Tab>
            )}
            {usePermissions(["ROLES_VIEW"]) && (
              <Tab eventKey="roles" title="Roles">
                <RoleManagement />
              </Tab>
            )}
            {usePermissions(["PAGES_VIEW"]) && (
              <Tab eventKey="pages" title="Pages">
                <Pages />
              </Tab>
            )}
            {usePermissions(["PERMISSIONS_VIEW"]) && (
              <Tab eventKey="permissions" title="Permissions">
                <PermissionManagement />
              </Tab>
            )}
            {usePermissions(["ACTIVITIES_VIEW"]) && (
              <Tab eventKey="activities" title="Activities">
                <Activities />
              </Tab>
            )}
            {usePermissions(["CATEGORIES_VIEW"]) && (
              <Tab eventKey="categories" title="Categories">
                <Categories />
              </Tab>
            )}
          </Tabs>
        </Row>
      </div>
    </>
  );
};

export default Manage;
