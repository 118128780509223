import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "services/api";
import { showAlert } from "../slices/alertSlice";
import MESSAGES from "../../constants/messages";
import React from "react";

const fetchOrchestrators = createAsyncThunk(
  "Orchestrators/fetchOrchestrators",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/orchestrators`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchOrchestratorsById = createAsyncThunk(
  "Orchestrators/fetchOrchestratorsById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await API.get(`/orchestrators/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const saveOrchestrators = createAsyncThunk(
  "Orchestrators/saveOrchestrators",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`/orchestrators`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const onboardConcerto = createAsyncThunk(
  "Orchestrators/onboardConcerto",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`/orchestrators/onboard-concerto`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const updateOrchestrators = createAsyncThunk(
  "Orchestrators/updateOrchestrators",
  async (orchestrator, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.put(
        `/orchestrators/${orchestrator._id}`,
        orchestrator
      );
      dispatch(
        showAlert({
          message: MESSAGES["orchestrator-update-sc"],
          variant: "success",
        })
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const deleteOrchestrators = createAsyncThunk(
  "Orchestrators/deleteOrchestrators",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await API.get(`/orchestrators/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export {
  fetchOrchestrators,
  saveOrchestrators,
  deleteOrchestrators,
  updateOrchestrators,
  fetchOrchestratorsById,
  onboardConcerto,
};
