import {
  faCheckCircle,
  faCircleXmark,
  faClose,
  faDownload,
  faEdit,
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useMemo, useEffect } from "react";
import {
  Table,
  Pagination,
  Form,
  Col,
  Row,
  Dropdown,
  DropdownButton,
  Button,
  Badge,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { downloadCSV, downloadPDF, downloadText } from "utils/download";
import CopyToClipboard from "./CopyToClipboard";

// CSS for resizing columns
const resizableTableStyles = {
  resizable: {
    position: "relative",
    overflow: "hidden",
  },
  resizer: {
    width: "5px",
    height: "100%",
    position: "absolute",
    top: "0",
    right: "0",
    cursor: "col-resize",
    userSelect: "none",
  },
};

const PaginatedTable = ({
  data = [],
  columns,
  perPage,
  onView,
  onEdit,
  onDelete,
  isLoading,
  paginationRequired = true,
  searchRequired = true,
  filterRequired = true,
  bulkActionsRequired = false,
  downloadRequired = false,
  selectedItems,
  setSelectedItems,
  fileName,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(perPage || 10);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [filters, setFilters] = useState({});
  const [columnWidths, setColumnWidths] = useState([]); // Empty initially
  const [hoveredCell, setHoveredCell] = useState(null); // Track which cell is hovered

  useEffect(() => {
    // Calculate initial column widths based on data and header
    const calculateColumnWidths = () => {
      const tableWidth =
        document.querySelector(".custom-table")?.offsetWidth || 1000;
      const defaultColumnWidth = tableWidth / columns.length;

      const widths = columns.map((column) => {
        // Calculate width based on header text length
        let maxWidth = column.text.length * 8; // Assuming ~8px per character in the header

        // Calculate width based on the longest data in the column
        data?.forEach((item) => {
          const cellLength = String(item[column.dataField] || "").length * 8; // Assuming ~8px per character in the cell
          if (cellLength > maxWidth) {
            maxWidth = cellLength;
          }
        });

        return Math.max(maxWidth + 20, defaultColumnWidth); // Add padding and ensure min width
      });

      setColumnWidths(widths);
    };

    calculateColumnWidths();
  }, [columns, data]);

  // Column Resizing Logic
  const startResizing = (index, e) => {
    const startX = e.clientX;
    const startWidth = columnWidths[index];

    const onMouseMove = (moveEvent) => {
      const newWidth = startWidth + moveEvent.clientX - startX;
      setColumnWidths((prevWidths) => {
        const newWidths = [...prevWidths];
        newWidths[index] = newWidth > 50 ? newWidth : 50; // Min width of 50px
        return newWidths;
      });
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleSort = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
  };

  const handleFilter = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
    setCurrentPage(1);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedItems(data.map((item) => item.id));
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectItem = (event, id) => {
    if (event.target.checked) {
      setSelectedItems((prevSelected) => [...prevSelected, id]);
    } else {
      setSelectedItems((prevSelected) =>
        prevSelected.filter((itemId) => itemId !== id)
      );
    }
  };

  const sortedData = useMemo(() => {
    let sortableData = [...data];

    if (sortField) {
      sortableData.sort((a, b) => {
        const aValue = a[sortField];
        const bValue = b[sortField];

        // Handling sorting for numbers
        if (!isNaN(aValue) && !isNaN(bValue)) {
          return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
        }

        // Handling sorting for strings
        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortOrder === "asc"
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        }

        // Handling sorting for dates
        if (
          new Date(aValue) !== "Invalid Date" &&
          new Date(bValue) !== "Invalid Date"
        ) {
          return sortOrder === "asc"
            ? new Date(aValue) - new Date(bValue)
            : new Date(bValue) - new Date(aValue);
        }

        // Fallback sorting
        return 0;
      });
    }

    return sortableData;
  }, [data, sortField, sortOrder]);

  const filteredData = useMemo(() => {
    return sortedData?.filter((item) => {
      return Object.keys(filters).every((key) => {
        if (!filters[key]) return true;
        return item[key]
          ?.toString()
          .toLowerCase()
          .includes(filters[key].toLowerCase());
      });
    });
  }, [sortedData, filters]);

  const searchedData = useMemo(() => {
    return filteredData?.filter((item) =>
      columns.some((column) =>
        item[column.dataField]
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );
  }, [filteredData, searchTerm, columns]);

  const totalPages = Math.ceil(searchedData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = searchedData.slice(startIndex, endIndex);

  const renderPaginationItems = () => {
    const maxPageButtons = 3;
    let items = [];
    let startPage, endPage;

    if (totalPages <= maxPageButtons) {
      startPage = 1;
      endPage = totalPages;
    } else {
      startPage = Math.max(1, currentPage - 2);
      endPage = Math.min(totalPages, currentPage + 2);

      if (currentPage <= 3) {
        endPage = maxPageButtons;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - (maxPageButtons - 1);
      }
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => setCurrentPage(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    if (startPage > 1) {
      items.unshift(<Pagination.Ellipsis key="start-ellipsis" />);
      items.unshift(
        <Pagination.Item key={1} onClick={() => setCurrentPage(1)}>
          1
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      items.push(<Pagination.Ellipsis key="end-ellipsis" />);
      items.push(
        <Pagination.Item
          key={totalPages}
          onClick={() => setCurrentPage(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return items;
  };

  const defineColumn = (item, field, link) => {
    const fieldVal = item[field];
    if (field === "name" && onView)
      return (
        <span className="link" onClick={() => onView(item)}>
          {fieldVal}
        </span>
      );
    else if (link) {
      return (
        <a href={fieldVal} target="_blank" className="link">
          {fieldVal}
        </a>
      );
    } else {
      const val = fieldVal ? String(fieldVal)?.toLowerCase() : fieldVal;
      switch (val) {
        case "completed":
        case "success":
        case "enabled":
          return <FontAwesomeIcon className="cl green" icon={faCheckCircle} />;

        case "failed":
        case "not-enabled":
          return <FontAwesomeIcon className="cl red" icon={faCircleXmark} />;

        case "missing":
          return <FontAwesomeIcon className="cl yellow" icon={faCircleXmark} />;

        case "mismatch":
          return <FontAwesomeIcon className="cl orange" icon={faCircleXmark} />;
        default:
          return fieldVal;
      }
    }
  };

  const handleRemoveFilter = (field) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      delete updatedFilters[field]; // Remove the specific filter by key
      return updatedFilters;
    });
    setCurrentPage(1); // Reset to the first page after removing a filter
  };

  const handleClearAllFilters = () => {
    setFilters({}); // Clear all filters
    setCurrentPage(1); // Reset to the first page after clearing all filters
  };

  return (
    <div>
      <Form.Group as={Row}>
        <Row className="m-0">
          <Col>
            {searchRequired && (
              <Form.Control
                type="text"
                className="search-input w-75 px-1"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearch}
              />
            )}
          </Col>
          <Col className="col-auto">
            <Row>
              {filterRequired && (
                <Col>
                  <DropdownButton
                    id="dropdown-filter"
                    title="Filters"
                    className="filter-dropdown float-right w-25"
                    align="end"
                  >
                    <Row>
                      {columns.map((column) => {
                        const uniqueValues = [
                          ...new Set(
                            data?.map((item) => item[column.dataField])
                          ),
                        ];
                        const selectedValue =
                          filters[column.dataField] || "All";

                        return (
                          <Col key={`dt-field-${column.dataField}`}>
                            <Dropdown.ItemText key={column.dataField}>
                              <small>{column.text}</small>
                              <DropdownButton
                                id={`dropdown-filter-${column.dataField}`}
                                title={`${selectedValue}`}
                                className="filter-btn"
                                onSelect={(value) =>
                                  handleFilter(column.dataField, value)
                                }
                              >
                                <Dropdown.Item key="all" eventKey="">
                                  All
                                </Dropdown.Item>
                                {uniqueValues.map((option, index) => (
                                  <Dropdown.Item key={index} eventKey={option}>
                                    {option}
                                  </Dropdown.Item>
                                ))}
                              </DropdownButton>
                            </Dropdown.ItemText>
                          </Col>
                        );
                      })}
                    </Row>
                  </DropdownButton>
                </Col>
              )}
              {downloadRequired && (
                <Col>
                  <DropdownButton
                    id="dropdown-download"
                    title={<FontAwesomeIcon icon={faDownload} />}
                    className="mb-3 download-btn"
                  >
                    <Dropdown.Item
                      onClick={() => downloadCSV(columns, data, fileName)}
                    >
                      Download CSV
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => downloadPDF(columns, data, fileName)}
                    >
                      Download PDF
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => downloadText(columns, data, fileName)}
                    >
                      Download Text
                    </Dropdown.Item>
                  </DropdownButton>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Form.Group>
      {Object.values(filters).length > 0 && (
        <Row className="px-3 py-2 " key={filters}>
          <Col>
            {Object.keys(filters).length > 0 && (
              <Row className="py-0 my-0">
                <Col>
                  {Object.entries(filters).map(
                    ([key, value], index) =>
                      value && (
                        <Badge key={index} bg="secondary" className="m-1">
                          {value}
                          <Button
                            variant="light"
                            size="sm"
                            className="py-0 px-1 ml-2"
                            onClick={() => handleRemoveFilter(key)}
                          >
                            &times;
                          </Button>
                        </Badge>
                      )
                  )}
                  <Button
                    variant="danger"
                    size="sm"
                    className="ml-2"
                    onClick={handleClearAllFilters}
                  >
                    Clear All Filters
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      )}

      <Table
        className={`custom-table`}
        style={{ tableLayout: "auto", width: "100%" }}
      >
        <thead>
          <tr>
            {bulkActionsRequired && (
              <th>
                <Form.Check
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={
                    selectedItems.length === data.length && data.length > 0
                  }
                />
              </th>
            )}
            {columns.map((column, index) => (
              <th
                key={index}
                style={{ width: columnWidths[index] }}
                className="resizable"
              >
                <div
                  style={resizableTableStyles.resizer}
                  className="resizer"
                  onMouseDown={(e) => startResizing(index, e)}
                />
                <span onClick={() => handleSort(column.dataField)}>
                  {column.text}{" "}
                  {sortField === column.dataField ? (
                    sortOrder === "asc" ? (
                      <FontAwesomeIcon icon={faSortUp} className="blue cl" />
                    ) : (
                      <FontAwesomeIcon icon={faSortDown} className="blue cl" />
                    )
                  ) : (
                    <FontAwesomeIcon icon={faSort} />
                  )}
                </span>
              </th>
            ))}
            {(onEdit || onDelete) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, rowIndex) => (
            <tr key={rowIndex}>
              {bulkActionsRequired && (
                <td>
                  <Form.Check
                    type="checkbox"
                    onChange={(e) => handleSelectItem(e, item.id)}
                    checked={selectedItems.includes(item.id)}
                  />
                </td>
              )}
              {columns.map((column, colIndex) => (
                <OverlayTrigger
                  key={colIndex}
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-${colIndex}`}>
                      {item[column.dataField]}
                    </Tooltip>
                  }
                >
                  <td
                    className={`ellipsis`}
                    style={{
                      width: columnWidths[colIndex],
                      position: "relative",
                    }}
                    onMouseEnter={() =>
                      setHoveredCell(`${rowIndex}-${colIndex}`)
                    }
                    onMouseLeave={() => setHoveredCell(null)}
                  >
                    <span className={`cell-content`}>
                      {defineColumn(item, column.dataField, column?.link)}
                      {hoveredCell === `${rowIndex}-${colIndex}` && (
                        <CopyToClipboard text={item[column.dataField]} />
                      )}
                    </span>
                  </td>
                </OverlayTrigger>
              ))}
              {(onEdit || onDelete) && (
                <td style={{ textAlign: "right" }}>
                  {onEdit && (
                    <>
                      <Button
                        variant="warning"
                        size="sm"
                        onClick={() => onEdit(item)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>{" "}
                    </>
                  )}
                  {onDelete && (
                    <>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => onDelete(item)}
                      >
                        <FontAwesomeIcon icon={faClose} />
                      </Button>
                    </>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      {paginationRequired && (
        <Row>
          <Col>
            Items per page:
            <Form.Control
              as="select"
              value={itemsPerPage}
              style={{ width: "auto", display: "inline-block" }}
              onChange={(e) => {
                setItemsPerPage(Number(e.target.value));
                setCurrentPage(1);
              }}
            >
              {[10, 15, 20, 25, 50].map((count) => (
                <option value={count} key={`count_${count}`}>
                  {count}
                </option>
              ))}
            </Form.Control>
          </Col>
          <Col>
            <Pagination>
              <Pagination.First
                onClick={() => setCurrentPage(1)}
                disabled={currentPage === 1}
              />
              <Pagination.Prev
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
              />
              {renderPaginationItems()}
              <Pagination.Next
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
                disabled={currentPage === totalPages}
              />
              <Pagination.Last
                onClick={() => setCurrentPage(totalPages)}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default PaginatedTable;
