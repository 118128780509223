const MESSAGES = {
  "host-fetch-sc": "Host loaded successfully",
  "host-fetch-fa": "Failed to load Hosts",
  "host-create-sc": "Host created successfully",
  "host-create-fa": "Failed to create Host",
  "host-update-sc": "Host updated successfully",
  "host-update-fa": "Failed to update Host",
  "host-delete-sc": "Host deleted successfully",
  "host-delete-fa": "Failed to delete Host",
  "headends-fetch-sc": "Headends loaded successfully",
  "headends-fetch-fa": "Failed to load Headends",
  "headend-onboard-sc": "Headend onboarded successfully",
  "headend-onboard-fa": "Failed to onboard Headend",
  "headend-save-sc": "Headend saved successfully",
  "headend-save-fa": "Failed to save headend",
  "backup-fetch-sc": "Backups loaded successfully",
  "backup-fetch-fa": "Failed to load Backups",
};

export default MESSAGES;
