import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import HeadendDetailsView from "./DetailsView";
import { useBreadcrumbs } from "contexts";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchHeadendById,
  fetchMonitoringAuditReport,
  fetchNmapReport,
} from "stores/actions";
import { AddButton } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleMinus,
  faClockRotateLeft,
  faShieldHalved,
} from "@fortawesome/free-solid-svg-icons";
import { useWebSocket } from "contexts";
import { usePermissions } from "hooks/usePermission";
import { fetchSingleBackup } from "stores/actions";
import BackupListView from "pages/Backups/components/List";
import useActivityLogger from "hooks/useActivity";
import Loader from "components/Loader";
import MonitorDashboard from "pages/Monitoring/Components/MonitoringAuditDashboard";
import BackButton from "components/BackButton";
import MonitorHeadendView from "./Components/MonitorHeadendView";
import SystemStats from "./Components/SystemStats";

const HeadendDetails = () => {
  const { updateBreadcrumbs } = useBreadcrumbs();
  const { headend, headends, loading } = useSelector((state) => state.headend);
  const { backup } = useSelector((state) => state.backup);
  const { nmap } = useSelector((state) => state.security);
  const { monitoringReport } = useSelector((state) => state.audit);

  const { websocketService, connected } = useWebSocket();
  const [vdData, setVdData] = useState({
    cpuCount: 0,
    cpuLoad: "0",
    memory: "0",
    memoryFree: "0",
    disk: "0",
    diskUsage: "",
  });
  const [usageLoading, setUsageLoading] = useState(true);
  const logActivity = useActivityLogger();
  const canViewBackups = usePermissions(["BACKUPS_VIEW"]);
  const canEditHeaend = usePermissions(["HEADENDS_EDIT"]);
  const [dataKey, setDataKey] = useState("director");
  const { id } = useParams();
  // const [newId, setNewId] = useState(id);
  const [selected, setSelected] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const newId = useMemo(() => {
    let selectedId = id;
    if (selected)
      selectedId = headends.find(
        (head) => head?.code === selected || head.name === selected
      )?._id;
    return selectedId;
  }, [selected]);

  const sendLog = (activity, name) => {
    logActivity({
      page: "headend-details",
      activity,
      name,
    });
  };

  useEffect(() => {
    dispatch(fetchHeadendById(newId));
    dispatch(fetchNmapReport(newId));
  }, [newId]);

  useEffect(() => {
    updateBreadcrumbs([
      { text: "Home", url: "/dashboard" },
      { text: "Headends", url: "/headends" },
    ]);
  }, []);

  const redirectToManage = (id) => {
    sendLog("headend-edit-click", headend?.name);
    navigate(`/headends/manage/${id}`);
  };

  useEffect(() => {
    if (connected) {
      websocketService.registerCallback("message", (data) => {
        // setMessages((prevMessages) => [...prevMessages, data]);
        // console.log("WEBSOKCET MESSAGES", data);
        if (data.room === newId) {
          if (usageLoading) setUsageLoading(false);
          setVdData(data?.message?.systemDetails);
        }
      });

      websocketService.joinRoom(newId);

      return () => {
        websocketService.leaveRoom();
      };
    }
  }, [connected, newId, websocketService]);

  useEffect(() => {
    const guiCredentials = headend?.directors?.[0]?.host?.guiCredentials?.[0];
    const { username, password } = guiCredentials || {};

    if (username && password) {
      websocketService.sendMessage({
        type: "message",
        message: { username, password, fqdn: headend?.fqdn, type: "headend" },
        room: newId,
      });
    }

    dispatch(fetchSingleBackup(headend?.code || headend?.name));
    dispatch(fetchMonitoringAuditReport(headend?.code || headend?.name));
  }, [headend]);

  return (
    <>
      <div className={`middle-section`}>
        <Row>
          <Col>
            <BackButton title="Headends" />
            <div className="my-2">
              <h5>
                <b>Headend Details</b>
              </h5>
              <small className="sub-info">Detailed view of the headend</small>
            </div>
          </Col>
          {canEditHeaend && (
            <Col className="col-auto">
              <AddButton
                handleClick={() => redirectToManage(headend?._id)}
                name="Edit"
                size="sm"
                className="add-btn"
              />
            </Col>
          )}
        </Row>

        {loading["fetchSingle"] ? (
          <Loader />
        ) : (
          <Row className="my-2">
            <Col sm={8}>
              <Card className="mb-4">
                <Card.Body>
                  <Row>
                    <Col>
                      {headend && (
                        <Row>
                          <Col>
                            <h4 className="m-0">
                              <Dropdown.ItemText>
                                <DropdownButton
                                  id={`headend-lists`}
                                  title={`${
                                    selected || headend?.code || headend?.name
                                  }`}
                                  onSelect={(value) => {
                                    setSelected(value);
                                  }}
                                  className="cust-dropdown"
                                >
                                  {headends.map((option, index) => (
                                    <Dropdown.Item
                                      key={index}
                                      eventKey={`${
                                        option?.code || option.name
                                      }`}
                                    >
                                      {option?.code || option?.name}
                                    </Dropdown.Item>
                                  ))}
                                </DropdownButton>
                              </Dropdown.ItemText>
                            </h4>
                          </Col>
                          <Col>
                            <small>NAME</small>
                            <h6>
                              <b>{headend?.name}</b>
                            </h6>
                          </Col>
                          <Col>
                            <small>CATEGORY</small>
                            <h6>
                              <b>{headend?.category?.name}</b>
                            </h6>
                          </Col>
                          <Col>
                            <small>INFRA</small>
                            <h6>
                              <b>{headend?.infra?.name}</b>
                            </h6>
                          </Col>
                          <Col>
                            <small>ENVIRONMENT</small>
                            <h6>
                              <b>{headend?.envType?.name}</b>
                            </h6>
                          </Col>
                          <Col>
                            <small>ACTIVE</small>
                            <h6>
                              <FontAwesomeIcon
                                icon={
                                  headend?.active
                                    ? faCircleCheck
                                    : faCircleMinus
                                }
                                className={`${
                                  headend.active ? "green" : "red"
                                } cl`}
                              />
                            </h6>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <HeadendDetailsView headend={headend} />
              <Row>
                <Col md={8}>
                  <Card className="mt-3">
                    <Card.Body>
                      <div className="">
                        <h5>
                          <b>Monitoring</b>
                        </h5>
                        <small className="sub-info">
                          Monitoring informations
                        </small>
                      </div>

                      <MonitorHeadendView
                        data={
                          monitoringReport &&
                          monitoringReport.length > 0 &&
                          monitoringReport[0]
                        }
                        dataKey={dataKey}
                        setDataKey={setDataKey}
                      />
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={4} className="mt-3">
                  {/* <Card className="mt-3">
                    <Card.Body> */}
                  <div className="mt-3 mb-3">
                    <h5>
                      <b>Monitoring Audit Report</b>
                    </h5>
                    <small className="sub-info">
                      <span>Check latest reports</span>
                    </small>
                  </div>
                  <MonitorDashboard
                    monitorAudit={monitoringReport}
                    showHeadend={false}
                  />
                  {/* </Card.Body>
                  </Card> */}
                </Col>
              </Row>
            </Col>

            <Col sm={4}>
              <Row className="mb-3">
                <Col sm={12}>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col sm={1} style={{ fontSize: "2rem" }}>
                          <FontAwesomeIcon icon={faShieldHalved} />
                        </Col>
                        <Col>
                          <div className=" mb-1">
                            <h5>
                              <b>Nmap Report</b>
                            </h5>
                            <small className="sub-info">
                              <FontAwesomeIcon
                                icon={faClockRotateLeft}
                                className="green cl"
                              />{" "}
                              <span>last scanned : </span>
                              <b>{nmap?.lastUpdated}</b>
                            </small>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-2 mb-1">
                        <Col sm={4}>
                          <b>PORT</b>
                        </Col>
                        <Col sm={4}>
                          <b>STATE</b>
                        </Col>
                        <Col sm={4}>
                          <b>SERVICE</b>
                        </Col>
                      </Row>

                      {nmap?.reports?.length > 0 &&
                        nmap?.reports[0]?.ports?.map((data) => (
                          <Row className="mt-2">
                            <Col sm={4}>
                              <Card.Text>{data?.port}</Card.Text>
                            </Col>
                            <Col sm={4}>
                              <Card.Text>{data?.state}</Card.Text>
                            </Col>
                            <Col sm={4}>
                              <Card.Text>{data?.service}</Card.Text>
                            </Col>
                          </Row>
                        ))}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <SystemStats isLoading={usageLoading} data={vdData} />
            </Col>
            <Col sm={12}>
              {canViewBackups && (
                <Card className="mt-4">
                  <Card.Body>
                    <BackupListView backups={backup} />
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default HeadendDetails;
