import { useEffect } from "react";
import { useBreadcrumbs } from "../../../contexts";
import { Card, Col, Row } from "react-bootstrap";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useActivityLogger from "hooks/useActivity";

const Prfile = () => {
  const selectedUser = JSON.parse(localStorage.getItem("user"));
  const logActivity = useActivityLogger();
  const { updateBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    logActivity({
      page: "profile",
      activity: "page-load",
    });
    updateBreadcrumbs([{ text: "Home", url: "/dashboard" }]);
  }, []);

  return (
    <>
      <div className="middle-section">
        <div className="my-2">
          <h5>
            <b>Profile</b>
          </h5>
          <small className="sub-info">
            View your profile details and permissions
          </small>
        </div>
        <Row className="my-2">
          <Col>
            <Card>
              <Card.Body>
                <Row>
                  <Col sm={1} className="m-3">
                    <div className="user-icon">
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                  </Col>
                  <Col className="mt-3">
                    <Row>
                      <Col>
                        <h4
                          style={{
                            textTransform: "capitalize",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <b>{selectedUser.name}</b>
                        </h4>
                        <small>{selectedUser.email}</small>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Row>
              <Col>
                <Card className="mt-3">
                  <Card.Body>
                    <h5>
                      <b>Roles & Permissions</b>
                    </h5>
                    <p>
                      You have <b>{selectedUser?.role?.name}</b> role
                    </p>
                    <p>Permissions</p>
                    <ul>
                      {selectedUser?.role?.pages?.map((pages) => (
                        <li>
                          <b>{pages.name}</b>
                          <ul className="my-2">
                            {pages?.permissions?.map((permission) => (
                              <li>{permission.name}</li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col></Col>
        </Row>
      </div>
    </>
  );
};

export default Prfile;
