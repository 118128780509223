import React, { useEffect, useRef } from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  Handle,
} from "react-flow-renderer";
import html2canvas from "html2canvas"; // Import html2canvas to capture the diagram
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

// Define node styles for different components
const nodeStyles = {
  arista: {
    background: "#E3F2FD",
    border: "1px solid #1E88E5",
    borderRadius: "10px",
    padding: "10px",
    color: "#1E88E5",
    fontWeight: "bold",
    fontSize: "0.8rem",
    width: "150px",
    textAlign: "center",
  },
  dc: {
    background: "#FFCDD2",
    border: "1px solid #E57373",
    borderRadius: "10px",
    padding: "10px",
    color: "#E57373",
    fontWeight: "bold",
    width: "150px",
    fontSize: "0.8rem",
    textAlign: "center",
  },
  router: {
    background: "#FFF9C4",
    border: "1px solid #FFEB3B",
    borderRadius: "10px",
    padding: "10px",
    color: "#FFEB3B",
    fontWeight: "bold",
    width: "150px",
    fontSize: "0.8rem",
    textAlign: "center",
  },
  subnet: {
    background: "#C8E6C9",
    border: "1px solid #4CAF50",
    borderRadius: "50%",
    padding: "10px",
    color: "#4CAF50",
    fontWeight: "bold",
    width: "100px",
    fontSize: "0.8rem",
    textAlign: "center",
  },
  isp: {
    background: " rgb(229 245 232)",
    border: "1px solid rgb(86 184 36)",
    borderRadius: "10px",
    padding: "10px",
    color: "rgb(83 167 70)",
    fontWeight: "bold",
    width: "120px",
    fontSize: "0.8rem",
    textAlign: "center",
  },
  device: {
    background: "#E1BEE7",
    border: "1px solid #9C27B0",
    borderRadius: "20px",
    padding: "10px",
    color: "#9C27B0",
    fontWeight: "bold",
    width: "120px",
    fontSize: "0.8rem",
    textAlign: "center",
  },
};

// Custom node component based on type with top/bottom Handle positioning
const CustomNode = ({ data, type }) => {
  const style = nodeStyles[type] || nodeStyles.device; // Fallback to device style if no type is given
  return (
    <div style={style}>
      {data.label}
      {/* Top handle for edges connecting from above */}
      <Handle type="source" position="top" style={{ background: "#555" }} />
      {/* Bottom handle for edges connecting from below */}
      <Handle type="target" position="bottom" style={{ background: "#555" }} />
    </div>
  );
};

// Function to create nodes and edges based on dynamic data
const createNodesAndEdges = (data, containerWidth) => {
  const nodes = [];
  const edges = [];

  // Check if data is available
  if (!data || !data.arista_sw) {
    // console.log("No valid data available");
    return { nodes, edges }; // Handle missing data gracefully
  }

  //   console.log("Data received:", data);

  // Dynamic x-position for centering items
  const centerX = containerWidth / 2;

  // Create a node for the Central Node (Arista Switch in the center)
  nodes.push({
    id: data._id || "arista",
    data: {
      label:
        `${data.arista_sw?.name}\n ${data.arista_sw?.ipAddress}` || "Arista",
    },
    position: { x: centerX - 75, y: 300 }, // Center position
    type: "arista",
  });

  // Add a Data Center (DC) node and connect it to the Arista Switch
  const dcNodeId = data.dc?._id || "dc-01";
  nodes.push({
    id: dcNodeId,
    data: { label: data?.name || "DC" },
    position: { x: centerX - 75, y: 400 }, // Positioned below the Arista switch and centered
    type: "dc",
  });

  // Connect Arista to the DC
  edges.push({
    id: `edge-${data._id}-${dcNodeId}`,
    source: data._id,
    target: dcNodeId,
    animated: true,
    style: { stroke: "#E57373" }, // Red for DC connection
    sourceHandle: "bottom",
    targetHandle: "top",
  });

  // Add gateways and connect them to the DC
  if (data.gateway && data.gateway.length > 0) {
    const totalGateways = data.gateway.length;
    const gatewayStartX = centerX - totalGateways * 75; // Start from the left to center-align

    data.gateway.forEach((gateway, index) => {
      const gatewayNodeId = gateway._id || `gateway-${index}`;
      nodes.push({
        id: gatewayNodeId,
        data: {
          label:
            `${gateway.name} ${gateway.ipAddress}` || `Gateway ${index + 1}`,
        },
        position: { x: gatewayStartX + index * 150, y: 500 }, // Positioned at the bottom of the DC and horizontally centered
        type: "device",
      });

      // Connect each gateway to the DC
      edges.push({
        id: `edge-${gatewayNodeId}-${dcNodeId}`,
        source: gatewayNodeId,
        target: dcNodeId,
        animated: true,
        style: { stroke: "#9C27B0" }, // Pink for gateway connections
        sourceHandle: "bottom",
        targetHandle: "top",
      });
    });
  }
  //   else {
  //     console.log("No gateways data available");
  //   }

  // Add ISP connections (Position at the top)
  if (data.connections && data.connections.length > 0) {
    const totalISPs = data.connections.length;
    const ispStartX = centerX - totalISPs * 75; // Start from the left to center-align ISPs

    data.connections.forEach((isp, index) => {
      const ispNodeId = isp._id || `isp-${index}`;
      nodes.push({
        id: ispNodeId,
        data: {
          label: `${isp.isp} ${isp.isp_side_ip} ` || `ISP ${index + 1}`,
        },
        position: { x: ispStartX + index * 150, y: 100 }, // Position at the top, horizontally centered
        type: "isp",
      });

      // Connect each ISP to the central Arista node using top/bottom handles
      edges.push({
        id: `edge-${data._id}-${ispNodeId}`,
        source: data._id,
        target: ispNodeId,
        animated: true,
        style: { stroke: "#8E24AA" }, // Purple for ISP connections
        sourceHandle: "bottom",
        targetHandle: "top",
      });
    });
  }
  //   else {
  //     console.log("No ISP data available");
  //   }

  //   console.log("Nodes created:", nodes);
  //   console.log("Edges created:", edges);

  return { nodes, edges };
};

const Diagram = ({ data }) => {
  const reactFlowInstance = useRef(null);
  const diagramRef = useRef(null); // Ref to capture the diagram container
  const [nodes, setNodes] = React.useState([]);
  const [edges, setEdges] = React.useState([]);

  // Dynamically set container width for centering
  const containerWidth = 800; // Set a default width for now or calculate it dynamically

  useEffect(() => {
    const { nodes, edges } = createNodesAndEdges(data, containerWidth);
    setNodes(nodes);
    setEdges(edges);

    if (reactFlowInstance.current) {
      reactFlowInstance.current.fitView();
    }
  }, [data]);

  // Function to download diagram as an image
  const downloadDiagramAsImage = () => {
    if (diagramRef.current) {
      html2canvas(diagramRef.current).then((canvas) => {
        const link = document.createElement("a");
        link.download = "diagram.png";
        link.href = canvas.toDataURL("image/png");
        link.click();
      });
    }
  };

  return (
    <div>
      <center>
        <button onClick={downloadDiagramAsImage} className="download-link link">
          <FontAwesomeIcon icon={faDownload} /> Download as Image
        </button>
      </center>
      <div
        ref={diagramRef}
        style={{ height: "70vh", width: "90%", margin: "auto" }}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={{
            isp: CustomNode,
            arista: CustomNode,
            dc: CustomNode, // New DC node type
            router: CustomNode,
            device: CustomNode,
          }}
          style={{ width: "100%", height: "100%" }}
          onLoad={(instance) => {
            reactFlowInstance.current = instance;
            instance.fitView();
          }}
          defaultZoom={1.5}
          fitView
        >
          {/* <MiniMap /> */}
          {/* <Controls /> */}
          <Background />
        </ReactFlow>
      </div>
    </div>
  );
};

export default Diagram;
