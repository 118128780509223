import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { handleLogoutCallback, removeUser } from "services/auth";
import { Card } from "react-bootstrap";

const LogoutCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    handleLogoutCallback()
      .then(() => {
        removeUser()
          .then(() => {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          })
          .catch((err) => {
            console.error("Error during user removal:", err);
          });
      })
      .catch((err) => {
        console.error("Logout callback error:", err);
      });
  }, [navigate]);

  return (
    <div
      className="bg-image"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Card className="p-4 w-50">
        <Card.Body>
          <center>Logging out...</center>
        </Card.Body>
      </Card>
    </div>
  );
};

export default LogoutCallback;
