const COUNTRY_COORDINATES = {
  Australia: { lat: -25.2744, lng: 133.7751, zoom: 4 },
  Brazil: { lat: -14.235, lng: -51.9253, zoom: 4 },
  Canada: { lat: 56.1304, lng: -106.3468, zoom: 4 },
  China: { lat: 35.8617, lng: 104.1954, zoom: 4 },
  France: { lat: 46.6034, lng: 1.8883, zoom: 5 },
  Germany: { lat: 51.1657, lng: 10.4515, zoom: 5 },
  HongKong: { lat: 22.3193, lng: 114.1694, zoom: 10 },
  India: { lat: 20.5937, lng: 78.9629, zoom: 5 },
  Indonesia: { lat: -0.7893, lng: 113.9213, zoom: 5 },
  Jakarta: { lat: -6.2088, lng: 106.8456, zoom: 10 },
  Japan: { lat: 36.2048, lng: 138.2529, zoom: 5 },
  Netherlands: { lat: 52.1326, lng: 5.2913, zoom: 6 },
  Philippines: { lat: 12.8797, lng: 121.774, zoom: 5 },
  "Saudi Arabia": { lat: 23.8859, lng: 45.0792, zoom: 5 },
  Singapore: { lat: 1.3521, lng: 103.8198, zoom: 12 },
  "South Africa": { lat: -30.5595, lng: 22.9375, zoom: 5 },
  UAE: { lat: 23.4241, lng: 53.8478, zoom: 6 },
  USA: { lat: 37.0902, lng: -95.7129, zoom: 4 },
  "United Kingdom": { lat: 55.3781, lng: -3.436, zoom: 5 },
};

export default COUNTRY_COORDINATES;
