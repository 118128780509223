import React from "react";
import { PieChart, Pie, Tooltip, Cell } from "recharts";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const LanguageUsage = ({ languageUsage, loading }) => (
  <div>
    <div className="mt-2 mb-3">
      <h5>
        <b>Language Used</b>
      </h5>
    </div>
    {!loading && (
      <PieChart width={400} height={400}>
        <Pie
          data={languageUsage}
          dataKey="count"
          nameKey="_id"
          cx="50%"
          cy="50%"
          outerRadius={100}
          fill="#8884d8"
        >
          {languageUsage?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    )}
  </div>
);

export default LanguageUsage;
