import Highlight from "components/HighlightText";
import HostSingleView from "pages/Host/SingleHostView";
import { useState } from "react";
const {
  Accordion,
  Badge,
  ListGroup,
  Card,
  Col,
  Row,
} = require("react-bootstrap");

const HostResult = ({ hosts, sendLog, query }) => {
  const [selectedHost, setSelectedHost] = useState(null);
  const [showHostModal, setShowHostmodal] = useState(false);
  return (
    <Accordion.Item eventKey="hosts">
      <Accordion.Header>
        Hosts :
        <Badge pill bg="info">
          {hosts?.length}
        </Badge>
      </Accordion.Header>
      <Accordion.Body>
        <Row>
          {hosts?.map((host) => (
            <Col sm={3} className="mb-3">
              <Card>
                <Card.Body className="p-0">
                  <ListGroup variant="flush">
                    <ListGroup.Item className="mb-1">
                      <span>Name</span>

                      <b
                        className="link"
                        onClick={() => {
                          sendLog("host-click", host?.name);
                          setSelectedHost(host);
                          setShowHostmodal(true);
                        }}
                      >
                        <Highlight text={host?.name} highlight={query} />
                      </b>
                    </ListGroup.Item>
                    <ListGroup.Item className="mb-1">
                      <span>Category</span>

                      <b>
                        <Highlight
                          text={host?.category?.name}
                          highlight={query}
                        />
                      </b>
                    </ListGroup.Item>
                    <ListGroup.Item className="mb-1">
                      <span>Headend Name</span>

                      <b className="ellipsis text-uppercase">
                        <Highlight text={host?.headendName} highlight={query} />
                      </b>
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Accordion.Body>
      <HostSingleView
        host={selectedHost}
        removeHost={setSelectedHost}
        showModal={showHostModal}
        closeModal={() => setShowHostmodal(false)}
      />
    </Accordion.Item>
  );
};

export default HostResult;
