import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "services/api";
import { showAlert } from "../slices/alertSlice";
import MESSAGES from "../../constants/messages";
import React from "react";

const fetchIsps = createAsyncThunk(
  "isps/fetchIsps",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/isps`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const saveIsp = createAsyncThunk(
  "isps/saveIsp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`/isps`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const updateIsp = (isp) => async (dispatch) => {
  try {
    const response = await API.put(`/isps/${isp._id}`, isp);
    dispatch(
      showAlert({
        message: MESSAGES["isp-update-sc"],
        variant: "success",
      })
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteIsp = createAsyncThunk(
  "isps/deleteIsp",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await API.get(`/isps/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export { fetchIsps, saveIsp, deleteIsp, updateIsp };
