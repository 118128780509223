// pages/UserManagement.js
import React, { useState } from "react";
import { updateUser } from "stores/actions";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import deepClone from "utils/deepClone";

const UserManagement = () => {
  const dispatch = useDispatch();
  const { users, roles, pages } = useSelector((state) => state.auth);

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState([]);

  const handleUserChange = (item) => {
    const user = users?.find((user) => user._id === item._id);
    setSelectedUser(user);
    setSelectedRole(user ? user?.role : {});
  };

  const handleSave = async () => {
    if (selectedUser) {
      const clonedUser = deepClone(selectedUser);
      clonedUser.role = selectedRole;
      await dispatch(updateUser(clonedUser));
    }
  };

  return (
    <Container>
      <div className="mb-3">
        <h5>
          <b>User Management</b>
        </h5>
        <small className="sub-info">
          Manage users by giving proper roles and permissions
        </small>
      </div>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <PaginatedTable
                columns={COLUMNS["user"]}
                data={users}
                onView={handleUserChange}
              />
            </Card.Body>
          </Card>
        </Col>

        <Col>
          {selectedUser && (
            <>
              <Card>
                <Card.Body>
                  <Row>
                    <Col sm={1} className="m-3">
                      <div className="user-icon">
                        <FontAwesomeIcon icon={faUser} />
                      </div>
                    </Col>
                    <Col className="mt-3">
                      <Row>
                        <Col>
                          <h4
                            style={{
                              textTransform: "capitalize",
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            <b>{selectedUser.name}</b>
                          </h4>
                          <small>{selectedUser.email}</small>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Row>
                <Col>
                  <Card className="mt-3">
                    <Card.Body>
                      <h5>
                        <b>Roles</b>
                      </h5>
                      {roles?.map((role) => (
                        <Form.Check
                          key={role._id}
                          type="radio"
                          label={role.name}
                          name="role"
                          value={role._id}
                          checked={selectedRole?._id === role?._id}
                          onChange={() => setSelectedRole(role)}
                        />
                      ))}
                    </Card.Body>
                  </Card>
                  <Button className="mt-3" onClick={handleSave}>
                    Update Role
                  </Button>
                </Col>
                <Col>
                  <Card className="mt-3">
                    <Card.Body>
                      <h5>
                        <b>Pages</b>
                      </h5>
                      {selectedRole?.pages?.map((page) => (
                        <>
                          <b>{page.name}</b>

                          <Row className="mt-2">
                            {page?.permissions?.map((permissions) => (
                              <Col>{permissions?.name}</Col>
                            ))}
                          </Row>
                          <hr className="my-2" />
                        </>
                      ))}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default UserManagement;
