import Highlight from "components/HighlightText";
import OrchSingleView from "../../Orchestrators/OrchestratorsDetails";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchOrchestratorsById } from "stores/actions";
const {
  Accordion,
  Badge,
  ListGroup,
  Card,
  Col,
  Row,
} = require("react-bootstrap");

const OrchestratorResult = ({ orchestrators, sendLog, query }) => {
  const [showModal, setShowmodal] = useState(false);
  const dispatch = useDispatch();

  const setOrchestrator = (orchestrator) => {
    dispatch(fetchOrchestratorsById(orchestrator._id));
    sendLog("orchestrator-click", orchestrator?.name);
    setShowmodal(true);
  };
  return (
    <Accordion.Item eventKey="orchestrators">
      <Accordion.Header>
        Orchestrator :
        <Badge pill bg="info">
          {orchestrators?.length}
        </Badge>
      </Accordion.Header>
      <Accordion.Body>
        <Row>
          {orchestrators?.map((orchestrator) => (
            <Col sm={3} className="mb-3">
              <Card>
                <Card.Body className="p-0">
                  <ListGroup variant="flush">
                    <ListGroup.Item className="mb-1">
                      <span>Name</span>

                      <b
                        className="link"
                        onClick={() => {
                          setOrchestrator(orchestrator);
                        }}
                      >
                        <Highlight
                          text={orchestrator?.name}
                          highlight={query}
                        />
                      </b>
                    </ListGroup.Item>
                    <ListGroup.Item className="mb-1">
                      <span>Category</span>

                      <b>
                        <Highlight
                          text={orchestrator?.category?.name}
                          highlight={query}
                        />
                      </b>
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default OrchestratorResult;
