import { PaginatedTable } from "components";
import COLUMNS from "constants/columns";
import React, { useMemo } from "react";

const UserActions = ({ userActions }) => {
  const data = useMemo(() => {
    return userActions.map((action) => ({
      user: action._id.user,
      activity: action._id.activity,
      count: action.count,
    }));
  }, [userActions]);

  return (
    <>
      <div className="mt-2 mb-3">
        <h5>
          <b>User Actions</b>
        </h5>
      </div>
      <PaginatedTable
        data={data}
        columns={COLUMNS["userActions"]}
        perPage={25}
      />
    </>
  );
};

export default React.memo(UserActions);
