// reducers/hostSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { fetchIsps } from "../actions";

const ispSlice = createSlice({
  name: "isps",
  initialState: {
    isps: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIsps.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchIsps.fulfilled, (state, action) => {
        state.loading = false;
        state.isps = action.payload;
      })
      .addCase(fetchIsps.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default ispSlice.reducer;
