import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import { Card } from "react-bootstrap";

const Logout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    logout().catch((error) => {
      console.error("Logout failed:", error);
      navigate("/login"); // Redirect in case of failure
    });
  }, [logout, navigate]);

  return (
    <div
      className="bg-image"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Card className="p-4 w-50">
        <Card.Body>
          <center>Redirecting to logout...</center>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Logout;
