import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "services/api";

const fetchBackupAuditReport = createAsyncThunk(
  "audit/fetchBackupAuditReport",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/audit/backup`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchMonitoringAuditReport = createAsyncThunk(
  "audit/fetchMonitoringAuditReport",
  async (headend, { rejectWithValue }) => {
    try {
      const response = await API.get(`/audit/monitoring?headend=${headend}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchMonitoringAuditSummary = createAsyncThunk(
  "audit/fetchMonitoringAuditSummary",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/audit/monitoring/summary`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export {
  fetchBackupAuditReport,
  fetchMonitoringAuditReport,
  fetchMonitoringAuditSummary,
};
