import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";

const AddButton = ({ handleClick, name, ...props }) => {
  return (
    <Button onClick={handleClick} {...props}>
      <FontAwesomeIcon
        icon={name.includes("Edit") ? faEdit : faPlus}
        className="nav-icon"
      />
      &nbsp;{name}
    </Button>
  );
};

export default AddButton;
