import { Card, Col, Modal, Row } from "react-bootstrap";
import HostView from "./HostView";
import COLUMNS from "constants/columns";
import { PaginatedTable } from "components";

const SingleView = ({
  host,
  removeHost,
  showModal,
  closeModal,
  orgMapping = [],
}) => {
  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        size="xl"
        dialogClassName="l-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title> {host?.name} </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg">
          <Row>
            <Col md={12}>
              <HostView host={host} />
              {orgMapping?.length > 0 && (
                <Card>
                  <Card.Body>
                    <Card.Title>
                      <b>Tenants</b>
                    </Card.Title>
                    <Card.Text>Lists all organisations</Card.Text>
                    <PaginatedTable
                      data={orgMapping}
                      columns={COLUMNS["orgMapping"]}
                      itemsPerPage={10}
                    />
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SingleView;
