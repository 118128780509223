import React, { useEffect, useMemo, useState } from "react";
import SingleView from "pages/Host/SingleHostView";
import { PaginatedTable } from "components";
import COLUMNS from "constants/columns";
import { Card, Tabs } from "react-bootstrap";
import { Tab } from "bootstrap";
import capitalize from "utils/capitalize";

const AppliancesView = ({ appliances }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedAppliance, setSelectedAppliance] = useState(null);
  const [devices, setDevices] = useState({
    versaDevice: [],
    tenantDevice: [],
  });
  const [tenantManaged, setTenantManaged] = useState([]);

  useEffect(() => {
    let versaDevice = [],
      tenantDevice = [];

    appliances.forEach((appliance) => {
      const isVersaManaged =
        appliance?.host?.isVersaManaged ||
        appliance?.host?.ownerOrg?.toLowerCase() === "versa";
      const data = {
        id: appliance?._id,
        name: appliance?.host?.name,
        ownerOrg: appliance?.host?.ownerOrgName,
        type: capitalize(appliance?.host?.type),
      };
      if (isVersaManaged) versaDevice.push(data);
      else tenantDevice.push(data);
    });
    setDevices({
      versaDevice,
      tenantDevice,
    });
  }, [appliances]);

  const onView = (item) => {
    const selectedAppliance = appliances?.find(
      (appliance) => item.id === appliance._id
    );

    setSelectedAppliance(selectedAppliance);
    setShowModal(true);
  };
  return (
    <>
      <div className="my-3">
        <h5>
          <b>Appliances</b>
        </h5>
        <small className="sub-info">List of all Appliances</small>
      </div>
      <Card>
        <Card.Body>
          <Tabs
            defaultActiveKey="tenant"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab
              eventKey="versa"
              title={`Versa Appliances(${devices?.versaDevice.length})`}
            >
              <PaginatedTable
                data={devices?.versaDevice}
                columns={COLUMNS["appliance"]}
                perPage={15}
                onView={onView}
              />
            </Tab>
            <Tab
              eventKey="tenant"
              title={`Tenant Appliances(${devices?.tenantDevice.length})`}
            >
              <PaginatedTable
                data={devices?.tenantDevice}
                columns={COLUMNS["appliance"]}
                perPage={15}
                onView={onView}
              />
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>

      <SingleView
        host={selectedAppliance?.host}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      />
    </>
  );
};

export default AppliancesView;
