import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TenantForm from "./AddForm";
import { fetchTenantSummary } from "stores/actions";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import { usePermissions } from "hooks/usePermission";
import SingleView from "./SingleOrgView";
import { useNavigate } from "react-router-dom";

const TenantListView = ({ downloadList, setDownloadList, onSave, sendLog }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [orgToEdit, setOrgToEdit] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);

  const { tenants, loading } = useSelector((state) => state.organisation);

  const tenantsData = useMemo(() => {
    return tenants
      .filter((org) => org?.name !== "none" || org?.name !== undefined)
      .map((org) => {
        return {
          id: org._id,
          name: org?.name,
          parentOrg: org?.parentOrg || "",
          contacts: org?.contacts?.length || "",
          plan: org?.plan,
          headends: org?.headends
            ?.map((headend) => `${headend?.code}-${headend?.name}`)
            .join(", "),
        };
      });
  }, [tenants]);

  useEffect(() => {
    if (selectedOrg?._id) dispatch(fetchTenantSummary(selectedOrg?._id));
  }, [selectedOrg]);

  const onView = (item) => {
    const selected = tenants.find((org) => item.id === org._id);
    sendLog("Tenants", "click-view-org", selected?.name);
    navigate(`/tenants/${selected?._id}`);
    // setSelectedOrg(selected);
    // setShowViewModal(true);
  };
  const onEdit = (item) => {
    const selected = tenants.find((org) => item.id === org._id);
    sendLog("Tenants", "click-edit-org", selected?.name);
    setOrgToEdit(selected);
    setShowModal(true);
  };

  return (
    <>
      <PaginatedTable
        data={tenantsData}
        columns={COLUMNS["orgs"]}
        perPage={20}
        onView={usePermissions(["TENANTS_VIEW"]) && onView}
        onEdit={usePermissions(["TENANTS_EDIT"]) && onEdit}
        isLoading={loading["fetchTenants"]}
        bulkActionsRequired={true}
        selectedItems={downloadList}
        setSelectedItems={setDownloadList}
      />
      <TenantForm
        organization={orgToEdit}
        onSave={onSave}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      />
      <SingleView
        organisation={selectedOrg}
        removeOrg={setSelectedOrg}
        // summary={selectedOrgSummary}
        showModal={showViewModal}
        closeModal={() => setShowViewModal(false)}
      />
    </>
  );
};

export default TenantListView;
