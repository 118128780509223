import React, { useMemo, useState } from "react";

import SingleView from "pages/Host/SingleHostView";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";

const GatewaysView = ({ gateways }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState(null);

  const gatewaysData = useMemo(() => {
    return gateways.map((gateway) => {
      return {
        id: gateway?.host._id,
        name: gateway?.host?.name,
        ownerOrg: gateway?.host?.ownerOrg,
        type: gateway?.host?.subtype,
      };
    });
  }, [gateways]);

  const onView = (item) => {
    const selected = gateways.find((gateway) => item.id === gateway._id);
    console.log(item, selected);
    setSelectedGateway(selected);
    setShowModal(true);
  };
  return (
    <>
      <div className="my-3">
        <h5>
          <b>Gateways</b>
        </h5>
        <small className="sub-info">
          List of all Gateways managed by the headend
        </small>
      </div>
      <PaginatedTable
        data={gatewaysData}
        columns={COLUMNS["gateway"]}
        itemsPerPage={10}
        onView={onView}
      />
      <SingleView
        host={selectedGateway?.host}
        showModal={showModal}
        closeModal={() => {
          setSelectedGateway(null);
          setShowModal(false);
        }}
      />
    </>
  );
};

export default GatewaysView;
