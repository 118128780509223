import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { Button } from "react-bootstrap";
import SingleView from "pages/Host/SingleHostView";

// Import custom icons
import regionIconImg from "assets/images/cluster.png";
import dcIconImg from "assets/images/dcIcon.png";
import switchIconImg from "assets/images/switchIcon.png";
import gatewayIconImg from "assets/images/gatewayIcon.png";

// Define custom Leaflet icons
const regionIcon = new L.Icon({
  iconUrl: regionIconImg,
  iconSize: [40, 40],
});

const dcIcon = new L.Icon({
  iconUrl: dcIconImg,
  iconSize: [40, 40],
});

const switchIcon = new L.Icon({
  iconUrl: switchIconImg,
  iconSize: [40, 40],
});

const gatewayIcon = new L.Icon({
  iconUrl: gatewayIconImg,
  iconSize: [40, 40],
});

const DeviceMap = ({ dataCenters, mapConfig }) => {
  const [currentZoom, setCurrentZoom] = useState(2); // Zoom level
  const [center, setCenter] = useState([0, 0]); // Initial center
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedDC, setSelectedDC] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  // Helper function for navigation between views
  const handleRegionClick = (region) => {
    setSelectedRegion(region);
    setCurrentZoom(2);
    setCenter([region.lat, region.long]);
  };

  const handleDCClick = (dataCenter) => {
    setSelectedDC(dataCenter);
    setCenter([dataCenter.location.lat, dataCenter.location.long]);
    setCurrentZoom(5);
  };

  const handleBack = () => {
    if (selectedDC) {
      setSelectedDC(null);
      setCenter([selectedRegion.lat, selectedRegion.long]);
      setCurrentZoom(6);
    } else if (selectedRegion) {
      setSelectedRegion(null);
      setCenter([0, 0]);
      setCurrentZoom(2);
    }
  };

  // Extract regions from data centers
  const getRegionsFromDataCenters = () => {
    const regions = {};
    dataCenters.forEach((dc) => {
      const region = dc.location.region;
      if (!regions[region.name]) {
        regions[region.name] = {
          name: region.name,
          lat: region.lat,
          long: region.long,
        };
      }
    });
    return Object.values(regions);
  };

  // Render markers dynamically
  const renderMarkers = () => {
    if (!selectedRegion && !selectedDC) {
      // World view: Show regions with labels and icons
      const regions = getRegionsFromDataCenters();
      return regions.map((region, index) => (
        <Marker
          key={`region-${index}`}
          position={[region.lat, region.long]}
          icon={regionIcon} // Region icon
          eventHandlers={{
            click: () => handleRegionClick(region),
          }}
        >
          <Popup>
            <strong>{region.name}</strong>
          </Popup>
        </Marker>
      ));
    } else if (selectedRegion && !selectedDC) {
      // Region view: Show individual data centers (only icons)
      return dataCenters.map((dataCenter, index) => (
        <Marker
          key={`dc-${index}`}
          position={[dataCenter.location.lat, dataCenter.location.long]}
          icon={dcIcon}
          eventHandlers={{
            click: () => handleDCClick(dataCenter),
          }}
        >
          <Popup>
            <strong>{dataCenter.site}</strong>
          </Popup>
        </Marker>
      ));
    } else if (selectedDC) {
      // Data Center view: Show devices (switches and gateways)
      const devices = [
        selectedDC.arista_sw && {
          ...selectedDC.arista_sw,
          type: "switch",
        },
        ...selectedDC.gateway.map((gw) => ({
          ...gw,
          type: "gateway",
        })),
      ].filter(Boolean);

      return devices.map((device, index) => (
        <Marker
          key={`device-${index}`}
          position={
            device.coordinates
              ? [device.coordinates.lat, device.coordinates.long]
              : [selectedDC.location.lat, selectedDC.location.long]
          }
          icon={device.type === "switch" ? switchIcon : gatewayIcon}
          eventHandlers={{
            click: () => {
              setSelectedDevice(device);
              handleShowModal();
            },
          }}
        >
          <Popup>
            <strong>{device.name}</strong>
          </Popup>
        </Marker>
      ));
    }
  };

  return (
    <div className="dc-map">
      <MapContainer
        center={center}
        zoom={2}
        style={{ height: mapConfig?.height || "60vh", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          attribution=""
        />
        <MarkerClusterGroup>{renderMarkers()}</MarkerClusterGroup>
      </MapContainer>

      {/* Back Button */}
      {(selectedRegion || selectedDC) && (
        <Button
          onClick={handleBack}
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            zIndex: 1000,
          }}
        >
          Back
        </Button>
      )}

      {/* Device Modal */}
      {selectedDevice && (
        <SingleView
          host={selectedDevice}
          showModal={showModal}
          closeModal={handleCloseModal}
        />
      )}
    </div>
  );
};

export default DeviceMap;
