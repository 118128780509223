import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card, Col, Row } from "react-bootstrap";
import OrchestratorsForm from "./AddForm";
import OrchestratorsListView from "./List";
import { useBreadcrumbs } from "contexts";
import { fetchOrchestrators } from "stores/actions";
import { usePermissions } from "hooks/usePermission";
import useActivityLogger from "hooks/useActivity";
import OnboardConcerto from "./OnboardConcerto";

const Orchestrators = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [onboardModal, setOnboardModal] = useState(false);
  const [formData, setFormData] = useState();
  const { orchestrators } = useSelector((state) => state.orchestrators);
  const { updateBreadcrumbs } = useBreadcrumbs();
  const logActivity = useActivityLogger();

  const sendLog = (page, activity, name) => {
    logActivity({
      page,
      activity,
      name,
    });
  };

  useEffect(() => {
    updateBreadcrumbs([{ text: "Home", url: "/dashboard" }]);
    sendLog("Orchestrators", "page-load", "Orchestrators");
    dispatch(fetchOrchestrators());
  }, []);

  return (
    <>
      <div className="middle-section">
        <Row>
          <Col sm={8}>
            <div className="my-2">
              <h5>
                <b>Orchestrators</b>
              </h5>
              <small className="sub-info">
                List of all Concerto and Titan nodes
              </small>
            </div>
            <Card className="mb-4">
              <Card.Body>
                <OrchestratorsListView sendLog={sendLog} />
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Row>
              <Col>
                <Card className="orange bg">
                  <Card.Body>
                    <h1>{orchestrators?.length || 0}</h1>
                    Orchestrators
                  </Card.Body>
                </Card>
              </Col>
              {usePermissions(["WRAPPERS_ADD"]) && (
                <>
                  <Col>
                    <Card
                      className="text-center"
                      onClick={() => setShowModal(true)}
                    >
                      <Card.Body className="">
                        <h1>+</h1>
                        New Orchestrator
                      </Card.Body>
                    </Card>
                    <OrchestratorsForm
                      formData={formData}
                      setFormData={setFormData}
                      showModal={showModal}
                      closeModal={() => setShowModal(false)}
                      sendLog={sendLog}
                    />
                  </Col>
                  <Col>
                    <Card
                      className="text-center"
                      onClick={() => setOnboardModal(true)}
                    >
                      <Card.Body className="">
                        <h1>+</h1>
                        Onboard Concerto
                      </Card.Body>
                    </Card>
                    <OnboardConcerto
                      showModal={onboardModal}
                      closeModal={() => setOnboardModal(false)}
                      sendLog={sendLog}
                    />
                  </Col>
                </>
              )}
            </Row>
            {/* <Activities type="orchestrator" /> */}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Orchestrators;
