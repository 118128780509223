import React, { useMemo, useState } from "react";
import SingleView from "pages/Host/SingleHostView";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import { useSelector } from "react-redux";

const ControllersView = ({ controllers }) => {
  const [selected, setSelected] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedController, setSelectedController] = useState(null);
  const { selectedHeadend: headend } = useSelector((state) => state.headend);
  const { regions } = useSelector((state) => state.dashboard);

  const orgMapping = useMemo(() => {
    const orgs = selectedController?.orgMapping?.map((org) => {
      return {
        name: headend?.organisations.find((o) => o._id === org.orgId)?.name,
        localId: org?.localId,
        localKey: org?.localKey,
        remoteId: org?.remoteId,
        remoteKey: org?.remoteKey,
      };
    });

    return orgs;
  }, [selectedController?.orgMapping, headend]);

  const controllerData = useMemo(() => {
    return controllers?.map((controller) => {
      return {
        id: controller._id,
        name: controller?.host?.name,
        ip: controller?.ip,
        region: regions.find(
          (region) => region._id === controller?.host?.region
        )?.name,
      };
    });
  }, [controllers]);

  const onView = (item) => {
    const selected = controllers?.find(
      (controller) => item.id === controller._id
    );
    setSelectedController(selected);
    setShowModal(true);
  };
  return (
    <>
      <div className="my-3">
        <h5>
          <b>Controllers</b>
        </h5>
        <small className="sub-info">List of all Controllers</small>
      </div>
      <PaginatedTable
        data={controllerData}
        columns={COLUMNS["controller"]}
        itemsPerPage={10}
        onView={onView}
      />
      <SingleView
        host={selectedController?.host}
        showModal={showModal}
        orgMapping={orgMapping}
        closeModal={() => {
          setSelectedController(null);
          setShowModal(false);
        }}
      />
    </>
  );
};

export default ControllersView;
