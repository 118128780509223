import { Card, Col, Row } from "react-bootstrap";
import HoverIcon from "./HoverIcon";
import Loader from "./Loader";

const DashboardCard = ({
  title,
  total,
  loading,
  defaultIcon,
  hoverIcon,
  bgColorClass,
  onClick,
}) => (
  <Col className="">
    <Card className={`box ${bgColorClass} bg`} onClick={onClick}>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Row>
          <Col>
            <h2>{loading ? <Loader /> : total}</h2>
            Total
          </Col>
          <Col>
            <HoverIcon
              defaultIcon={defaultIcon}
              hoverIcon={hoverIcon}
              className={`box-icon ${bgColorClass} cl`}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  </Col>
);

export default DashboardCard;
