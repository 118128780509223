// HeadendDetail.js
import React, { useEffect, useState } from "react";
import {
  HeadendTenants,
  HeadendAnalytics,
  HeadendAppliances,
  HeadendControllers,
  HeadendDirectors,
  HeadendGateways,
  HeadendBasic,
} from "./Forms";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import { diff } from "deep-diff";

import {
  fetchTenants,
  saveHeadend,
  fetchCategories,
  fetchHeadendById,
} from "stores/actions";
import { useBreadcrumbs } from "contexts";
import { useParams } from "react-router-dom";
import { updateHost } from "stores/actions";
import getTheDifference from "../../utils/getTheDifference";
import useActivityLogger from "hooks/useActivity";
import Loader from "components/Loader";

const HeadendForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { headend, loading } = useSelector((state) => state.headend);
  const [key, setKey] = useState("tab-basic");
  const [formData, setFormData] = useState(headend);
  const logActivity = useActivityLogger();

  const { updateBreadcrumbs } = useBreadcrumbs();
  const [isEdit, setIsEdit] = useState(false);

  const updateFormData = (type, data) => {
    setFormData({
      ...formData,
      [type]: data,
    });
  };

  const submitHeadend = async () => {
    logActivity({
      page: "headend-manage",
      activity: "save-headend",
      name: headend?.name,
    });
    const differences = diff(headend, formData);
    console.log(headend, differences);
    const structuredDifferences = getTheDifference(headend, differences);
    console.log(headend, structuredDifferences);
    if (structuredDifferences) {
      if (headend?._id) structuredDifferences._id = headend?._id;
      const response = await dispatch(saveHeadend(structuredDifferences));
      if (response.meta.requestStatus === "fulfilled") {
        setFormData(headend);
      }
    }
  };

  const updateHostInformation = async (host, index, dataArray, type) => {
    const updatedHost = await dispatch(updateHost(host));
    const updatedArray = [...dataArray];
    updatedArray[index] = {
      ...updatedArray[index],
      host: updatedHost,
    };

    updateFormData(type, updatedArray);
  };

  useEffect(() => {
    dispatch(fetchTenants());
    dispatch(fetchCategories());
    updateBreadcrumbs([
      { text: "Home", url: "/dashboard" },
      { text: "Headends", url: "/headends" },
    ]);
  }, []);

  useEffect(() => {
    if (id !== "new") {
      setIsEdit(true);
      dispatch(fetchHeadendById(id));
    } else setIsEdit(false);
  }, [id]);

  useEffect(() => {
    setFormData(headend);
  }, [headend]);

  const tabTitle = (title, count) => (
    <Row>
      <Col>{title}</Col>

      <Col className="col-auto">
        <Badge>{count}</Badge>
      </Col>
    </Row>
  );

  return (
    <>
      <div className="middle-section">
        {loading["fetchSingle"] ? (
          <Loader />
        ) : (
          <Card className="mb-4 step-form">
            <Card.Body>
              <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3 "
              >
                <Tab eventKey="tab-basic" title="Basic">
                  <HeadendBasic
                    formData={formData}
                    updateFormData={setFormData}
                    isEdit={isEdit}
                  />
                </Tab>
                <Tab
                  eventKey="tab-orgs"
                  title={tabTitle(
                    "Organisation",
                    formData?.organisations.length
                  )}
                >
                  <HeadendTenants
                    organisations={formData?.organisations}
                    updateFormData={(data) =>
                      updateFormData("organisations", data)
                    }
                    isEdit={isEdit}
                    updateHostInformation={updateHostInformation}
                  />
                </Tab>
                <Tab
                  eventKey="tab-director"
                  title={tabTitle("Directors", formData?.directors.length)}
                >
                  <HeadendDirectors
                    directors={formData?.directors}
                    updateFormData={(data) => updateFormData("directors", data)}
                    isEdit={isEdit}
                    updateHostInformation={updateHostInformation}
                  />
                </Tab>
                <Tab
                  eventKey="tab-controller"
                  title={tabTitle("Controllers", formData?.controllers.length)}
                >
                  <HeadendControllers
                    controllers={formData?.controllers}
                    organisations={formData?.organisations}
                    updateFormData={(data) =>
                      updateFormData("controllers", data)
                    }
                    isEdit={isEdit}
                    updateHostInformation={updateHostInformation}
                    isShared={formData?.infra?.name === "Shared"}
                  />
                </Tab>
                <Tab
                  eventKey="tab-analytics"
                  title={tabTitle("Analytics", formData?.analytics.length)}
                >
                  <HeadendAnalytics
                    analytics={formData?.analytics}
                    updateFormData={(data) => updateFormData("analytics", data)}
                    isEdit={isEdit}
                    updateHostInformation={updateHostInformation}
                  />
                </Tab>
                {formData?.gateways?.length > 0 && (
                  <Tab
                    eventKey="tab-gateway"
                    title={tabTitle("Gateways", formData?.gateways.length)}
                  >
                    <HeadendGateways
                      gateways={formData?.gateways}
                      updateFormData={(data) =>
                        updateFormData("gateways", data)
                      }
                      isEdit={isEdit}
                      updateHostInformation={updateHostInformation}
                    />
                  </Tab>
                )}
                {formData?.appliances?.length > 0 && (
                  <Tab
                    eventKey="tab-appl"
                    title={tabTitle("Appliances", formData?.appliances.length)}
                  >
                    <HeadendAppliances
                      appliances={formData?.appliances}
                      updateFormData={(data) =>
                        updateFormData("appliances", data)
                      }
                      isEdit={isEdit}
                      updateHostInformation={updateHostInformation}
                    />
                  </Tab>
                )}
              </Tabs>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col></Col>
                <Col className="col-auto">
                  <Button
                    variant="primary"
                    className="px-5"
                    onClick={submitHeadend}
                  >
                    {formData?._id ? "Update Headend" : "Add Headend"}
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        )}
      </div>
    </>
  );
};

export default HeadendForm;
