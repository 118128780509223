import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const BackButton = ({ title }) => {
  const navigate = useNavigate();
  return (
    <div className="link back-btn" onClick={() => navigate(-1)}>
      <small>
        <FontAwesomeIcon icon={faArrowLeft} /> {title}
      </small>
    </div>
  );
};

export default BackButton;
