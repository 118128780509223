export const usePermissions = (requiredPermissions) => {
  const currentUser = JSON.parse(localStorage.getItem("user"));

  const pages = currentUser?.role?.pages;
  const userPermissions = new Set();
  userPermissions.add("PROFILE_VIEW");

  pages?.forEach((page) =>
    page?.permissions?.forEach((permission) => {
      userPermissions.add(`${page.name}_${permission.name}`);
    })
  );

  return requiredPermissions?.every((permission) =>
    userPermissions.has(permission)
  );
};
