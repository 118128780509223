import { useState } from "react";
import { Container, Row } from "react-bootstrap";
import TopNavbar from "./TopNavbar";
import SideNavbar from "./SideNavbar";
import MainContent from "./MainContent";

const Layout = ({ children }) => {
  const isLoginPage = window.location.pathname === "/login";
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(
    localStorage.getItem("collapsed") === "yes" ? true : false
  );

  const toggleMenu = () => {
    localStorage.setItem(
      "collapsed",
      localStorage.getItem("collapsed") === "yes" ? "no" : "yes"
    );
    setIsMenuCollapsed(!isMenuCollapsed);
  };

  return (
    <>
      <Container fluid>
        <Row>
          {!isLoginPage && (
            <div
              className={`side-navbar ${
                !isLoginPage && isMenuCollapsed ? "side-collpased" : ""
              }`}
            >
              <SideNavbar isCollapsed={isMenuCollapsed} />
            </div>
          )}
          <div
            className={`content-container ${
              !isLoginPage && isMenuCollapsed ? "main-collapsed" : ""
            }`}
          >
            <MainContent>
              {!isLoginPage && (
                <TopNavbar
                  onToggleMenu={toggleMenu}
                  isMenuCollapsed={isMenuCollapsed}
                />
              )}

              {children}
            </MainContent>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Layout;
