import React, { createContext, useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories, fetchRegions, fetchVersion } from "stores/actions";
import {
  fetchHeadends,
  fetchPages,
  fetchPermissions,
  fetchRoles,
} from "stores/actions";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null);
  const dispatch = useDispatch();
  const [userConfig, setUserConfig] = useState({});
  const { headends } = useSelector((state) => state.headend);
  const { currentUser } = useSelector((state) => state.auth);

  const fetchInitialData = () => {
    dispatch(fetchRoles());
    dispatch(fetchPages());
    dispatch(fetchPermissions());
    dispatch(fetchCategories());
    dispatch(fetchRegions());
    dispatch(fetchVersion());
  };

  useEffect(() => {
    if (currentUser?.name) {
      if (localStorage.getItem("userInfo")) {
        setUserInfo(JSON.parse(localStorage.getItem("user")));
        if (headends.length === 0) dispatch(fetchHeadends());
      }
    }
    fetchInitialData();
  }, [currentUser]);

  return (
    <UserContext.Provider value={{ userInfo, setUserInfo, userConfig }}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => useContext(UserContext);

export { UserProvider, useUser };
