// HeadendAppliancesForm.js
import React, { useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { APPLIANCES_INIT } from "constants/headendInit";
import HostForm from "../../Host/AddForm";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import deepClone from "utils/deepClone";

const HeadendAppliancesForm = ({
  appliances,
  updateFormData,
  updateHostInformation,
  isEdit,
}) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [hostToEdit, setEditToHost] = useState(null);

  const appliancesData = useMemo(() => {
    return appliances?.map((appliance) => {
      return {
        id: appliance?._id,
        name: appliance?.host?.name,
        ownerOrg: appliance?.host?.ownerOrgName,
        type: appliance?.host?.type,
        managedByUs:
          appliance?.ownerOrg?.toLowerCase() === "versa" ? "Yes" : "No",
      };
    });
  }, [appliances]);

  const handleApplianceChange = (index, e) => {
    const { name, value } = e.target;
    const updatedAppliances = [...appliances];
    updatedAppliances[index] = { ...updatedAppliances[index], [name]: value };
    updateFormData(updatedAppliances);
  };

  const addAppliance = () => {
    updateFormData([...appliances, APPLIANCES_INIT]);
  };

  const onView = (item) => {
    const selected = appliances.find((appliance) => item.id === appliance?._id);
    setEditToHost(selected);
  };
  const onEdit = (item) => {
    const selected = appliances.find((appliance) => item.id === appliance._id);
    setEditToHost(selected);
    setShowModal(true);
  };
  const updateHost = (hostData) => {
    const updatedAppliances = deepClone(appliances);
    const index = updatedAppliances.findIndex(
      (dir) => dir?.host.name === hostData.name
    );
    if (index > -1) {
      updatedAppliances[index].host = hostData;
    }
    updateFormData(updatedAppliances);
  };

  return (
    <Container>
      <PaginatedTable
        data={appliancesData}
        columns={COLUMNS["appliance"]}
        itemsPerPage={25}
        onEdit={onEdit}
      />
      <HostForm
        host={hostToEdit?.host}
        removeHost={setEditToHost}
        showModal={showModal}
        isEdit={true}
        updateHostDetails={updateHost}
        closeModal={() => {
          setEditToHost(null);
          setShowModal(false);
        }}
      />
    </Container>
  );
};

export default HeadendAppliancesForm;
