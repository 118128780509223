import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card, Col, Form, Row } from "react-bootstrap";
import IspForm from "./AddForm";
import { ISP_INIT } from "constants/headendInit";
import { saveIsp } from "stores/actions";
import IspListView from "./List";
import { useBreadcrumbs } from "../../contexts";
import { fetchActivityLogs, fetchIsps } from "stores/actions";
import { colors, mapping } from "../../constants/mapping";
import Activities from "../../components/Activities";
import { usePermissions } from "../../hooks/usePermission";
import useActivityLogger from "hooks/useActivity";

const Isps = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(ISP_INIT);
  const { isps } = useSelector((state) => state.isps);
  const { activityLogs } = useSelector((state) => state.dashboard);
  const { updateBreadcrumbs } = useBreadcrumbs();
  const logActivity = useActivityLogger();

  const sendLog = (page, activity, name) => {
    logActivity({
      page,
      activity,
      name,
    });
  };

  useEffect(() => {
    updateBreadcrumbs([{ text: "Home", url: "/dashboard" }]);
    sendLog("Isps", "page-load", "Isps");
    dispatch(fetchIsps());
  }, []);

  const saveIspDetails = () => {
    sendLog("Isps", "save-isp", formData?.name);
    dispatch(saveIsp(formData));
  };

  return (
    <>
      <div className="middle-section">
        <Row>
          <Col sm={8}>
            <div className="my-2">
              <h5>
                <b>Internet Service Providers</b>
              </h5>
              <small className="sub-info">
                List all ISPs and their details
              </small>
            </div>
            <Card className="mb-4">
              <Card.Body>
                <IspListView sendLog={sendLog} />
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Row>
              <Col>
                <Card className="green bg">
                  <Card.Body>
                    <h1>{isps?.length || 0}</h1>
                    ISPs
                  </Card.Body>
                </Card>
              </Col>
              {usePermissions(["ISPS_ADD"]) && (
                <Col>
                  <Card
                    className="text-center"
                    onClick={() => setShowModal(true)}
                  >
                    <Card.Body className="">
                      <h1> +</h1>
                      New Isp
                    </Card.Body>
                  </Card>
                  <IspForm
                    formData={formData}
                    setFormData={setFormData}
                    onAdd={saveIspDetails}
                    showModal={showModal}
                    closeModal={() => setShowModal(false)}
                  />
                </Col>
              )}
            </Row>
            {/* <Activities type="isp" /> */}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Isps;
