import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "services/api";
import React from "react";
import { showAlert } from "stores/slices/alertSlice";

const fetchDashboard = createAsyncThunk(
  "dashboard/fetchDashboard",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/dashboard`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchCatSummary = createAsyncThunk(
  "dashboard/fetchCatSummary",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/dashboard/cat-summary`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const globalSearch = createAsyncThunk(
  "dashboard/globalSearch",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(`/dashboard/search?query=${data}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const bulkUpload = (formData) => async (dispatch) => {
  try {
    const response = await API.post("/dashboard/bulkupload", formData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const sendFeedback = (formData) => async (dispatch) => {
  try {
    const response = await API.post("/dashboard/feedback", formData);
    dispatch(
      showAlert({
        message: "Feedback sent successfully",
        variant: "success",
      })
    );
    return response;
  } catch (error) {
    dispatch(
      showAlert({
        message: "Failed to send feedback",
        variant: "danger",
      })
    );
    throw error;
  }
};

const fetchRegions = createAsyncThunk(
  "dashboard/fetchRegions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/region`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchVersion = createAsyncThunk(
  "dashboard/fetchVersion",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/dashboard/version`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export {
  fetchDashboard,
  fetchCatSummary,
  globalSearch,
  bulkUpload,
  sendFeedback,
  fetchRegions,
  fetchVersion,
};
