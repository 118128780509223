import React, { useMemo, useState } from "react";

import SingleView from "pages/Host/SingleHostView";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import { useSelector } from "react-redux";

const DirectorsView = ({ directors }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedDirector, setSelectedDirector] = useState(null);
  const { regions } = useSelector((state) => state.dashboard);

  const directorData = useMemo(() => {
    return directors.map((director) => {
      return {
        ...director.host,
        id: director._id,
        name: director?.host?.name,
        fqdn: director?.host?.fqdn,
        region: regions.find((region) => region._id === director?.host?.region)
          ?.name,
        primary: director?.host?.isPrimary ? "primary" : "secondary",
      };
    });
  }, [directors]);

  const onView = (item) => {
    const selected = directors.find((director) => item.id === director._id);
    setSelectedDirector(selected);
    setShowModal(true);
  };

  return (
    <>
      <div className="my-3">
        <h5>
          <b>Directors</b>
        </h5>
        <small className="sub-info">Headend director lists</small>
      </div>
      <PaginatedTable
        data={directorData}
        columns={COLUMNS["director"]}
        itemsPerPage={10}
        onView={onView}
      />
      <SingleView
        host={selectedDirector?.host}
        showModal={showModal}
        closeModal={() => {
          setSelectedDirector(null);
          setShowModal(false);
        }}
      />
    </>
  );
};

export default DirectorsView;
