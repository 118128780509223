import Highlight from "components/HighlightText";
import { useNavigate } from "react-router-dom";

const {
  Accordion,
  Badge,
  ListGroup,
  Card,
  Col,
  Row,
} = require("react-bootstrap");

const HeadendResult = ({ headends, sendLog, query }) => {
  const navigate = useNavigate();
  const redirectToManage = (headend) => {
    // updateBreadcrumbs([{ text: "Home", url: "/dashboard" }]);
    sendLog("redirect", headend?.name);
    navigate(`/headends/${headend?._id}`);
  };

  return (
    <Accordion.Item eventKey="headends">
      <Accordion.Header>
        Headends :
        <Badge pill bg="info">
          {headends?.length}
        </Badge>
      </Accordion.Header>
      <Accordion.Body>
        <Row>
          {headends?.map((headend) => (
            <Col sm={3} className="mb-3">
              <Card>
                <Card.Body className="p-0">
                  <ListGroup variant="flush">
                    <ListGroup.Item className="mb-1">
                      <span>Name</span>

                      <b
                        className="link"
                        onClick={() => redirectToManage(headend)}
                      >
                        <Highlight text={headend?.name} highlight={query} />
                      </b>
                    </ListGroup.Item>
                    <ListGroup.Item className="mb-1">
                      <span>Category</span>

                      <b>
                        <Highlight
                          text={headend?.category[0]?.name}
                          highlight={query}
                        />
                      </b>
                    </ListGroup.Item>
                    <ListGroup.Item className="mb-1">
                      <span>FQDN</span>

                      <b className="ellipsis">
                        <Highlight text={headend?.fqdn} highlight={query} />
                      </b>
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default HeadendResult;
