import { UserManager, Log } from "oidc-client";
import userConfigDetails from "../utils/userConfig";

// Enable detailed logging for OIDC client (Optional for debugging)
Log.logger = console;
Log.level = Log.DEBUG;

const userManager = new UserManager(userConfigDetails);

// Event: User Loaded
userManager.events.addUserLoaded((user) => {
  console.log("User loaded:", user);
  // Optionally, you could avoid manually saving user and token here.
  // localStorage.setItem("user", JSON.stringify(user));
  // localStorage.setItem("token", user?.access_token);
});

// Event: User Unloaded
userManager.events.addUserUnloaded(() => {
  console.log("User unloaded. Optionally handle re-login or session end.");
});

userManager.events.addAccessTokenExpired(() => {
  console.log("Access token expired, redirecting to login...");
  login();
});

userManager.events.addAccessTokenExpiring(() => {
  console.log("Access token is expiring soon, attempting silent renew...");
  localStorage.setItem("currentUrl", window.location.href);
  silentRenew();
});

// Get User
const getUser = () => userManager.getUser();

// Login (Redirect to OIDC provider)
const login = () => {
  // Clear old tokens if necessary
  localStorage.clear(); // Ensure no stale tokens are present
  userManager.signinRedirect().catch((error) => {
    console.error("Error during signinRedirect:", error);
  });
};

// Handle OIDC Callback (After successful authentication)
const handleCallback = () => {
  return userManager.signinRedirectCallback().catch((error) => {
    console.error("Error during signinRedirectCallback:", error);
  });
};

// Logout (Redirect to OIDC provider's logout endpoint)
const logout = async () => {
  try {
    await userManager.stopSilentRenew(); // Stop the silent renew process
    await userManager.signoutRedirect(); // Redirect to the IdP logout endpoint
  } catch (error) {
    console.error("Error during logout:", error);
  }
};

// Handle Logout Callback (After successful logout)
const handleLogoutCallback = () => {
  return userManager.signoutRedirectCallback().catch((error) => {
    console.error("Error during signoutRedirectCallback:", error);
  });
};

// Silent Renew Token (Attempt to silently renew token using iframe)
const silentRenew = () => {
  // Capture the current URL before performing silent renew

  return userManager
    .signinSilent()
    .then(() => {
      console.log("Silent renew successful, staying on current page");
      // After successful silent renew, redirect back to the current URL
      window.location.href = localStorage.getItem("currentUrl");
    })
    .catch((error) => {
      console.error("Silent renew error:", error);

      // Handle specific cases for silent renew failure
      if (error.error === "login_required") {
        console.log("Silent renew failed, redirecting to login");
        login(); // Redirect to login if the token cannot be silently renewed
      } else if (error.error === "interaction_required") {
        console.log("User interaction required, redirecting to login");
        login();
      } else {
        console.error("Unexpected silent renew error:", error);
      }
    });
};

// Remove User Data (From OIDC client and localStorage)
const removeUser = () => {
  return userManager.removeUser().catch((error) => {
    console.error("Error removing user:", error);
  });
};

export {
  userManager,
  getUser,
  login,
  handleCallback,
  logout,
  silentRenew,
  handleLogoutCallback,
  removeUser,
};
