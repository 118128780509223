// HeadendDirectorsForm.js
import React, { useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { DIRECTOR_INIT } from "constants/headendInit";
import deepClone from "utils/deepClone";
import { PaginatedTable } from "components";
import HostForm from "../../Host/AddForm";
import COLUMNS from "constants/columns";

const HeadendDirectorsForm = ({
  directors,
  updateFormData,
  updateHostInformation,
  isEdit,
}) => {
  const guiUserTypes = ["admin", "monitoring"];
  const [showModal, setShowModal] = useState(false);
  const [hostToEdit, setEditToHost] = useState(null);
  const { regions } = useSelector((state) => state.dashboard);
  // tacacs

  const handleDirectorChange = (index, e) => {
    const { name, value } = e.target;
    const updatedDirectors = deepClone(directors);
    updatedDirectors[index] = { ...updatedDirectors[index], [name]: value };
    updateFormData(updatedDirectors);
  };

  const handleGuiChange = (index, guiIndex, e) => {
    const { name, value } = e.target;
    const updatedDirectors = deepClone(directors);
    updatedDirectors[index].guiCredentials[guiIndex] = {
      ...updatedDirectors[index].guiCredentials[guiIndex],
      [name]: value,
    };
    updateFormData(updatedDirectors);
  };

  const handleSSHChange = (dirIndex, sshIndex, e) => {
    const { name, value } = e.target;
    const updatedDirectors = deepClone(directors);
    updatedDirectors[dirIndex].sshCredentials[sshIndex] = {
      ...updatedDirectors[dirIndex].sshCredentials[sshIndex],
      [name]: value,
    };
    updateFormData(updatedDirectors);
  };

  const addDirector = () => {
    updateFormData([...directors, { ...DIRECTOR_INIT, isPrimary: false }]);
  };

  const addCredentials = (dirIndex, type) => {
    const updatedDirectors = deepClone(directors);
    const creds = DIRECTOR_INIT[type];
    updatedDirectors[dirIndex][type].push(creds[0]);
    updateFormData(updatedDirectors);
  };
  const deleteSsh = (dirIndex, sshIndex) => {
    const updatedDirectors = deepClone(directors);
    updatedDirectors[dirIndex].sshCredentials.splice(sshIndex, 1);
    updateFormData(updatedDirectors);
  };

  const deleteGui = (dirIndex, guiIndex) => {
    const updatedDirectors = deepClone(directors);
    updatedDirectors[dirIndex].guiCredentials.splice(guiIndex, 1);
    updateFormData(updatedDirectors);
  };

  const onEdit = (item) => {
    console.log("ONEDIT ", item);
    setEditToHost(item?.host);
    setShowModal(true);
  };

  const updateHost = (hostData) => {
    console.log("UPDATE HOST", hostData);
    const updatedDirectors = deepClone(directors);
    const index = updatedDirectors.findIndex(
      (dir) => dir?.host.name === hostData.name
    );
    if (index > -1) {
      updatedDirectors[index].host = hostData;
    }
    updateFormData(updatedDirectors);
  };

  const directorsData = useMemo(() => {
    return directors?.map((director) => {
      return {
        ...director,
        id: director?.host._id,
        primary: director?.host?.isPrimary ? "Primary" : "Secondary",
        name: director?.host?.name,
        region: regions.find((region) => region._id === director?.host?.region)
          ?.name,
        fqdn: director?.host.fqdn,
      };
    });
  }, [directors]);

  return (
    <div>
      <PaginatedTable
        data={directorsData}
        columns={COLUMNS["director"]}
        itemsPerPage={25}
        onEdit={onEdit}
      />

      {!isEdit && (
        <Button variant="primary" size="sm" onClick={addDirector}>
          + Director
        </Button>
      )}

      <HostForm
        host={hostToEdit}
        removeHost={setEditToHost}
        showModal={showModal}
        isEdit={true}
        updateHostDetails={updateHost}
        closeModal={() => {
          setEditToHost(null);
          setShowModal(false);
        }}
      />
    </div>
  );
};

export default HeadendDirectorsForm;
