const ISP_PROVIDER_INFO = [
  {
    label: "Bandwidth",
    value: "bandwidth",
  },
  {
    label: "Local AS",
    value: "local_as",
  },
  {
    label: "Remote AS",
    value: "remote_as",
  },
  {
    label: "Versa Side IP",
    value: "versa_side_ip",
  },
  {
    label: "ISP Side IP",
    value: "isp_side_ip",
  },
  {
    label: " Circuit ID",
    value: "circuit_id",
  },
];

export { ISP_PROVIDER_INFO };
