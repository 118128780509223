import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import CustomPieChart from "./Charts/CustomPieChart";
import BackupStatusCard from "pages/Backups/components/BackupStatusCard";

const BackupAuditSummary = () => {
  const { backupReport } = useSelector((state) => state.audit);

  const cardData = useMemo(() => {
    if (!backupReport || Object.keys(backupReport).length === 0) {
      return [];
    }

    return Object.keys(backupReport).flatMap((key) => backupReport[key]);
  }, [backupReport]);

  const createPieChartData = () => {
    const newStat = { failed: [], mismatch: [], enabled: [] };
    cardData?.forEach((item) => {
      if (item.status === "missing") newStat.mismatch.push(item);
      else if (item.status === "not found") newStat.failed.push(item);
      else newStat.enabled.push(item);
    });

    return [
      {
        name: "Failed",
        value: newStat.failed.length,
        key: "failed",
      },
      { name: "Missing", value: newStat.mismatch.length, key: "mismatch" },
      { name: "Enabled", value: newStat.enabled.length, key: "success" },
    ];
  };

  return (
    <>
      <CustomPieChart data={createPieChartData()} />
      <BackupStatusCard data={cardData} list={true} />
    </>
  );
};

export default BackupAuditSummary;
