import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import { usePermissions } from "hooks/usePermission";
import { fetchHosts } from "stores/actions";
import SingleView from "pages/Host/SingleHostView";
import HostForm from "pages/Host/AddForm";

const GatewayList = ({ sendLog }) => {
  const [showModal, setShowModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedHost, setSelectedHost] = useState(null);
  const [hostToEdit, setEditToHost] = useState(null);
  const { hosts, loading } = useSelector((state) => state.host);
  const dispatch = useDispatch();
  const hasViewPermission = usePermissions(["HOSTS_VIEW"]);
  const hasEditPermission = usePermissions(["HOSTS_EDIT"]);

  useEffect(() => {
    dispatch(fetchHosts("gateway"));
  }, []);

  const hostData = useMemo(() => {
    return hosts?.map((host) => ({
      ...host,
      id: host._id,
      name: host?.name,
      headend: `${host?.headendCode ?? ""} ${host?.headendName ?? ""}`,
      category: host?.subtype || host?.category?.name,
      spackVersion: host?.spackVersion,
      owner: host?.ownerOrgName,
      osspackVersion: host?.osspackVersion,
    }));
  }, [hosts]);

  const onView = useCallback(
    (item) => {
      // const selected = selectHost(item);
      sendLog("gateway", "click-gateway-view", item?.name);
      setSelectedHost(item);
      setShowViewModal(true);
    },
    [hosts, sendLog]
  );

  const onEdit = useCallback(
    (item) => {
      sendLog("gateway", "click-gateway-edit", item?.name);
      setEditToHost(item);
      setShowModal(true);
    },
    [hosts, sendLog]
  );

  return (
    <div>
      <PaginatedTable
        data={hostData || []}
        columns={COLUMNS["dcGateway"]}
        perPage={20}
        onView={hasViewPermission ? onView : null}
        onEdit={hasEditPermission ? onEdit : null}
      />

      <HostForm
        host={hostToEdit}
        removeHost={setEditToHost}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        isEdit={!!hostToEdit?._id}
      />
      <SingleView
        host={selectedHost}
        removeHost={setSelectedHost}
        showModal={showViewModal}
        closeModal={() => setShowViewModal(false)}
      />
    </div>
  );
};

export default GatewayList;
