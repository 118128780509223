import { useState } from "react";
import { Accordion, Card, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { globalSearch } from "stores/actions";
import useActivityLogger from "hooks/useActivity";
import HeadendResult from "./Components/HeadendResult";
import HostResult from "./Components/HostResult";
import OrganisationResult from "./Components/OrganisationResult";
import IspResult from "./Components/IspResult";
import OrchestratorResult from "./Components/OrchestratorResult";
import Loader from "components/Loader";
import UsersResult from "./Components/UsersResult";

const Search = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");

  const { searchResults, loading } = useSelector((state) => state.dashboard);

  const logActivity = useActivityLogger();

  const sendLog = (activity, name) => {
    logActivity({
      page: "search",
      activity,
      name,
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (query.length > 0) {
      sendLog("search", query);
      dispatch(globalSearch(query));
    }
  };

  return (
    <>
      <div className="middle-section">
        <Card className="my-2">
          <Card.Body>
            <Row className="">
              <Col md={3}>
                <div>
                  <h5>
                    <b>Global Search</b>
                  </h5>
                  <small className="sub-info">
                    Will search entire database
                  </small>
                </div>
              </Col>
              <Col>
                <Form onSubmit={handleSearch} className="mt-1">
                  <Form.Group controlId="formSearch">
                    <Form.Control
                      type="text"
                      className="search-input"
                      placeholder="Enter search query..."
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col className="col-auto">
                {searchResults?.query?.length > 0 && (
                  <h6 className="mt-2 ellipsis">
                    Found <b>{searchResults?.length} </b>results for{" "}
                    <b>{searchResults?.query}</b>
                  </h6>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {loading["search"] ? (
          <Card className="m-4">
            <Card.Body>
              <Loader
                content="Scanning the Asset Database... Hang tight, we're digging up the
                treasures!"
              />
            </Card.Body>
          </Card>
        ) : searchResults?.length > 0 ? (
          <>
            <Accordion flush>
              {searchResults?.headends?.length > 0 && (
                <HeadendResult
                  headends={searchResults?.headends}
                  sendLog={sendLog}
                  query={searchResults?.query}
                />
              )}
              {searchResults?.hosts?.length > 0 && (
                <HostResult
                  hosts={searchResults?.hosts}
                  sendLog={sendLog}
                  query={searchResults?.query}
                />
              )}
              {searchResults?.organisations?.length > 0 && (
                <OrganisationResult
                  organisations={searchResults?.organisations}
                  sendLog={sendLog}
                  query={searchResults?.query}
                />
              )}
              {searchResults?.isps?.length > 0 && (
                <IspResult
                  isps={searchResults?.isps}
                  sendLog={sendLog}
                  query={searchResults?.query}
                />
              )}
              {searchResults?.orchestrators?.length > 0 && (
                <OrchestratorResult
                  orchestrators={searchResults?.orchestrators}
                  sendLog={sendLog}
                  query={searchResults?.query}
                />
              )}
              {searchResults?.users?.length > 0 && (
                <UsersResult
                  users={searchResults?.users}
                  sendLog={sendLog}
                  query={searchResults?.query}
                />
              )}
            </Accordion>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Search;
