import React, { useEffect, useState } from "react";
import { Badge, Col, ListGroup, Row } from "react-bootstrap";

const BackupStatusCard = ({ data, list = false }) => {
  const [stat, setStat] = useState({
    failed: [],
    mismatch: [],
    enabled: [],
  });

  const updateStats = (report) => {
    const newStat = { failed: [], mismatch: [], enabled: [] };
    report?.forEach((item) => {
      if (item.status === "missing") newStat.mismatch.push(item);
      else if (item.status.toLowerCase() === "not found")
        newStat.failed.push(item);
      else newStat.enabled.push(item);
    });
    setStat(newStat);
  };

  useEffect(() => {
    if (data) updateStats(data);
  }, [data]);

  return (
    <>
      {list ? (
        <>
          <ListGroup variant="flush">
            <ListGroup.Item
              className="mb-1 hover-item"
              style={{ cursor: "pointer" }}
            >
              <span className="link" style={{ textTransform: "capitalize" }}>
                <b>Failed</b>
              </span>

              <b style={{ fontSize: "0.9rem" }}>
                <Badge bg="danger">{stat.failed.length}</Badge>
              </b>
            </ListGroup.Item>
            <ListGroup.Item
              className="mb-1 hover-item"
              style={{ cursor: "pointer" }}
              // onClick={() => sendLog(fieldText)}
            >
              <span className="link" style={{ textTransform: "capitalize" }}>
                <b>Missing</b>
              </span>

              <b style={{ fontSize: "0.9rem" }}>
                <Badge bg="warning">{stat.mismatch.length}</Badge>
              </b>
            </ListGroup.Item>
            <ListGroup.Item
              className="mb-1 hover-item"
              style={{ cursor: "pointer" }}
              // onClick={() => sendLog(fieldText)}
            >
              <span className="link" style={{ textTransform: "capitalize" }}>
                <b>Success</b>
              </span>

              <b style={{ fontSize: "0.9rem" }}>
                <Badge bg="success">{stat.enabled.length}</Badge>
              </b>
            </ListGroup.Item>
          </ListGroup>
        </>
      ) : (
        <Row>
          <Col>
            <h5 className="p-2 px-3 red bg">
              {stat.failed.length}
              <br />
              <small style={{ fontSize: "1rem" }}>Failed</small>
            </h5>
          </Col>
          <Col>
            <h5 className="p-2 px-3 orange bg">
              {stat.mismatch.length}
              <br />
              <small style={{ fontSize: "1rem" }}>Missing</small>
            </h5>
          </Col>
          <Col>
            <h5 className="p-2 px-3 green bg">
              {stat.enabled.length}
              <br />
              <small style={{ fontSize: "1rem" }}>Success </small>
            </h5>
          </Col>
        </Row>
      )}
    </>
  );
};

export default BackupStatusCard;
