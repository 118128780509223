import useActivityLogger from "hooks/useActivity";
import React, { useMemo } from "react";
import { ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const HostSummary = ({ hosts, canNavigate }) => {
  const logActivity = useActivityLogger();
  const navigate = useNavigate();

  const navigateToPage = (page, type) => {
    logActivity({
      page: "dashboard",
      activity: "click",
      name: type,
    });
    if (canNavigate) navigate(page);
  };

  const updatedHost = useMemo(() => {
    if (!hosts?.categorised) return [];
    const vnf = { _id: "", category_name: "service_vnf", count: 0 };
    const newHosts = hosts.categorised.reduce((acc, host) => {
      if (host?.category_name?.replace(/[_-]/, " ") === "service vnf") {
        vnf.count += host.count;
        vnf._id = host._id;
      } else if (host?._id && host?.category_name) {
        acc.push(host);
      }
      return acc;
    }, []);

    newHosts.push(vnf);

    return newHosts;
  }, [hosts]);

  return (
    <div>
      <ListGroup variant="flush">
        {updatedHost?.map((host) => (
          <React.Fragment key={host?._id}>
            {host?._id && (
              <ListGroup.Item
                className={`mb-1 ${canNavigate && "hover-item"}`}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  canNavigate &&
                  navigateToPage(
                    `/host#${host?.category_name.toLowerCase()}`,
                    "host?.category_name"
                  )
                }
              >
                <span
                  style={{ textTransform: "uppercase" }}
                  className={`${canNavigate && "link"}`}
                >
                  <b>{host?.category_name?.replace("_", " ")}</b>
                </span>

                <b className="gradient-txt" style={{ fontSize: "0.9rem" }}>
                  {host?.count}
                </b>
              </ListGroup.Item>
            )}
          </React.Fragment>
        ))}
      </ListGroup>
    </div>
  );
};

export default HostSummary;
