import React from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { PaginatedTable } from "components";
import COLUMNS from "constants/columns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import BackupStatusCard from "./BackupStatusCard";
import capitalize from "utils/capitalize";

const BackupAuditSingleView = ({ data, dataKey, setDataKey }) => {
  const formatTableData = (keyData) => {
    if (!keyData || keyData.length === 0) return [];
    return keyData.map((audit) => {
      return {
        ...audit,
        status: audit?.status === "not found" ? "failed" : audit?.status,
        files: (
          <React.Fragment>
            <b>{audit.comment}</b>
            <ul>
              {audit?.files?.map(
                (file, index) =>
                  file?.status?.toLowerCase() === "not found" && (
                    <li key={`file-${index}`}>
                      <>
                        {file.file_name} ({file.file_size} B) -{" "}
                        <FontAwesomeIcon
                          className="cl red"
                          icon={faCircleXmark}
                        />
                      </>
                    </li>
                  )
              )}
            </ul>
          </React.Fragment>
        ),
      };
    });
  };

  // General component to render each Tab with its respective data
  const renderTabContent = (keyData, tabKey, title) => (
    <Tab eventKey={tabKey} title={capitalize(title)}>
      <Row className="mt-3 mb-3">
        <Col md={9}>
          <h5>
            <b>{capitalize(title)} report</b>
          </h5>
          <small className="sub-info">
            All audit related to {title} backup
          </small>
        </Col>
        <Col>
          <BackupStatusCard data={keyData} />
        </Col>
      </Row>

      <PaginatedTable
        columns={COLUMNS["backupAudit"]}
        data={keyData ? formatTableData(keyData) : []}
      />
    </Tab>
  );

  return (
    <>
      <Tabs
        id="dashboard-tabs"
        activeKey={dataKey}
        onSelect={(k) => setDataKey(k)}
        className=""
      >
        {!!data &&
          Object.keys(data).length > 0 &&
          Object.keys(data).map((key, index) =>
            renderTabContent(data[key], key, key, "")
          )}
      </Tabs>
    </>
  );
};

export default BackupAuditSingleView;
