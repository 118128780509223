import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { handleCallback } from "services/auth";
import { useAuth } from "contexts";
import { Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { register } from "stores/actions";
import useActivityLogger from "hooks/useActivity";

const Callback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setUser } = useAuth();
  const logActivity = useActivityLogger();

  const updateCurrentUser = async (profile) => {
    try {
      const user = await dispatch(register(profile));
      logActivity({
        page: "login",
        activity: "idp-login",
      });
      navigate("/dashboard");
    } catch (error) {
      console.error("Failed to update user:", error);
    }
  };

  useEffect(() => {
    handleCallback()
      .then((user) => {
        setUser(user);
        updateCurrentUser(user?.profile);
      })
      .catch((err) => {
        console.error("OIDC Callback Error:", err);
      });
  }, [navigate]);

  return (
    <div
      className="bg-image"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Card className="p-4 w-50">
        <Card.Body>
          <center>Redirecting to Maestro...</center>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Callback;
