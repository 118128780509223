import React, { useEffect } from "react";
import { useBreadcrumbs } from "../../contexts";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBackupAuditReport,
  fetchDashboard,
  fetchDCs,
  fetchMonitoringAuditSummary,
} from "stores/actions";

import { fetchBackupSummary, fetchCatSummary } from "stores/actions";
import CategorySummary from "./Components/CategorySummary";
import GatewaySummary from "./Components/GatewaysSummary";
import Summary from "./Components/Summary";
import HostSummary from "../../components/HostSummary";
import useActivityLogger from "hooks/useActivity";
import { usePermissions } from "hooks/usePermission";
import {
  faArrowRight,
  faShieldHalved,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DcMap from "components/Map/dcMap";
import MonitorAuditSummary from "components/MonitorAuditSummary";
import BackupAuditSummary from "components/BackupAuditSummary";

const Dashboard = () => {
  const dispatch = useDispatch();
  const canNavigate = usePermissions(["DASHBOARD_NAVIGATION"]);

  const navigate = useNavigate();
  const { hosts } = useSelector((state) => state.dashboard);
  const { dataCenters } = useSelector((state) => state.dataCenter);
  const { updateBreadcrumbs } = useBreadcrumbs();
  const logActivity = useActivityLogger();
  const mapConfig = {
    width: "100%",
    height: "40vh", // Full height map
  };

  const navigateToPage = (page, type) => {
    logActivity({
      page: "dashboard",
      activity: "click",
      name: type,
    });
    if (canNavigate) navigate(page);
  };
  useEffect(() => {
    updateBreadcrumbs([{ text: "", url: "/" }]);
    dispatch(fetchDashboard());
    dispatch(fetchCatSummary());
    dispatch(fetchDCs());
    dispatch(fetchMonitoringAuditSummary());
    dispatch(fetchBackupAuditReport());
  }, []);

  useEffect(() => {
    logActivity({
      page: "dashboard",
      activity: "page-load",
    });
  }, []);

  return (
    <div className="dashboard">
      <div className="middle-section mt-1">
        {/* First Row */}
        <Row className="mb-3">
          {/* <Col sm={2}>
            <div
              className="bg-image top-section m-0 mb-3 p-4"
              style={{ color: "#fff" }}
            >
              <h3>
                Welcome <br />
                <b>{user?.profile?.name}!</b>
              </h3>
            </div>
          </Col> */}
          <Col>
            <Summary
              canNavigate={canNavigate}
              navigateToPage={navigateToPage}
            />
          </Col>
        </Row>
        {/* Second Row */}
        <Row>
          <Col sm={9}>
            <Row>
              <Col md={4}>
                <GatewaySummary isDashboard={true} />
              </Col>

              <Col md={4}>
                {/* <BackupSummary
                  isDashboard={true}
                  navigateToPage={navigateToPage}
                /> */}

                <Card>
                  <Card.Body>
                    <Row
                      className="mb-3 "
                      onClick={() =>
                        navigateToPage("/backups", "backup-audit-report")
                      }
                    >
                      <Col>
                        <div className="">
                          <h5>
                            <b>Backup Audit Report</b>
                          </h5>
                          <small className="sub-info">
                            <span>Check latest reports</span>
                          </small>
                        </div>
                      </Col>
                      <Col
                        sm={2}
                        style={{ fontSize: "2rem", cursor: "pointer" }}
                      >
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Col>
                    </Row>
                    <BackupAuditSummary />
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card>
                  <Card.Body>
                    <Row
                      className="mb-3 "
                      onClick={() =>
                        navigateToPage("/monitoring", "monitor-audit-report")
                      }
                    >
                      <Col>
                        <div className="">
                          <h5>
                            <b>Monitoring Audit Report</b>
                          </h5>
                          <small className="sub-info">
                            <span>Check latest reports</span>
                          </small>
                        </div>
                      </Col>
                      <Col
                        sm={2}
                        style={{ fontSize: "2rem", cursor: "pointer" }}
                      >
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Col>
                    </Row>
                    <MonitorAuditSummary />
                  </Card.Body>
                </Card>
              </Col>
              <Col md={12} className="mb-3 mt-3">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <h5>
                            <b>Regions & Data Centers</b>
                          </h5>
                          <small className="sub-info">
                            <span>View all DCs in a particular region</span>
                          </small>
                        </div>
                      </Col>
                      <Col
                        sm={1}
                        style={{ fontSize: "1.7rem" }}
                        onClick={() => navigateToPage("/dc", "dc")}
                        className="hover-item icon"
                      >
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Col>
                    </Row>

                    {dataCenters.length > 0 && (
                      <DcMap dataCenters={dataCenters} mapConfig={mapConfig} />
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={3}>
            <Card
              className="mb-3 px-2 py-2 hover-item"
              onClick={() => navigateToPage("/security", "security-report")}
            >
              <Card.Body>
                <Row>
                  <Col sm={2} style={{ fontSize: "2rem" }}>
                    <FontAwesomeIcon icon={faShieldHalved} />
                  </Col>
                  <Col>
                    <div className="mx-1 mb-1">
                      <h5>
                        <b>Security Reports</b>
                      </h5>
                      <small className="sub-info">
                        <span>Check last scanned reports</span>
                      </small>
                    </div>
                  </Col>
                  <Col sm={2} style={{ fontSize: "2rem" }}>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <CategorySummary canNavigate={canNavigate} />

            <Card>
              <Card.Body>
                <div className=" mb-3">
                  <h5>
                    <b>Host Summary</b>
                  </h5>
                  <small className="sub-info">
                    Categorised summary of all hosts
                  </small>
                </div>
                <HostSummary hosts={hosts} canNavigate={canNavigate} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row></Row>
      </div>

      {/* <WebSocketClient /> */}
    </div>
  );
};

export default React.memo(Dashboard);
