import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Row, Col, Modal, Card } from "react-bootstrap";
import {
  fetchHeadendById,
  saveHeadend,
  saveHost,
  updateHost,
} from "stores/actions";
import deepClone from "utils/deepClone";
import { HOST_INIT } from "constants/headendInit";
import useActivityLogger from "hooks/useActivity";
import capitalize from "utils/capitalize";

const HostForm = ({
  host,
  showModal,
  closeModal,
  isEdit,
  buttonName,
  updateHostDetails,
  onSave,
  headend,
}) => {
  const dispatch = useDispatch();
  const logActivity = useActivityLogger();
  const [formData, setFormData] = useState(HOST_INIT);

  const { categories } = useSelector((state) => state.categories);
  const { headend: headendDetails } = useSelector((state) => state.headend);
  const { regions } = useSelector((state) => state.dashboard);

  const guiUserTypes = ["Admin", "Monitoring"]; // Example GUI user types

  useEffect(() => {
    if (host) setFormData(host);
  }, [host]);

  useEffect(() => {
    if (headend?._id) dispatch(fetchHeadendById(headend?._id));
  }, [headend, dispatch]);

  const logHostActivity = (activity, name) => {
    logActivity({
      page: "host-form",
      activity,
      name,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleNestedChange = (index, fieldName, value, type) => {
    const ipType = type === "wan" ? "wanIps" : "ips";
    const updatedIPs = deepClone(formData[ipType]);
    updatedIPs[index][fieldName] = value;
    setFormData((prevData) => ({ ...prevData, [ipType]: updatedIPs }));
  };

  const handleSSHChange = (sshIndex, e) => {
    const { name, value } = e.target;
    const updatedSSH = deepClone(formData.sshCredentials);
    updatedSSH[sshIndex][name] = value;
    setFormData((prevData) => ({ ...prevData, sshCredentials: updatedSSH }));
  };

  const handleGuiChange = (guiIndex, e) => {
    const { name, value } = e.target;
    const updatedGui = deepClone(formData.guiCredentials);
    updatedGui[guiIndex][name] = value;
    setFormData((prevData) => ({ ...prevData, guiCredentials: updatedGui }));
  };

  const handleLicenseChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      license: { ...prevData.license, [name]: value },
    }));
  };

  const handleAddItem = (key, defaultItem) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: [...(prevData[key] || []), defaultItem],
    }));
  };

  const handleDeleteItem = (key, index) => {
    setFormData((prevData) => {
      const updatedList = deepClone(prevData[key]);
      updatedList.splice(index, 1);
      return { ...prevData, [key]: updatedList };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let savedHost;

    if (headend) {
      savedHost = await dispatch(
        saveHost({ ...formData, headendName: headendDetails?.name })
      );
      logHostActivity("save-new-host", formData.name);

      const updateData = {
        _id: headendDetails?._id,
        ...(savedHost?.category?.name === "vms" && {
          vms: [...headendDetails.vms, { host: savedHost }],
        }),
      };

      await dispatch(saveHeadend(updateData));
      logHostActivity("update-headend", headendDetails?.name);
    } else if (host._id) {
      logHostActivity("update-host", formData.name);
      savedHost = await dispatch(updateHost(formData));
      if (updateHostDetails) updateHostDetails(savedHost);
    } else {
      logHostActivity("save-new-host", formData.name);
      savedHost = await dispatch(saveHost(formData));
      onSave(savedHost);
    }

    setFormData(HOST_INIT);
    closeModal();
  };

  const formatFieldName = (field) => {
    return capitalize(field.replace(/([a-z])([A-Z])/g, "$1 $2"));
  };

  const renderIPs = (ipType, type = "ips") =>
    formData[ipType]?.map((ip, index) => (
      <Row key={index} className="mb-2">
        <Col>
          <Form.Group controlId={`${ipType}[${index}].type`}>
            <Form.Label>{ip?.type || "IP"} Type</Form.Label>
            <Form.Control type="text" value={ip?.type} readOnly />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={`${ipType}[${index}].private_ip`}>
            <Form.Label>Private IP</Form.Label>
            <Form.Control
              type="text"
              value={ip?.private_ip}
              onChange={(e) =>
                handleNestedChange(index, "private_ip", e.target.value, type)
              }
              required
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={`${ipType}[${index}].public_ip`}>
            <Form.Label>Public IP</Form.Label>
            <Form.Control
              type="text"
              value={ip?.public_ip}
              onChange={(e) =>
                handleNestedChange(index, "public_ip", e.target.value, type)
              }
              required
            />
          </Form.Group>
        </Col>
      </Row>
    ));

  const renderLicenseFields = () => (
    <Row className="mb-2">
      {["solutionTier", "bandwidth", "startDate", "endDate"].map((field) => (
        <Col key={field}>
          <Form.Group controlId={`license.${field}`}>
            <Form.Label>{formatFieldName(field)}</Form.Label>
            <Form.Control
              type="text"
              value={formData?.license?.[field] || ""}
              name={field}
              onChange={handleLicenseChange}
            />
          </Form.Group>
        </Col>
      ))}
    </Row>
  );

  const renderSSHFields = () =>
    formData.sshCredentials?.map((ssh, index) => (
      <Row key={`ssh-${index}`} className="mb-2">
        {["username", "password", "keyFile", "passPhrase"].map((field) => (
          <Col key={field}>
            <Form.Control
              type={field === "password" ? "password" : "text"}
              name={field}
              value={ssh[field] || ""}
              onChange={(e) => handleSSHChange(index, e)}
              placeholder={`Enter ${field}`}
            />
          </Col>
        ))}
        {formData.sshCredentials.length > 1 && (
          <Button
            onClick={() => handleDeleteItem("sshCredentials", index)}
            className="delete-btn"
          >
            X
          </Button>
        )}
      </Row>
    ));

  const renderGuiFields = () =>
    formData.guiCredentials?.map((gui, index) => (
      <Row key={`gui-${index}`} className="mb-2">
        <Col>
          <Form.Control
            as="select"
            name="user_type"
            value={gui.user_type}
            onChange={(e) => handleGuiChange(index, e)}
          >
            {guiUserTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Form.Control>
        </Col>
        <Col>
          <Form.Control
            type="text"
            name="username"
            value={gui.username}
            onChange={(e) => handleGuiChange(index, e)}
            placeholder="Enter Username"
          />
        </Col>
        <Col>
          <Form.Control
            type="password"
            name="password"
            value={gui.password}
            onChange={(e) => handleGuiChange(index, e)}
            placeholder="Enter Password"
          />
        </Col>
        <Col>
          <Form.Control
            type="text"
            name="keyfile"
            value={gui.keyfile}
            onChange={(e) => handleGuiChange(index, e)}
            placeholder="Enter Keyfile path"
          />
        </Col>
        <Col>
          <Form.Control
            type="text"
            name="jumpServer"
            value={gui.jumpServer}
            onChange={(e) => handleGuiChange(index, e)}
            placeholder="Enter Jump Server IP"
          />
        </Col>
        <Col>
          <Form.Check
            type="checkbox"
            name="isTacacs"
            checked={gui.isTacacs}
            onChange={(e) =>
              handleGuiChange(index, {
                target: {
                  name: "isTacacs",
                  value: e.target.checked,
                },
              })
            }
          />
        </Col>
        {formData.guiCredentials.length > 1 && (
          <Button
            onClick={() => handleDeleteItem("guiCredentials", index)}
            className="delete-btn"
          >
            X
          </Button>
        )}
      </Row>
    ));

  return (
    <Modal show={showModal} onHide={closeModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{`Host Information ${
          headend ? `for ${headend?.name}` : ""
        }`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-2">
            {["name", "backupName", "ipmi_ip"].map((field) => (
              <Col key={field}>
                <Form.Group controlId={field}>
                  <Form.Label>{formatFieldName(field)}</Form.Label>
                  <Form.Control
                    type="text"
                    name={field}
                    value={formData?.[field] || ""}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
            ))}
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Group controlId="fqdn">
                <Form.Label>FQDN</Form.Label>
                <Form.Control
                  type={"text"}
                  name="fqdn"
                  value={formData?.fqdn}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="category">
                <Form.Label>Type:</Form.Label>
                <Form.Control
                  as="select"
                  name="category"
                  className="select-box"
                  value={formData?.category?._id || formData?.category}
                  onChange={handleInputChange}
                >
                  <option value="">Select Type</option>
                  {categories?.host?.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category?.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-2">
            {["cpu", "cpuCount", "memory", "disk"].map((field) => (
              <Col key={field}>
                <Form.Group controlId={field}>
                  <Form.Label>{formatFieldName(field)}</Form.Label>
                  <Form.Control
                    type={"text"}
                    name={field}
                    value={formData?.hardware?.[field]}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
            ))}
          </Row>

          <Row className="mb-2">
            {["softwareVersion", "osspackVersion", "spackVersion"].map(
              (field) => (
                <Col key={field}>
                  <Form.Group controlId={field}>
                    <Form.Label>{formatFieldName(field)}</Form.Label>
                    <Form.Control
                      type="text"
                      name={field}
                      value={formData?.[field] || ""}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Col>
              )
            )}
          </Row>

          <Row className="mb-2">
            {["serialNo", "model"].map((field) => (
              <Col key={field}>
                <Form.Group controlId={field}>
                  <Form.Label>{formatFieldName(field)}</Form.Label>
                  <Form.Control
                    type="text"
                    name={field}
                    value={formData?.hardware?.[field] || ""}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
            ))}
          </Row>

          <Row className="mb-2">
            <Col>
              <Form.Group controlId="provider">
                <Form.Label>Provider</Form.Label>
                <Form.Control
                  type="text"
                  name="provider"
                  value={formData?.provider || ""}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="region">
                <Form.Label>Region:</Form.Label>
                <Form.Control
                  as="select"
                  name="region"
                  className="select-box"
                  value={formData?.region || ""}
                  onChange={handleInputChange}
                >
                  <option value="">Select Region</option>
                  {regions?.map((region) => (
                    <option key={region._id} value={region._id}>
                      {region?.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="location">
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type="text"
                  name="location"
                  value={formData?.location || ""}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <hr />

          <Card className="mt-4 mb-4">
            <Card.Header>
              <Row>
                <Col>
                  <h6>WAN IPs</h6>
                </Col>
                <Col className="col-auto">
                  <Button
                    className="px-3 py-1"
                    size="sm"
                    onClick={() =>
                      handleAddItem("wanIps", {
                        type: "WAN",
                        private_ip: "",
                        public_ip: "",
                      })
                    }
                  >
                    Add WAN IPs
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>{renderIPs("wanIps", "wan")}</Card.Body>
          </Card>

          <Card className="mt-4 mb-4">
            <Card.Header>
              <Row>
                <Col>
                  <h6>SSH Credentials</h6>
                </Col>
                <Col className="col-auto">
                  <Button
                    className="px-3 py-1"
                    size="sm"
                    onClick={() =>
                      handleAddItem("sshCredentials", {
                        username: "",
                        password: "",
                        keyFile: "",
                        passPhrase: "",
                      })
                    }
                  >
                    Add SSH
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>{renderSSHFields()}</Card.Body>
          </Card>

          <Card className="mt-4 mb-4">
            <Card.Header>
              <Row>
                <Col>
                  <h6>GUI Credentials</h6>
                </Col>
                <Col className="col-auto">
                  <Button
                    className="px-3 py-1"
                    size="sm"
                    onClick={() =>
                      handleAddItem("guiCredentials", {
                        user_type: "",
                        username: "",
                        password: "",
                        keyfile: "",
                        jumpServer: "",
                        isTacacs: false,
                      })
                    }
                  >
                    Add GUI
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>{renderGuiFields()}</Card.Body>
          </Card>

          <Card className="mt-4 mb-4">
            <Card.Header>
              <Row>
                <Col>
                  <h6>IPs</h6>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>{renderIPs("ips")}</Card.Body>
          </Card>

          <Card className="mt-4 mb-4">
            <Card.Header>
              <Row>
                <Col>
                  <h6>License</h6>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>{renderLicenseFields()}</Card.Body>
          </Card>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
        <Button variant="primary" type="submit" onClick={handleSubmit}>
          {isEdit ? "Update Host" : "Add Host"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HostForm;
