// reducers/hostSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchBackups,
  fetchBackupSummary,
  fetchSingleBackup,
} from "../actions";

const backupSlice = createSlice({
  name: "backup",
  initialState: {
    backups: [],
    backup: {},
    summary: {},
    loading: {
      fetchBackups: false,
    },
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBackups.pending, (state) => {
        state.loading["fetchBackups"] = true;
        state.error = null;
      })
      .addCase(fetchBackups.fulfilled, (state, action) => {
        state.loading["fetchBackups"] = false;
        state.backups = action.payload;
      })
      .addCase(fetchBackups.rejected, (state, action) => {
        state.loading["fetchBackups"] = false;
        state.error = action.payload;
      })
      .addCase(fetchSingleBackup.pending, (state) => {
        state.loading["fetchSingleBackup"] = true;
        state.error = null;
      })
      .addCase(fetchSingleBackup.fulfilled, (state, action) => {
        state.loading["fetchSingleBackup"] = false;
        state.backup = action.payload;
      })
      .addCase(fetchSingleBackup.rejected, (state, action) => {
        state.loading["fetchSingleBackup"] = false;
        state.error = action.payload;
      })
      .addCase(fetchBackupSummary.pending, (state) => {
        state.loading["fetchBackupSummary"] = true;
        state.error = null;
      })
      .addCase(fetchBackupSummary.fulfilled, (state, action) => {
        state.loading["fetchBackupSummary"] = false;
        state.summary = action.payload;
      })
      .addCase(fetchBackupSummary.rejected, (state, action) => {
        state.loading["fetchBackupSummary"] = false;
        state.error = action.payload;
      });
  },
});

export const {} = backupSlice.actions;
export default backupSlice.reducer;
