import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import SingleView from "./SingleHostView";
import HostForm from "./AddForm";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import { usePermissions } from "hooks/usePermission";
import { Tab, Tabs } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "components/Loader";
import { fetchHosts } from "stores/actions";

const HostList = ({ sendLog }) => {
  const [showModal, setShowModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedHost, setSelectedHost] = useState(null);
  const [hostToEdit, setEditToHost] = useState(null);
  const { hosts, loading } = useSelector((state) => state.host);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hash = location.hash.substring(1) || "gateway";
  const { categories } = useSelector((state) => state.categories);
  const hasViewPermission = usePermissions(["HOSTS_VIEW"]);
  const hasEditPermission = usePermissions(["HOSTS_EDIT"]);

  const fetchHost = (category) => {
    dispatch(fetchHosts(category));
  };

  useEffect(() => {
    if (!location.hash) {
      fetchHost("director");
      navigate("#director", { replace: true });
    } else {
      fetchHost(location.hash.replace("#", ""));
    }
  }, [location.hash, navigate]);

  const handleSelect = useCallback(
    (key) => {
      navigate(`#${key}`);
    },
    [navigate]
  );

  const getHostData = useCallback((hosts) => {
    return hosts?.map((host) => ({
      ...host,
      id: host._id,
      name: host?.name,
      headend: `${host?.headendCode ?? ""} ${host?.headendName ?? ""}`,
      category: host?.subtype || host?.category?.name,
      spackVersion: host?.spackVersion,
      owner: host?.ownerOrgName,
      osspackVersion: host?.osspackVersion,
    }));
  }, []);

  const selectHost = (item) => {
    let selectedCategory = hosts.find(
      (host) => item?.category?.toLowerCase() === host.category
    );

    let selected = selectedCategory?.hosts?.find(
      (host) => host._id === item.id
    );

    if (!selected) {
      selectedCategory = hosts.find((host) => "Gateway" === host.category);
      selected = selectedCategory?.hosts?.find((host) => host._id === item.id);
    }
    return selected;
  };

  const onView = useCallback(
    (item) => {
      // const selected = selectHost(item);
      sendLog("host", "click-host-view", item?.name);
      setSelectedHost(item);
      setShowViewModal(true);
    },
    [hosts, sendLog]
  );

  const onEdit = useCallback(
    (item) => {
      // const selected = selectHost(item);
      sendLog("host", "click-host-edit", item?.name);

      setEditToHost(hosts.find((data) => item._id === data._id));
      setShowModal(true);
    },
    [hosts, sendLog]
  );

  const TabContent = useMemo(
    () =>
      ({ hosts }) => {
        const hostData = getHostData(hosts);
        return (
          <PaginatedTable
            data={hostData || []}
            columns={COLUMNS["host"]}
            perPage={20}
            onView={hasViewPermission ? onView : null}
            onEdit={hasEditPermission ? onEdit : null}
          />
        );
      },
    [getHostData, onView, onEdit, hasViewPermission, hasEditPermission]
  );

  return (
    <div>
      {hosts && (
        <Tabs className={`hosts-tabs`} activeKey={hash} onSelect={handleSelect}>
          {categories?.host?.map((category) => {
            return (
              <Tab
                key={category?.name?.toLowerCase()}
                eventKey={category?.name?.toLowerCase()}
                title={category?.name}
                className="mt-3"
              >
                {loading["fetchHosts"] ? (
                  <Loader />
                ) : (
                  <TabContent hosts={hosts} />
                )}
              </Tab>
            );
          })}
        </Tabs>
      )}

      <HostForm
        host={hostToEdit}
        removeHost={setEditToHost}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        isEdit={!!hostToEdit?._id}
      />
      <SingleView
        host={selectedHost}
        removeHost={setSelectedHost}
        showModal={showViewModal}
        closeModal={() => setShowViewModal(false)}
      />
    </div>
  );
};

export default HostList;
