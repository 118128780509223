import React, { useMemo, useState } from "react";

import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import SingleView from "pages/Host/SingleHostView";

const VmsView = ({ vms }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedVms, setSelectedVms] = useState(null);

  const vmsData = useMemo(() => {
    return vms?.map((vm) => {
      return {
        ...vm?.host,
        id: vm?.host?._id,
        name: vm?.host?.name,
        type: vm?.host?.category?.name,
      };
    });
  }, [vms]);

  const onView = (item) => {
    setSelectedVms(item);
    setShowModal(true);
  };

  return (
    <>
      <div className="my-3">
        <h5>
          <b>VMS</b>
        </h5>
        <small className="sub-info">List of all VMS nodes</small>
      </div>
      <PaginatedTable
        data={vmsData}
        columns={COLUMNS["vms"]}
        itemsPerPage={10}
        onView={onView}
      />

      {showModal && (
        <SingleView
          host={selectedVms}
          showModal={showModal}
          closeModal={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default VmsView;
