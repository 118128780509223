import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { Card } from "react-bootstrap";
import COUNTRY_COORDINATES from "constants/countryCoordinates";
import clusterImg from "assets/images/cluster.png";

const MapUpdater = ({ center, zoom }) => {
  const map = useMap();

  useEffect(() => {
    map.setView(center, zoom);
  }, [center, zoom, map]);

  return null;
};

// Icon for markers
const clusterIcon = new L.Icon({
  iconUrl: clusterImg, // Path to your cluster icon
  iconSize: [40, 40],
});

const mapStyle = {
  height: "450px",
  width: "100%",
};

const initialCenter = {
  lat: 20.0,
  lng: 0.0,
};

const GatewayMap = ({ groupedDeviceData, selectedCountry }) => {
  const [mapCenter, setMapCenter] = useState([
    initialCenter.lat,
    initialCenter.lng,
  ]);
  const [zoomLevel, setZoomLevel] = useState(2);

  // Update map center and zoom when a country is selected
  const handleCountryChange = (country) => {
    const location = COUNTRY_COORDINATES[country];
    if (location) {
      setMapCenter([location.lat, location.lng]);
      setZoomLevel(location.zoom);
    } else {
      setMapCenter([initialCenter.lat, initialCenter.lng]);
      setZoomLevel(2);
    }
  };

  useEffect(() => {
    handleCountryChange(selectedCountry);
  }, [selectedCountry]);

  return (
    <MapContainer center={mapCenter} zoom={zoomLevel} style={mapStyle}>
      <MapUpdater center={mapCenter} zoom={zoomLevel} />

      {/* TileLayer for base map */}
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        attribution=""
      />

      {/* Marker Cluster Group */}
      <MarkerClusterGroup
        iconCreateFunction={(cluster) => {
          const childCount = cluster.getChildCount();
          return new L.DivIcon({
            html: `<div class="">${childCount}</div>`,
            className: "custom-marker-cluster",
            iconSize: [40, 40],
          });
        }}
      >
        {groupedDeviceData.map((group, index) => (
          <Marker
            key={index}
            position={[group.lat, group.lng]}
            icon={clusterIcon}
          >
            <Popup>
              <h3>Gateways</h3>
              {group.devices.map((device, idx) => (
                <Card key={idx} className="mb-3">
                  <Card.Body>
                    <strong>Gateway Name:</strong> {device.gatewayName}
                    <br />
                    <strong>Country:</strong> {device.country}
                    <br />
                    <strong>Location:</strong> {device.location}
                    <br />
                    <strong>Serial Number:</strong> {device.serialNumber}
                    <br />
                    <strong>Software Version:</strong> {device.softwareVersion}
                  </Card.Body>
                </Card>
              ))}
            </Popup>
          </Marker>
        ))}
      </MarkerClusterGroup>
    </MapContainer>
  );
};

export default GatewayMap;
