import React from "react";
import { Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Loader from "components/Loader";
import BackupSummary from "../components/BackupSummary";
import BackupListView from "../components/List";

const BackupHistory = () => {
  const { backups, loading } = useSelector((state) => state.backup);
  return (
    <>
      {loading["fetchBackups"] ? (
        <Loader />
      ) : (
        <>
          <BackupSummary />
          <div className="mt-4">
            <BackupListView backups={backups} />
          </div>
        </>
      )}
    </>
  );
};

export default BackupHistory;
