import React, { useEffect, useState, useCallback } from "react";
import { Card, Col, Row, Tabs } from "react-bootstrap";
import { useBreadcrumbs } from "contexts";
import { useDispatch, useSelector } from "react-redux";
import useActivityLogger from "hooks/useActivity";
import GatewayList from "./List";
import GatewaySummary from "pages/Dashboard/Components/GatewaysSummary";
import { Tab } from "bootstrap";

const Gateways = () => {
  const { updateBreadcrumbs } = useBreadcrumbs();
  const { hosts } = useSelector((state) => state.host);
  const { headends } = useSelector((state) => state.headend);
  const [selectedHeadend, setSelectedHeadend] = useState();
  const [isInitialLoad, setIsInitialLoad] = useState(false);
  const logActivity = useActivityLogger();
  const dispatch = useDispatch();

  // Memoize the sendLog function to prevent it from being recreated on every render
  const sendLog = useCallback(
    (page, activity, name) => {
      logActivity({
        page,
        activity,
        name,
      });
    },
    [logActivity]
  );

  useEffect(() => {
    if (!isInitialLoad && hosts.length === 0) {
      // dispatch(fetchHosts());
      setIsInitialLoad(true);
    }
  }, [dispatch, isInitialLoad, hosts]);

  // Disable exhaustive-deps to ensure this effect only runs on mount
  useEffect(() => {
    sendLog("gateway", "page-load", "Gateway");
    updateBreadcrumbs([{ text: "Home", url: "/dashboard" }]);
  }, []);

  return (
    <>
      <div className="middle-section">
        <Row>
          <Col sm={12}>
            <GatewaySummary />
          </Col>

          <Col sm={12}>
            <Row className="my-2">
              <Col>
                <div>
                  <h5>
                    <b>Gateways</b>
                  </h5>
                  <small className="sub-info">List of all Gateways</small>
                </div>
              </Col>

              <Col className="col-auto"></Col>
            </Row>

            <Card className="my-2">
              <Card.Body>
                <Tabs
                  defaultActiveKey="summary"
                  id="gateway-tabs"
                  className="mb-3"
                >
                  <Tab eventKey="summary" title={`Summary`}>
                    <GatewayList sendLog={sendLog} />
                  </Tab>
                  <Tab eventKey="monitoring" title={`Monitoring`}></Tab>
                  <Tab eventKey="capacity" title={`Capacity Planning`}></Tab>
                  <Tab eventKey="certificate" title={`Certificates`}></Tab>
                </Tabs>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default React.memo(Gateways);
