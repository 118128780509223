// reducers/securitySlice.js
import { createSlice } from "@reduxjs/toolkit";
import { fetchNmapReport } from "../actions";

const securitySlice = createSlice({
  name: "security",
  initialState: {
    nmap: [],
    loading: {
      nmap: false,
    },
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNmapReport.pending, (state) => {
        state.loading["nmap"] = true;
        state.error = null;
        state.nmap = [];
      })
      .addCase(fetchNmapReport.fulfilled, (state, action) => {
        state.loading["nmap"] = false;
        state.nmap = action.payload;
      })
      .addCase(fetchNmapReport.rejected, (state, action) => {
        state.loading["nmap"] = false;
        state.error = action.payload;
      });
  },
});

export default securitySlice.reducer;
