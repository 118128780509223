import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "services/api";

const fetchDCs = createAsyncThunk(
  "dcs/fetchDcs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/dc`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchDC = createAsyncThunk(
  "dcs/fetchDc",
  async (id, { rejectWithValue }) => {
    try {
      const response = await API.get(`/dc/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export { fetchDCs, fetchDC };
