// HeadendBasicInfoForm.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import { MultiSelectSearch } from "components";

const HeadendBasicInfoForm = ({ formData, updateFormData, isEdit }) => {
  const [selectedOption, setSelectedOption] = useState();
  const { categories } = useSelector((state) => state.categories);

  useEffect(() => {
    setSelectedOption({
      category: selectedOption?.category?.label
        ? selectedOption?.category
        : formData?.category,
      infra: selectedOption?.infra?.label
        ? selectedOption.infra
        : formData?.infra,
      envType: selectedOption?.envType?.label
        ? selectedOption.envType
        : formData?.envType,
    });
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    updateFormData({ ...formData, [name]: value });
  };

  const handleSelect = (e) => {
    const { value, field } = e.target;
    const updatedSelectedOptions = {
      ...selectedOption,
      [field]: e.target,
    };
    setSelectedOption(updatedSelectedOptions);
    updateFormData({ ...formData, [field]: value });
  };

  return (
    <div>
      <Form>
        <Row>
          <Col>
            <Form.Group controlId="hename">
              <Form.Label>MS Code:</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData?.code}
                disabled
                // onChange={handleChange}
                placeholder="MS-001"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="hename">
              <Form.Label>Headend ID:</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData?.name}
                disabled
                // onChange={handleChange}
                placeholder="Ex: MS-011..."
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="category">
              <Form.Label>Category:</Form.Label>
              <MultiSelectSearch
                options={categories?.headend}
                isMulti={false}
                selectedOption={selectedOption?.category}
                onChange={(data) =>
                  handleSelect({
                    target: {
                      field: "category",
                      ...data,
                    },
                  })
                }
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="infra">
              <Form.Label>Infra Type :</Form.Label>

              <MultiSelectSearch
                options={categories?.infra}
                isMulti={false}
                selectedOption={selectedOption?.infra}
                onChange={(data) =>
                  handleSelect({
                    target: {
                      field: "infra",
                      ...data,
                    },
                  })
                }
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="envType">
              <Form.Label>Environment Type:</Form.Label>

              <MultiSelectSearch
                options={categories?.environment}
                isMulti={false}
                selectedOption={selectedOption?.envType}
                onChange={(data) =>
                  handleSelect({
                    target: {
                      field: "envType",
                      ...data,
                    },
                  })
                }
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default HeadendBasicInfoForm;
