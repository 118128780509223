// Modal.js
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const CustomModal = ({
  showModal = false,
  closeModal,
  children,
  onSubmit,
  title,
}) => {
  const handleClose = () => {
    closeModal();
  };
  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" type="submit" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
