// reducers/hostSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { fetchHosts, fetchHostSummary } from "../actions";

const hostSlice = createSlice({
  name: "hosts",
  initialState: {
    hosts: [],
    summary: {},
    loading: {
      fetchHosts: true,
      fetchSummary: false,
    },
    error: null,
  },
  reducers: {
    setHosts: (state, action) => {
      state.hosts = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHosts.pending, (state) => {
        state.loading["fetchHosts"] = true;
        state.error = null;
      })
      .addCase(fetchHosts.fulfilled, (state, action) => {
        state.loading["fetchHosts"] = false;
        state.hosts = action.payload;
      })
      .addCase(fetchHosts.rejected, (state, action) => {
        state.loading["fetchHosts"] = false;
        state.error = action.payload;
      })
      .addCase(fetchHostSummary.pending, (state) => {
        state.loading["fetchSummary"] = true;
        state.error = null;
      })
      .addCase(fetchHostSummary.fulfilled, (state, action) => {
        state.loading["fetchSummary"] = false;
        state.summary = action.payload;
      })
      .addCase(fetchHostSummary.rejected, (state, action) => {
        state.loading["fetchSummary"] = false;
        state.error = action.payload;
      });
  },
});

export const { setHosts, setLoading, setError } = hostSlice.actions;
export default hostSlice.reducer;
