import { PaginatedTable } from "components";
import COLUMNS from "constants/columns";
import React, { useMemo } from "react";
import { Card } from "react-bootstrap";

const UniqueUsers = ({ uniqueUsers, loading }) => {
  const column = [{ dataField: "email", text: "User" }];

  return (
    <div>
      <div className="mt-2 mb-3">
        <h5>
          <b>Unique Users</b>
        </h5>
      </div>
      <PaginatedTable data={uniqueUsers} columns={column} perPage={25} />
    </div>
  );
};

export default UniqueUsers;
