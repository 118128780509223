const createOrgTree = (orgs) => {
  // Normalize function to handle case insensitivity
  const normalizeName = (name) => name?.toLowerCase();

  // Step 1: Group tenants by parentOrg
  const orgsByParent = orgs.reduce((acc, org) => {
    const parent = normalizeName(org.parentOrg || "Versa"); // Default to 'Versa' for top-level nodes if parentOrg is missing
    if (!acc[parent]) {
      acc[parent] = [];
    }
    acc[parent].push(org);
    return acc;
  }, {});

  // Step 2: Ensure all referenced parent tenants are included
  orgs.forEach((org) => {
    const parent = normalizeName(org.parentOrg);
    if (parent && !orgsByParent[parent]) {
      orgsByParent[parent] = []; // Create an empty array for the missing parentOrg
    }
  });

  // Step 3: Build the tree with tenant counts, avoiding cycles
  const buildTree = (parent, visited = new Set()) => {
    if (visited.has(parent)) {
      return []; // Prevent infinite recursion by checking visited nodes
    }
    visited.add(parent);

    const children = (orgsByParent[parent] || []).map((org) => ({
      ...org,
      tenants: buildTree(normalizeName(org.name), new Set(visited)),
    }));

    return children.map((child) => ({
      ...child,
      tenantCount: child.tenants.length,
    }));
  };

  // Step 4: Extract the top-level "Versa" node from the provided tenants
  const topLevelVersa = orgs.find((org) => normalizeName(org.name) === "versa");

  // Step 5: Build the tree starting from the top-level "Versa" node
  if (topLevelVersa) {
    const newOrgs = [
      {
        ...topLevelVersa,
        tenants: buildTree("versa"),
        tenantCount: buildTree("versa").length,
      },
    ];

    return newOrgs; // Return the built tree
  } else {
    return []; // Return an empty array if "Versa" is not found
  }
};

export default createOrgTree;
