import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Modal, Card } from "react-bootstrap";
import { ISP_INIT, PROVIDER_INIT } from "constants/headendInit";
import { useDispatch, useSelector } from "react-redux";
import { CONTACT_OPTIONS } from "../../constants/selectOptions";
import { MultiSelectSearch } from "components";
import { saveIsp, updateIsp } from "stores/actions";
import deepClone from "utils/deepClone";

const IspForm = ({ isp, onAdd, showModal, closeModal }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(ISP_INIT);
  const { hosts } = useSelector((state) => state.host);

  useEffect(() => {
    if (isp) setFormData(isp);
  }, [isp, showModal]);

  const handleInputChange = (e, providerIndex, contactIndex, valueIndex) => {
    const { name, value } = e.target;
    setFormData((prevState) => {
      const updatedProviders = deepClone(prevState.connectivity_providers);

      if (providerIndex !== undefined) {
        if (contactIndex !== undefined) {
          const updatedContacts = deepClone(
            updatedProviders[providerIndex].contacts
          );

          if (valueIndex !== undefined) {
            const updatedValues = deepClone(
              updatedContacts[contactIndex].values
            );
            updatedValues[valueIndex] = {
              ...updatedValues[valueIndex],
              [name]: value,
            };
            updatedContacts[contactIndex] = {
              ...updatedContacts[contactIndex],
              values: updatedValues,
            };
          } else {
            updatedContacts[contactIndex] = {
              ...updatedContacts[contactIndex],
              [name]: value,
            };
          }

          updatedProviders[providerIndex] = {
            ...updatedProviders[providerIndex],
            contacts: updatedContacts,
          };
        } else {
          updatedProviders[providerIndex] = {
            ...updatedProviders[providerIndex],
            [name]: value,
          };
        }
      } else {
        return { ...prevState, [name]: value };
      }

      return { ...prevState, connectivity_providers: updatedProviders };
    });
  };

  const handleAddProvider = () => {
    setFormData((prevState) => ({
      ...prevState,
      connectivity_providers: [
        ...prevState.connectivity_providers,
        PROVIDER_INIT,
      ],
    }));
  };

  const handleAddContact = (providerIndex) => {
    setFormData((prevState) => {
      const updatedProviders = deepClone(prevState.connectivity_providers);
      updatedProviders[providerIndex].contacts.push({
        type: "",
        location: "",
        values: [{ link: "", username: "", password: "" }],
      });
      return { ...prevState, connectivity_providers: updatedProviders };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isp?._id) {
      await dispatch(updateIsp(formData));
    } else {
      await dispatch(saveIsp(formData));
    }
    setFormData(ISP_INIT);
    closeModal();
  };

  const deleteProvider = (index) => {
    setFormData((prevState) => {
      const updatedProviders = [...prevState.connectivity_providers];
      updatedProviders.splice(index, 1);
      return { ...prevState, connectivity_providers: updatedProviders };
    });
  };

  const deleteContact = (providerIndex, contactIndex) => {
    setFormData((prevState) => {
      const updatedProviders = [...prevState.connectivity_providers];
      const updatedContacts = [...updatedProviders[providerIndex].contacts];
      updatedContacts.splice(contactIndex, 1);
      updatedProviders[providerIndex].contacts = updatedContacts;
      return { ...prevState, connectivity_providers: updatedProviders };
    });
  };

  return (
    <Modal show={showModal} onHide={closeModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add New ISP</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Group controlId="site_name">
                <Form.Label>Site Name</Form.Label>
                <Form.Control
                  type="text"
                  name="site_name"
                  value={formData?.site_name || ""}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="data_center_name">
                <Form.Label>Data Center Name</Form.Label>
                <Form.Control
                  type="text"
                  name="data_center_name"
                  value={formData?.data_center_name || ""}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="arista_sw_ip">
                <Form.Label>Arista Switch IP</Form.Label>
                <Form.Control
                  type="text"
                  name="arista_sw_ip"
                  value={formData?.arista_sw_ip || ""}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="arista_sw_name">
                <Form.Label>Arista Switch Name</Form.Label>
                <Form.Control
                  type="text"
                  name="arista_sw_name"
                  value={formData?.arista_sw_name || ""}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          {formData?.connectivity_providers.map((provider, providerIndex) => (
            <Card key={providerIndex} className="mt-3">
              <Card.Header>
                Connectivity Provider {providerIndex + 1}
                {formData.connectivity_providers.length > 1 && (
                  <div
                    className="close-btn"
                    onClick={() => deleteProvider(providerIndex)}
                  >
                    X
                  </div>
                )}
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Group controlId={`isp_${providerIndex}`}>
                      <Form.Label>ISP</Form.Label>
                      <Form.Control
                        type="text"
                        name="isp"
                        value={provider.isp}
                        onChange={(e) => handleInputChange(e, providerIndex)}
                        // required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId={`bandwidth_${providerIndex}`}>
                      <Form.Label>Bandwidth</Form.Label>
                      <Form.Control
                        type="text"
                        name="bandwidth"
                        value={provider.bandwidth}
                        onChange={(e) => handleInputChange(e, providerIndex)}
                        // required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId={`local_as_${providerIndex}`}>
                      <Form.Label>Local AS</Form.Label>
                      <Form.Control
                        type="number"
                        name="local_as"
                        value={provider.local_as}
                        onChange={(e) => handleInputChange(e, providerIndex)}
                        // required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId={`remote_as_${providerIndex}`}>
                      <Form.Label>Remote AS</Form.Label>
                      <Form.Control
                        type="number"
                        name="remote_as"
                        value={provider.remote_as}
                        onChange={(e) => handleInputChange(e, providerIndex)}
                        // required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId={`versa_side_ip_${providerIndex}`}>
                      <Form.Label>Versa Side IP</Form.Label>
                      <Form.Control
                        type="text"
                        name="versa_side_ip"
                        value={provider.versa_side_ip}
                        onChange={(e) => handleInputChange(e, providerIndex)}
                        // required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId={`isp_side_ip_${providerIndex}`}>
                      <Form.Label>ISP Side IP</Form.Label>
                      <Form.Control
                        type="text"
                        name="isp_side_ip"
                        value={provider.isp_side_ip}
                        onChange={(e) => handleInputChange(e, providerIndex)}
                        // required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId={`circuit_id_${providerIndex}`}>
                      <Form.Label>Circuit ID</Form.Label>
                      <Form.Control
                        type="text"
                        name="circuit_id"
                        value={provider.circuit_id}
                        onChange={(e) => handleInputChange(e, providerIndex)}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Card className="mt-3">
                  <Card.Header>
                    <Row>
                      <Col>Contacts</Col>
                      <Col className="col-auto">
                        <Button
                          size="sm"
                          className="px-3 py-1"
                          onClick={() => handleAddContact(providerIndex)}
                        >
                          + Contact
                        </Button>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    {provider.contacts.map((contact, contactIndex) => (
                      <div key={contactIndex}>
                        <Row>
                          <Col>
                            <Form.Group
                              controlId={`contact_type_${providerIndex}_${contactIndex}`}
                            >
                              <Form.Label>Contact Type</Form.Label>
                              <Form.Control
                                as="select"
                                name="type"
                                value={contact.type}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    providerIndex,
                                    contactIndex
                                  )
                                }
                              >
                                {CONTACT_OPTIONS.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group
                              controlId={`contact_location_${providerIndex}_${contactIndex}`}
                            >
                              <Form.Label>Contact Location</Form.Label>
                              <Form.Control
                                as="select"
                                name="location"
                                value={contact.location}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    providerIndex,
                                    contactIndex
                                  )
                                }
                              >
                                <option value="">Select Location</option>
                                <option value="eu">Europe</option>
                                <option value="us">US</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        {contact.type === "website" ? (
                          <Row className="mt-3">
                            <Col>
                              <Form.Group
                                controlId={`contact_website_${providerIndex}_${contactIndex}`}
                              >
                                <Form.Label>Website Link</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="link"
                                  value={contact.values[0]?.link || ""}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      providerIndex,
                                      contactIndex,
                                      0
                                    )
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group
                                controlId={`contact_username_${providerIndex}_${contactIndex}`}
                              >
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="username"
                                  value={contact.values[0]?.username || ""}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      providerIndex,
                                      contactIndex,
                                      0
                                    )
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group
                                controlId={`contact_password_${providerIndex}_${contactIndex}`}
                              >
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                  type="password"
                                  name="password"
                                  value={contact.values[0]?.password || ""}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      providerIndex,
                                      contactIndex,
                                      0
                                    )
                                  }
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        ) : (
                          <Col>
                            <Form.Group
                              controlId={`contact_value_${providerIndex}_${contactIndex}`}
                            >
                              <Form.Label>{contact.type || "Value"}</Form.Label>
                              <Form.Control
                                type="text"
                                name="value"
                                value={contact.value || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    providerIndex,
                                    contactIndex
                                  )
                                }
                              />
                            </Form.Group>
                          </Col>
                        )}
                        <Col sm={1}>
                          <br />
                          {provider.contacts.length > 1 && (
                            <Button
                              variant="outline-danger"
                              onClick={() =>
                                deleteContact(providerIndex, contactIndex)
                              }
                            >
                              X
                            </Button>
                          )}
                        </Col>
                        <hr />
                      </div>
                    ))}
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          ))}

          <Button size="sm" className="mb-3 mt-3" onClick={handleAddProvider}>
            Add Provider
          </Button>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Save ISP
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default IspForm;
