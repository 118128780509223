// pages/PermissionManagement.js
import React, { useState } from "react";
import {
  createPermission,
  updatePermission,
  deletePermission,
} from "stores/actions";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import COLUMNS from "constants/columns";
import { CustomModal, PaginatedTable } from "components";

const PermissionManagement = () => {
  const dispatch = useDispatch();
  const { permissions } = useSelector((state) => state.auth);
  const [newPermissionName, setNewPermissionName] = useState("");
  const [selectedPermission, setSelectedPermission] = useState(null);
  const [showCreateMOdal, setShowCreateModal] = useState(false);

  const handleCreate = async () => {
    const newPermission = await dispatch(
      createPermission({ name: newPermissionName })
    );
    setShowCreateModal(false);
    setNewPermissionName("");
  };

  const handleSave = async () => {
    if (selectedPermission) {
      const updated = await dispatch(
        updatePermission(selectedPermission._id, {
          name: selectedPermission.name,
        })
      );
    }
  };

  const onView = (item) => {
    const permission = permissions.find((perm) => perm._id === item._id);
    setSelectedPermission(permission);
  };

  const onEdit = (perm) => {
    console.log(perm);
  };

  const onDelete = (perm) => {
    console.log(perm);
  };

  return (
    <Container>
      <Row className="mb-3">
        <Col>
          <h5>
            <b>Permission Management</b>
          </h5>
          <small className="sub-info">Manage all permissions</small>
        </Col>
        <Col className="col-auto">
          <Button
            className="mt-2"
            onClick={() => {
              setShowCreateModal(true);
            }}
          >
            Create Permission
          </Button>
        </Col>
      </Row>

      <CustomModal
        title="Add new permission"
        onSubmit={handleCreate}
        showModal={showCreateMOdal}
        closeModal={() => setShowCreateModal(false)}
      >
        <Form.Group controlId="newPermissionName">
          <Form.Label>New Permission Name</Form.Label>
          <Form.Control
            type="text"
            value={newPermissionName}
            onChange={(e) => setNewPermissionName(e.target.value)}
            placeholder="Enter new permission name"
          />
        </Form.Group>
        {/* <Button className="mt-2" onClick={handleCreate}>
            Create Permission
          </Button> */}
      </CustomModal>

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <PaginatedTable
                columns={COLUMNS["roles"]}
                data={permissions}
                onView={onView}
                // onEdit={onEdit}
                // onDelete={onDelete}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          {selectedPermission && (
            <Card className="mt-4">
              <Card.Body>
                <h2>Manage Permission: {selectedPermission.name}</h2>
                <Form.Group controlId="editPermissionName">
                  <Form.Label>Permission Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedPermission.name}
                    onChange={(e) =>
                      setSelectedPermission({
                        ...selectedPermission,
                        name: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Button className="mt-3" onClick={handleSave}>
                  Save Changes
                </Button>
                <Button
                  className="mt-3 ml-2"
                  variant="danger"
                  onClick={() =>
                    dispatch(deletePermission(selectedPermission._id))
                  }
                >
                  Delete Permission
                </Button>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default PermissionManagement;
