// reducers/headendSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchHeadendById,
  fetchHeadends,
  onBoardExisting,
  saveHeadend,
  fetchHeadendSummary,
} from "../actions/headend";
import { HEADEND_INIT } from "constants/headendInit";

const headendSlice = createSlice({
  name: "headends",
  initialState: {
    headends: [],
    headend: HEADEND_INIT,
    summary: {},
    selectedHeadend: null,
    isOnboarding: false,
    loading: {
      fetchAll: false,
      fetchSingle: false,
      save: false,
      onboard: false,
      summary: false,
    },
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle fetchHeadendById async actions
      .addCase(fetchHeadendById.pending, (state) => {
        state.loading["fetchSingle"] = true;
        state.error = null;
      })
      .addCase(fetchHeadendById.fulfilled, (state, action) => {
        state.loading["fetchSingle"] = false;
        state.selectedHeadend = action.payload;
        state.headend = {
          ...HEADEND_INIT,
          ...action.payload,
        };
      })
      .addCase(fetchHeadendById.rejected, (state, action) => {
        state.loading["fetchSingle"] = false;
        state.error = action.payload;
      })

      // Handle fetchHeadends async actions
      .addCase(fetchHeadends.pending, (state) => {
        state.loading["fetchAll"] = true;
        state.error = null;
      })
      .addCase(fetchHeadends.fulfilled, (state, action) => {
        state.loading["fetchAll"] = false;
        state.headends = action.payload;
      })
      .addCase(fetchHeadends.rejected, (state, action) => {
        state.loading["fetchAll"] = false;
        state.error = action.payload;
      })

      // Handle saveHeadend async actions
      .addCase(saveHeadend.pending, (state) => {
        state.loading["save"] = true;
        state.error = null;
      })
      .addCase(saveHeadend.fulfilled, (state, action) => {
        state.loading["save"] = false;
        state.headends = [action.payload, ...state.headends];
      })
      .addCase(saveHeadend.rejected, (state, action) => {
        state.loading["save"] = false;
        state.error = action.payload;
      })

      // Handle onBoardExisting async actions
      .addCase(onBoardExisting.pending, (state) => {
        state.loading["onboard"] = true;
        state.error = null;
        state.isOnboarding = true;
      })
      .addCase(onBoardExisting.fulfilled, (state, action) => {
        state.loading["onboard"] = false;
        state.headend = action?.payload?.headend;
        state.isOnboarding = false;
      })
      .addCase(onBoardExisting.rejected, (state, action) => {
        state.loading["onboard"] = false;
        state.error = action.payload;
        state.isOnboarding = false;
      })

      // Handle fetchHeadendSummary async actions
      .addCase(fetchHeadendSummary.pending, (state) => {
        state.loading["summary"] = true;
      })
      .addCase(fetchHeadendSummary.fulfilled, (state, action) => {
        state.loading["summary"] = false;
        state.summary = action?.payload;
      })
      .addCase(fetchHeadendSummary.rejected, (state, action) => {
        state.loading["summary"] = false;
      });
  },
});

// Export the generated actions and reducer directly
export default headendSlice.reducer;
