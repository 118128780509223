const COLUMNS = {
  headend: [
    { dataField: "name", text: "Name" },
    // { dataField: "code", text: "MS Id" },
    { dataField: "fqdn", text: "FQDN", link: true },
    { dataField: "category", text: "Category" },
    { dataField: "infra", text: "Infra" },
    // { dataField: "active", text: "Active" },
  ],
  host: [
    { dataField: "name", text: "Name" },
    { dataField: "headend", text: "Headend" },
    { dataField: "category", text: "Category" },
    { dataField: "owner", text: "Tenant" },
    { dataField: "spackVersion", text: "Spack Version" },
    { dataField: "osspackVersion", text: "OSSpack Version" },
    // { dataField: "status", text: "Status" },
    // { dataField: "active", text: "Active" },
  ],
  dcGateway: [
    { dataField: "name", text: "Name" },
    { dataField: "headendName", text: "Headend" },
    { dataField: "ipAddress", text: "IP" },
    { dataField: "spackVersion", text: "Spack Version" },
    { dataField: "osspackVersion", text: "OSSpack Version" },
    { dataField: "ownerOrgName", text: "Tenant" },
    // { dataField: "active", text: "Active" },
  ],
  orgs: [
    { dataField: "name", text: "Name" },
    { dataField: "headends", text: "Headends" },
    { dataField: "parentOrg", text: "Parent" },
    { dataField: "contacts", text: "Contacts" },
  ],
  tenants: [
    { dataField: "name", text: "Name" },
    { dataField: "parentOrg", text: "Parent Tenant" },
    // { dataField: "status", text: "Status" },
  ],
  director: [
    { dataField: "name", text: "Name" },
    { dataField: "primary", text: "Pri/Sec" },
    { dataField: "region", text: "Region" },
    { dataField: "fqdn", text: "FQDN", link: true },
  ],
  controller: [
    { dataField: "name", text: "Name" },
    { dataField: "ip", text: "IP" },
    { dataField: "region", text: "Region" },
  ],
  orgMapping: [
    { dataField: "name", text: "Name" },
    { dataField: "localId", text: "Local ID" },
    { dataField: "localKey", text: "Local Key" },
    { dataField: "remoteId", text: "Remote ID" },
    { dataField: "remoteKey", text: "Remote Key" },
  ],
  appliance: [
    { dataField: "name", text: "Name" },
    { dataField: "type", text: "Type" },
    { dataField: "ownerOrg", text: "Tenant" },
    // { dataField: "managedByUs", text: "Managed By Versa" },
  ],
  vms: [
    { dataField: "name", text: "Name" },
    { dataField: "type", text: "Type" },
    { dataField: "ownerOrg", text: "Tenant" },
  ],
  gateway: [
    { dataField: "name", text: "Name" },
    { dataField: "type", text: "Type" },
    { dataField: "ownerOrg", text: "Tenant" },
  ],
  analytics: [
    { dataField: "name", text: "Node name" },
    // { dataField: "analyticsName", text: "Analytics Name" },
    { dataField: "region", text: "Region" },
    { dataField: "cluster", text: "Cluster" },
    // { dataField: "active", text: "Active" },
  ],
  isp: [
    { dataField: "name", text: "Site Name" },
    { dataField: "dcName", text: "Data Center Code" },
    { dataField: "aristaSwName", text: "Arista SW Name" },
    { dataField: "aristaSwIp", text: "Arista SW Ip" },
  ],
  dcIsp: [
    { dataField: "isp", text: "ISP" },
    { dataField: "isp_side_ip", text: "ISP side IP" },
    { dataField: "versa_side_ip", text: "Versa side IP" },
    { dataField: "local_as", text: "Local As" },
    { dataField: "remote_as", text: "Remote As" },
    { dataField: "circuit_id", text: "Circuit ID" },
    { dataField: "complaint_url", text: "Contacts" },
  ],
  dc: [
    { dataField: "name", text: "DC Name" },
    { dataField: "site", text: "Site Name" },
    { dataField: "aristaSwName", text: "Arista SW Name" },
    { dataField: "gatewayNames", text: "Gateways" },
  ],
  backupStatus: [{ dataField: "name", text: "Server" }],
  monitoring: [{ dataField: "name", text: "Headend" }],
  monitoringAudit: [
    { dataField: "name", text: "Name" },
    { dataField: "frequency", text: "Frequency" },
    { dataField: "script", text: "Script" },
    { dataField: "status", text: "Enabled" },
    { dataField: "parameters_monitored", text: "Monitored Params" },
    { dataField: "mismatch", text: "Mismatch" },
  ],
  backupStatusDetail: [
    { dataField: "backupName", text: "File Path" },
    { dataField: "lastBackupTime", text: "Backup Time" },
    { dataField: "type", text: "Device" },
    { dataField: "backupType", text: "Type" },
    { dataField: "status", text: "Status" },
    { dataField: "message", text: "Message" },
  ],
  backupSummary: [
    { dataField: "serverName", text: "Server Name" },
    { dataField: "backupName", text: "File Path" },
    { dataField: "lastBackupTime", text: "Backup Time" },
    { dataField: "type", text: "Device" },
    { dataField: "status", text: "Status" },
    { dataField: "message", text: "Message" },
  ],
  category: [{ dataField: "name", text: "Server" }],
  orchestrators: [
    { dataField: "name", text: "Name" },
    { dataField: "headends", text: "Headends" },
    { dataField: "category", text: "Category" },
  ],
  orchestratorHost: [
    { dataField: "name", text: "Name" },
    { dataField: "fqdn", text: "FQDN", link: true },
    { dataField: "region", text: "Region" },
  ],
  roles: [{ dataField: "name", text: "Name" }],
  user: [
    { dataField: "name", text: "Name" },
    { dataField: "email", text: "Email" },
  ],
  headendUsers: [
    { dataField: "name", text: "Name" },
    { dataField: "role", text: "Role" },
    { dataField: "tenant", text: "Tenant" },
    { dataField: "twoFactor", text: "Two Factor" },
    { dataField: "userType", text: "User Type" },
  ],
  orchUsers: [
    { dataField: "username", text: "Username" },
    { dataField: "name", text: "Name" },
    { dataField: "email", text: "Email" },
    { dataField: "tenant", text: "Tenant" },
    { dataField: "twoFactor", text: "Two Factor" },
  ],
  activities: [
    { dataField: "name", text: "Name" },
    { dataField: "action", text: "Action" },
    { dataField: "createdAt", text: "Created On" },
    { dataField: "updatedAt", text: "Updated On" },
  ],
  userActions: [
    { dataField: "user", text: "User" },
    { dataField: "activity", text: "Acitivty" },
    { dataField: "count", text: "Count" },
  ],
  backupAudit: [
    { dataField: "device_name", text: "Device Name" },

    { dataField: "audit_time", text: "Audit Time" },
    { dataField: "status", text: "Backup Status" },
    // { dataField: "comment", text: "Comment" },
    { dataField: "files", text: "Comments" },
  ],
  subscriptions: [
    { dataField: "name", text: "Tenant" },
    { dataField: "parent", text: "Parent " },
    { dataField: "license", text: "Subscriptions" },
  ],
};

export default COLUMNS;
