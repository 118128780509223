import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import HeadendList from "./List";
import { useBreadcrumbs } from "contexts";
import { AddButton } from "components";
import { useNavigate } from "react-router-dom";
import Onboard from "./Onboard";
import { useDispatch, useSelector } from "react-redux";
import { fetchHeadendSummary } from "stores/actions";
import { mapping, colors } from "../../constants/mapping";
import Activities from "../../components/Activities";
import { usePermissions } from "../../hooks/usePermission";
import useActivityLogger from "hooks/useActivity";

const Headend = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { updateBreadcrumbs } = useBreadcrumbs();
  const { summary } = useSelector((state) => state.headend);
  const { activityLogs } = useSelector((state) => state.dashboard);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logActivity = useActivityLogger();

  useEffect(() => {
    updateBreadcrumbs([{ text: "Home", url: "/dashboard" }]);
    dispatch(fetchHeadendSummary());
  }, []);

  const sendLog = (activity, name) => {
    logActivity({
      page: "dashboard",
      activity,
      name,
    });
  };

  const redirectToManage = (activity, headend) => {
    sendLog(activity, headend?.name);
    navigate(`/headends/manage/${headend?.id}`);
  };

  return (
    <>
      <div className="middle-section">
        <Row className="">
          <Col sm={8}>
            <Row className="my-2">
              <Col>
                <div>
                  <h5>
                    <b>Headends</b>
                  </h5>
                  <small className="sub-info">List of all headends</small>
                </div>
              </Col>

              <Col className="col-auto">
                {usePermissions(["HEADENDS_ONBOARD"]) && (
                  <Onboard redirectToManage={redirectToManage} />
                )}
              </Col>
            </Row>

            <Card className="mt-3">
              <Card.Body>
                <HeadendList
                  searchTerm={searchTerm}
                  redirectToManage={redirectToManage}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Row>
              <Col sm={6} className="mb-3">
                <Card className="orange bg">
                  <Card.Body>
                    <h1>{summary?.totalHeadends}</h1>
                    Total
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={6} className="mb-3">
                <Card className="green bg">
                  <Card.Body>
                    <h1>{summary?.activeHeadends}</h1>
                    Active
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={6} className="mb-3">
                <Card className="blue bg">
                  <Card.Body>
                    <h1>{summary?.publishedHeadends}</h1>
                    Published
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={6} className="mb-3">
                <Card className="red bg">
                  <Card.Body>
                    <h1>{summary?.draftHeadends}</h1>
                    Draft
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <div className="mt-4 mb-3">
                <h5>
                  <b>Headend on Infra</b>
                </h5>
                <small className="sub-info">
                  List of all headends under different infra
                </small>
              </div>

              {summary?.headendsByInfra?.map((infra, index) => (
                <Col key={infra.categoryName} md={6}>
                  <Card>
                    <Card.Body className="gradient-txt">
                      <Card.Title>{infra.categoryName}</Card.Title>
                      <h2 className="gradient-txt">
                        <b>
                          <Card.Text>{infra.count}</Card.Text>
                        </b>
                      </h2>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row>
              <div className="mt-4 mb-3">
                <h5>
                  <b>Headends Categories </b>
                </h5>
                <small className="sub-info">
                  List of all headends under different categories
                </small>
              </div>

              {summary?.headendsByCategory?.map((category, index) => (
                <Col key={category.categoryName} md={6} className="mb-4">
                  <Card>
                    <Card.Body className="">
                      <Row>
                        <Col>
                          <h5 className="m-0">
                            <b>{category.categoryName}</b>
                          </h5>
                        </Col>
                        <Col className="col-auto">
                          {" "}
                          <h2
                            className="gradient-txt m-0"
                            style={{ fontSize: "1.5rem" }}
                          >
                            <b>
                              <Card.Text>{category.count}</Card.Text>
                            </b>
                          </h2>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
            {/* <Activities type="headend" /> */}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Headend;
