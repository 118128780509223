import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { bulkUpload } from "stores/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

const FileUpload = ({ type }) => {
  const dispatch = useDispatch();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleUpload = async () => {
    if (!uploadedFile) return;

    try {
      const response = await dispatch(bulkUpload({ data: uploadedFile, type }));
      handleClose();
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  function handleChange(e) {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      setUploadedFile(JSON.parse(e.target.result));
    };
  }

  function handleClose() {
    setShowModal(false);
  }

  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload {type}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="file" accept=".json" onChange={handleChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleUpload}
            disabled={!uploadedFile}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <button className="btn blue cl" onClick={() => setShowModal(true)}>
        <FontAwesomeIcon icon={faUpload} /> Upload
        {/* Upload */}
      </button>
    </>
  );
};

export default FileUpload;
