import React, { useEffect, useMemo, useState } from "react";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import { Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import SingleView from "./SingleBackupView";
import useActivityLogger from "hooks/useActivity";

const BackupListView = ({ backups }) => {
  const logActivity = useActivityLogger();
  const [selected, setSelected] = useState({});
  const [key, setKey] = useState(localStorage.getItem("manageTab") || "users");

  const statusData = useMemo(() => {
    return Object.entries(backups)?.map(([key, val]) => {
      return {
        name: key,
        values: val,
      };
    });
  }, [backups]);

  useEffect(() => {
    if (statusData.length > 0) {
      setTabKey(statusData[0]?.name);
    }
  }, [statusData]);

  useEffect(() => {
    if (key.length > 0 && backups[key]) {
      const data = Object.entries(backups[key])[0];
      const defaultSelected = { name: data[0], values: data[1] };
      setSelected(defaultSelected);
    }
  }, [key, backups]);

  const onView = (item) => {
    logActivity({
      page: "backups",
      activity: "click-view",
      item: item?.name,
    });
    setSelected(item);
  };

  const setTabKey = (selectedKey) => {
    localStorage.setItem("backup-tabs", selectedKey);
    setKey(selectedKey);
  };

  const defineData = (backups) => {
    return Object.entries(backups)?.map(([key, val]) => {
      return {
        name: key,
        values: val,
      };
    });
  };

  return (
    <>
      <Row>
        <div className="mb-3">
          <h5>
            <b>Backup History</b>
          </h5>
          <small className="sub-info">
            Displays all Backups taken from servers
          </small>
        </div>
        <Tabs
          id="controlled-tabs"
          activeKey={key}
          onSelect={setTabKey}
          className="mb-3  mr-3 p-0"
        >
          {statusData?.map((item) => (
            <Tab eventKey={item?.name} title={item?.name.toUpperCase()}>
              <Row>
                <Col md={3}>
                  <Card className="mb-4">
                    <Card.Body>
                      <PaginatedTable
                        data={defineData(item.values)}
                        columns={COLUMNS["backupStatus"]}
                        perPage={25}
                        onView={onView}
                      />
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={9}>
                  <SingleView backup={selected} />
                </Col>
              </Row>
            </Tab>
          ))}
        </Tabs>
      </Row>
    </>
  );
};

export default BackupListView;
