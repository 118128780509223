// pages/RoleManagement.js
import React, { useState } from "react";
import { createRole, updateRole, deleteRole } from "stores/actions";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import deepClone from "utils/deepClone";

const RoleManagement = () => {
  const dispatch = useDispatch();
  const { roles, pages } = useSelector((state) => state.auth);
  const [newRoleName, setNewRoleName] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedPages, setSelectedPages] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const handleRoleChange = (item) => {
    const role = roles?.find((role) => role._id === item._id);
    setSelectedRole(role);
    setSelectedPages(role?.pages?.map((page) => page._id) || []);
    const newPermisisons = [];
    console.log("ROLES", role);
    role?.pages?.forEach((page) => {
      page?.permissions?.forEach((perm) =>
        newPermisisons.push(page._id + perm._id)
      );
    });
    setNewRoleName(role?.name);
    console.log("NEW PERMISISON", newPermisisons, role);
    setSelectedPermissions(newPermisisons);
  };

  const handlePageChange = (newPage, checked = true) => {
    const value = newPage?._id;
    const clonedPages = deepClone(selectedPages);
    clonedPages.push(value);
    setSelectedPages(Array.from(new Set(clonedPages)));
  };

  const handlePermissionChange = (event, page) => {
    const { value, checked } = event.target;
    handlePageChange(page);
    const newVal = page._id + value;
    setSelectedPermissions((prev) =>
      checked ? [...prev, newVal] : prev.filter((perm) => perm !== newVal)
    );
  };

  const handleSave = async () => {
    const updatedPages = pages
      .filter((page) => selectedPages?.includes(page._id))
      .map((page) => {
        return {
          ...page,
          permissions: page?.permissions.filter((perm) =>
            selectedPermissions?.includes(page._id + perm._id)
          ),
        };
      });
    if (selectedRole) {
      console.log("UPDATED PAGES", updatedPages);
      await dispatch(
        updateRole({
          roleId: selectedRole._id,
          updatedRole: {
            ...selectedRole,
            pages: updatedPages,
          },
        })
      );
    } else {
      await dispatch(
        createRole({
          name: newRoleName,
          pages: updatedPages,
        })
      );
    }
    setNewRoleName("");
    setSelectedPages([]);
    setSelectedPermissions([]);
    setSelectedRole(null);
  };

  return (
    <Container>
      <div className="mb-3">
        <h5>
          <b>Roles Management</b>
        </h5>
        <small className="sub-info">Manage all roles and permissions</small>
      </div>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <PaginatedTable
                columns={COLUMNS["roles"]}
                data={roles}
                onView={handleRoleChange}
                onDelete={(item) => dispatch(deleteRole(item._id))}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Form.Group controlId="newRoleName">
            <Form.Label>New Role Name</Form.Label>
            <Form.Control
              type="text"
              value={newRoleName}
              onChange={(e) => setNewRoleName(e.target.value)}
              placeholder="Enter new role name"
            />
          </Form.Group>
          <Button className="mt-2" onClick={handleSave}>
            Create Role
          </Button>
          {/* {selectedRole && ( */}
          <>
            <div className="mb-3 mt-3">
              <h5>
                <b>Manage Permissions {newRoleName}</b>
              </h5>
              <small className="sub-info">
                Assign and remove permissions {newRoleName}
              </small>
            </div>

            <Card className="mt-4">
              <Card.Body>
                <Row>
                  <Col>
                    {pages?.map((page) => (
                      <>
                        <b>{page.name}</b>

                        <Row className="mt-2">
                          {page?.permissions?.map((permissions) => (
                            <Col>
                              <Form.Check
                                key={permissions._id}
                                type="checkbox"
                                label={permissions.name}
                                style={{ fontSize: "0.75rem" }}
                                value={permissions._id}
                                checked={selectedPermissions?.includes(
                                  page._id + permissions._id
                                )}
                                onChange={(e) =>
                                  handlePermissionChange(e, page)
                                }
                              />
                            </Col>
                          ))}
                        </Row>
                        <hr className="my-2" />
                      </>
                    ))}
                  </Col>
                </Row>
                <Button className="mt-3" onClick={handleSave}>
                  Save Changes
                </Button>
              </Card.Body>
            </Card>
          </>
          {/* )} */}
        </Col>
      </Row>
    </Container>
  );
};

export default RoleManagement;
