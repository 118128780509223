import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "services/api";
import { showAlert } from "../slices/alertSlice";
import MESSAGES from "../../constants/messages";
import React from "react";

const fetchBackups = createAsyncThunk(
  "backups/fetchBackups",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get(`/backups`);
      return response.data;
    } catch (error) {
      dispatch(
        showAlert({
          message: MESSAGES["backup-fetch-fa"],
          variant: "danger",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

const fetchBackupSummary = createAsyncThunk(
  "backups/fetchBackupSummary",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get(`/backups/summary`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchSingleBackup = createAsyncThunk(
  "backups/fetchSingleBackup",
  async (name, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get(`/backups/${name}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export { fetchBackups, fetchSingleBackup, fetchBackupSummary };
