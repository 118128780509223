import { Card, Col, ListGroup, Modal, Row } from "react-bootstrap";
import COLUMNS from "constants/columns";
import { PaginatedTable } from "components";
import maskPassword from "utils/maskPassword";
import { useEffect, useMemo, useState } from "react";
import SingleView from "pages/Host/SingleHostView";
// import Nodes from "./Nodes";
import Diagram from "./Nodes";
import BackButton from "components/BackButton";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchDC } from "stores/actions";

const DcDetailsView = ({ data, showModal, closeModal }) => {
  const [selectedHost, setSelectedHost] = useState(null);
  const [showHostModal, setShowHostModal] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { dataCenter } = useSelector((state) => state.dataCenter);

  useEffect(() => {
    dispatch(fetchDC(id));
  }, [id]);

  return (
    <div className={`middle-section`}>
      <Row>
        <Col sm={12}>
          <BackButton title="Data Centers" />
          <div className="my-2">
            <h5>
              <b>Data Center Details</b>
            </h5>
            <small className="sub-info">Detailed view of the DC</small>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Card className="mb-3">
            <Card.Body>
              <div className="my-3">
                <h5>
                  <b>DC Details</b>
                </h5>
                <small className="sub-info">Informations about the DC</small>
              </div>
              <ListGroup variant="flush" className="mb-3 ">
                <ListGroup.Item>
                  <span>DC Name</span>

                  <b>{dataCenter?.name}</b>
                </ListGroup.Item>
                <ListGroup.Item>
                  <span>Site</span>

                  <b>{dataCenter?.site}</b>
                </ListGroup.Item>

                <ListGroup.Item>
                  <span>Location </span>
                  <b>{dataCenter?.location?.name}</b>
                </ListGroup.Item>
                <ListGroup.Item>
                  <span>Region </span>
                  <b>{dataCenter?.location?.region?.name}</b>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <div className="my-3">
                <h5>
                  <b>Switch Details</b>
                </h5>
                <small className="sub-info">Switch informations</small>
              </div>
              <ListGroup variant="flush" className="mb-3 ">
                <ListGroup.Item>
                  <span>Switch Name</span>

                  <b>{dataCenter?.arista_sw?.name}</b>
                </ListGroup.Item>
                <ListGroup.Item>
                  <span>Ip Address</span>

                  <b>{dataCenter?.arista_sw?.ipAddress}</b>
                </ListGroup.Item>
                <ListGroup.Item>
                  <span>Switch Type</span>

                  <b>{dataCenter?.arista_sw?.subType}</b>
                </ListGroup.Item>
              </ListGroup>

              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>
                    <b>IPs</b>
                  </Card.Title>
                  <Card.Text>
                    All IPs related to device will be displayed
                  </Card.Text>
                  <Row className="details mb-2">
                    {dataCenter?.arista_sw?.ips?.map((credentials, credIndex) =>
                      Object.entries(credentials).map(
                        ([key, value], index) =>
                          key !== "_id" && (
                            <Col key={`${credIndex}-${index}`}>
                              <ListGroup.Item>
                                <span className="capitalize">
                                  {key.replace("_", " ")}
                                </span>
                                :{"  "}
                                <b>{value}</b>
                              </ListGroup.Item>
                            </Col>
                          )
                      )
                    )}
                  </Row>

                  {dataCenter?.arista_sw?.wanIps?.map(
                    (credentials, credIndex) => (
                      <Row className="details">
                        {Object.entries(credentials).map(
                          ([key, value], index) =>
                            key !== "_id" && (
                              <Col key={`${credIndex}-${index}`}>
                                <ListGroup.Item>
                                  <span className="capitalize">
                                    {key.replace("_", " ")}
                                  </span>
                                  :{"  "}
                                  <b>{value}</b>
                                </ListGroup.Item>
                              </Col>
                            )
                        )}
                      </Row>
                    )
                  )}
                </Card.Body>
              </Card>
              <Card className="mb-3 ">
                <Card.Body>
                  <Card.Title>
                    <b>SSH Credentials</b>
                  </Card.Title>
                  <Card.Text>SSH credentials to logon to the device</Card.Text>
                  <Row>
                    {dataCenter?.arista_sw?.sshCredentials?.map(
                      (credentials, credIndex) =>
                        Object.entries(credentials).map(
                          ([key, value], index) =>
                            key !== "_id" && (
                              <Col key={`${credIndex}-${index}`}>
                                <span className="capitalize">{key}</span>
                                <br />
                                <b>
                                  {key === "password"
                                    ? maskPassword(value)
                                    : value}
                                </b>
                              </Col>
                            )
                        )
                    )}
                  </Row>
                </Card.Body>
              </Card>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="mb-3">
            <Card.Body>
              <div className="my-3">
                <h5>
                  <b>ISP Details</b>
                </h5>
                <small className="sub-info">Informations about the ISPs</small>
              </div>
              <PaginatedTable
                data={dataCenter?.connections || []}
                columns={COLUMNS["dcIsp"]}
                paginationRequired={false}
              />
            </Card.Body>
          </Card>
          <Card className="mb-3">
            <Card.Body>
              <div className="my-3">
                <h5>
                  <b>Gateway Details</b>
                </h5>
                <small className="sub-info">
                  Informations about the Gateways
                </small>
              </div>
              <PaginatedTable
                data={dataCenter?.gateway}
                columns={COLUMNS["dcGateway"]}
                paginationRequired={false}
                onView={(item) => {
                  setSelectedHost(item);
                  setShowHostModal(true);
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        {dataCenter && (
          <Col>
            <Card className="mt-3">
              <Card.Body>
                <div className="my-3" style={{ textAlign: "center" }}>
                  <h5>
                    <b>Topology</b>
                  </h5>
                  <small className="sub-info">
                    Defines you the topology of that DC
                  </small>
                </div>
                <Diagram data={dataCenter} modalVisible={showModal} />
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>

      <SingleView
        host={selectedHost}
        removeHost={setSelectedHost}
        showModal={showHostModal}
        closeModal={() => setShowHostModal(false)}
      />
    </div>
  );
};

export default DcDetailsView;
