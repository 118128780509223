import { Card, Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { PaginatedTable } from "components";
import COLUMNS from "constants/columns";
import { useNavigate } from "react-router-dom";
import { usePermissions } from "hooks/usePermission";
import useActivityLogger from "hooks/useActivity";
import Loader from "components/Loader";

const CategorySummary = ({ canNavigate }) => {
  const { catSummary, loading } = useSelector((state) => state.dashboard);
  const navigate = useNavigate();
  const logActivity = useActivityLogger();

  const headendData = (headends) => {
    return headends.map((head) => {
      return {
        id: head?._id,
        name: head?.name,
        code: head?.code,
        infra: head?.infra?.name,
        category: head?.category?.name,
        fqdn: head?.fqdn,
        active: head?.active ? "Yes" : "No",
      };
    });
  };

  const onView = (item) => {
    console.log("ITEM", item);
    sendLog(item?.name);
    if (canNavigate) navigate(`/headends/${item.id}`);
  };

  const SummaryTableComponent = ({ category }) => {
    return (
      <PaginatedTable
        data={headendData(category.headends)}
        columns={COLUMNS["headend"]}
        itemsPerPage={5}
        onView={usePermissions(["HEADENDS_VIEW"]) && canNavigate && onView}
        // onEdit={usePermissions(["HEADENDS_EDIT"]) && onEdit}
      />
    );
  };

  const sendLog = (type) => {
    logActivity({
      page: "dashboard",
      activity: "category-click",
      name: type,
    });
  };

  return (
    <>
      <div className="mt-2 mb-3">
        <h5>
          <b>Headends Category Summary</b>
        </h5>
        <small className="sub-info">List of categories and headends</small>
      </div>
      <Row>
        {loading["catSummary"] ? (
          <Loader />
        ) : (
          catSummary.map((category, index) => (
            <Col key={category.category} className="mb-2" md={12}>
              <OverlayTrigger
                trigger="click"
                rootClose
                placement="auto"
                overlay={
                  <Popover
                    id="popover-trigger-click-root-close"
                    title="Popover"
                    style={{ minWidth: "40vw", padding: "10px" }}
                  >
                    <SummaryTableComponent category={category} />
                  </Popover>
                }
              >
                <Card
                  style={{ cursor: "pointer" }}
                  onClick={() => sendLog(category.category)}
                  className="mb-2 hover-item "
                >
                  <Card.Body className="gradient-txt py-1">
                    <Row>
                      <Col>
                        <Card.Title className="mt-4">
                          {category.category}
                        </Card.Title>
                      </Col>
                      <Col className="col-auto">
                        {" "}
                        <h2 className="gradient-txt m-0">
                          <b>
                            <Card.Text>{category.count}</Card.Text>
                          </b>
                        </h2>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </OverlayTrigger>
            </Col>
          ))
        )}
      </Row>
    </>
  );
};

export default CategorySummary;
