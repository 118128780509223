import React from "react";
import { Tabs, Tab, Card } from "react-bootstrap";
import {
  AnalyticsView,
  AppliancesView,
  ControllersView,
  DirectorsView,
  GatewaysView,
  OrganisationView,
  UsersView,
  VmsView,
} from "./Details";

const HeadendDetailsView = ({ headend }) => {
  return (
    <>
      {headend && (
        <Card>
          <Card.Body>
            <Tabs
              defaultActiveKey="direcotrs"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab
                eventKey="direcotrs"
                title={`Directors(${headend.directors.length})`}
              >
                <DirectorsView directors={headend.directors} />
              </Tab>
              <Tab
                eventKey="organisations"
                title={`Tenants (${headend.organisations.length})`}
              >
                <OrganisationView tenants={headend.organisations} />
              </Tab>
              <Tab
                eventKey="controllers"
                title={`Controllers(${headend.controllers.length})`}
              >
                <ControllersView controllers={headend.controllers} />
              </Tab>
              <Tab
                eventKey="analytics"
                title={`Analytics(${headend.analytics.length})`}
              >
                <AnalyticsView analytics={headend.analytics} />
              </Tab>
              {headend?.gateways?.length > 0 && (
                <Tab
                  eventKey="gateways"
                  title={`Gateways(${headend.gateways.length})`}
                >
                  <GatewaysView gateways={headend.gateways} />
                </Tab>
              )}
              <Tab
                eventKey="appliances"
                title={`Appliances(${headend.appliances.length})`}
              >
                <AppliancesView appliances={headend.appliances} />
              </Tab>
              {headend?.vms?.length > 0 && (
                <Tab eventKey="vms" title={`Vms(${headend.vms.length})`}>
                  <VmsView vms={headend.vms} />
                </Tab>
              )}
              <Tab
                eventKey="users"
                title={`Users(${
                  headend?.tenantUsers?.length + headend?.adminUsers?.length
                })`}
              >
                <UsersView
                  tenantUsers={headend?.tenantUsers}
                  adminUsers={headend?.adminUsers}
                />
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default HeadendDetailsView;
