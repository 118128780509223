import React, { useEffect, useState } from "react";
import { Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import CategoryForm from "./AddForm";
import CategoryList from "./List";
import { useBreadcrumbs } from "../../contexts";
import capitalize from "utils/capitalize";

const Categories = () => {
  const [key, setKey] = useState(
    localStorage.getItem("categoryTab") || "headendCategory"
  );

  const [searchTerm, setSearchTerm] = useState("");
  const { updateBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    updateBreadcrumbs([{ text: "Home", url: "/dashboard" }]);
  }, []);
  const colors = ["green", "orange", "blue", "red", "yellow", "violet"];
  const { categories } = useSelector((state) => state.categories);

  const setTabKey = (selectedKey) => {
    localStorage.setItem("categoryTab", selectedKey);
    setKey(selectedKey);
  };

  return (
    <>
      <div className="middle-section">
        <Row>
          <Col md={7}>
            <div className="my-2">
              <h5>
                <b>Categories</b>
              </h5>
              <small className="sub-info">Manage all categories here</small>
            </div>
            <Card className="my-2">
              <Card.Body>
                <Row className="category-tabs">
                  <Tabs
                    id="controlled-tabs"
                    activeKey={key}
                    onSelect={setTabKey}
                    className="mb-3  mr-3 p-0"
                    // as={Card}
                  >
                    {Object.keys(categories).map((key, index) => (
                      <Tab
                        eventKey={`${key}Category`}
                        title={`${capitalize(key)} Category`}
                      >
                        <div className="p-3">
                          <CategoryList
                            categories={categories[key]}
                            type={key}
                            searchTerm={searchTerm}
                          />
                        </div>
                      </Tab>
                    ))}
                  </Tabs>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Row className="mb-3">
              {Object.keys(categories).map((key, index) => (
                <Col md={4} className="mb-3">
                  <Card className={`${colors[index]} bg`}>
                    <Card.Body>
                      <h1>{categories[key]?.length}</h1>
                      {key} <br /> Category
                    </Card.Body>
                  </Card>
                </Col>
              ))}

              <Col md={4} className="mb-3">
                <CategoryForm />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <div className="m-3"></div>
    </>
  );
};

export default Categories;
