import React from "react";
import { ProgressBar, Card } from "react-bootstrap";
import { percentageVariant } from "utils/percentageVariant";

const ProgressCard = ({ icon, title, subInfo, data }) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <h4>
          {icon && <span className="mx-2">{icon}</span>}
          <b>{title}</b>
        </h4>
        <small className="sub-info">{subInfo}</small>
        {data.map(
          (item, index) =>
            item.percentage && (
              <div key={index} className="mt-3">
                <p className="mb-2">{item.label}</p>

                <ProgressBar
                  now={item.percentage}
                  label={`${item.percentage.toFixed(1)} %`}
                  variant={percentageVariant(item.percentage)}
                />
              </div>
            )
        )}
      </Card.Body>
    </Card>
  );
};

export default ProgressCard;
