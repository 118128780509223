import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "services/api";

const fetchNmapReport = createAsyncThunk(
  "security/fetchSecurity",
  async (id = null, { rejectWithValue }) => {
    try {
      const response = await API.get(`/security/nmap?id=${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export { fetchNmapReport };
