import { configureStore } from "@reduxjs/toolkit";
import {
  authSlice,
  apiSlice,
  tenantSlice,
  hostSlice,
  headendSlice,
  categoriesSlice,
  ispSlice,
  dashboardSlice,
  alertSlice,
  backupSlice,
  orchestratorSlice,
  userActivitiesSlice,
  securitySlice,
  dataCenterSlice,
  auditSlice,
} from "./slices";

import { thunk } from "redux-thunk";
// import createOidcMiddleware from "redux-oidc";
// import userManager from "../services/idp/userManager";

// const oidcMiddleware = createOidcMiddleware(userManager);

export default configureStore({
  reducer: {
    auth: authSlice,
    api: apiSlice,
    organisation: tenantSlice,
    categories: categoriesSlice,
    host: hostSlice,
    headend: headendSlice,
    isps: ispSlice,
    dashboard: dashboardSlice,
    alert: alertSlice,
    backup: backupSlice,
    orchestrators: orchestratorSlice,
    userActivities: userActivitiesSlice,
    security: securitySlice,
    dataCenter: dataCenterSlice,
    audit: auditSlice,
    // other reducers can be added here
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  devTools: process.env.NODE_ENV !== "production",
});
