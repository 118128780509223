import { Card, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import { useEffect, useMemo, useState } from "react";

const SingleView = ({ backup }) => {
  const [allDates, setAllDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedVal, setSelectedVal] = useState(null);

  useEffect(() => {
    if (backup?.values) {
      const keys = Object.keys(backup?.values);
      keys.sort((a, b) => new Date(b) - new Date(a));
      setAllDates(keys);
      setSelectedDate(keys[0]);
      setSelectedVal(backup?.values[keys[0]]);
    }
  }, [backup]);

  const formattedValues = useMemo(() => {
    let backupData = [];
    if (selectedDate) {
      backupData = backup?.values[selectedDate];
    }
    return backupData;
  }, [selectedDate, allDates]);

  return (
    <>
      {backup?.name && (
        <>
          <Row>
            <Col>
              <div className=" mt-2 mb-3">
                <h5>
                  <b>{backup?.name}</b>
                </h5>
                <small className="sub-info">More backup details</small>
              </div>
            </Col>
            <Col className="col-auto">
              <Dropdown.ItemText className="mb-3">
                <DropdownButton
                  id={`headend-lists`}
                  className="filter-btn cust-dropdown"
                  title={`${selectedDate}`}
                  onSelect={(value) => {
                    setSelectedDate(value);
                    setSelectedVal(backup?.values[value]);
                  }}
                >
                  {allDates?.map((option, index) => (
                    <Dropdown.Item key={index} eventKey={option}>
                      {option}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Dropdown.ItemText>
            </Col>
          </Row>

          {selectedVal && (
            <Card className="mb-3">
              <Card.Header>
                <b>{selectedDate}</b>
              </Card.Header>
              <Card.Body>
                <PaginatedTable
                  data={formattedValues}
                  columns={COLUMNS["backupStatusDetail"]}
                  itemsPerPage={25}
                />
              </Card.Body>
            </Card>
          )}
          {/* ))} */}
        </>
      )}
    </>
  );
};

export default SingleView;
