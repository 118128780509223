// src/components/MainContent.js
import Feedback from "components/FeedBack";
import { usePermissions } from "hooks/usePermission";
import React from "react";
import { Container } from "react-bootstrap";

const MainContent = ({ children }) => {
  return (
    <Container fluid className="main-content">
      {/* Main content area */}
      {children}
      {usePermissions(["DASHBOARD_FEEDBACK"]) && <Feedback />}
    </Container>
  );
};

export default MainContent;
