// BreadcrumbContext.js
import React, { createContext, useContext, useState } from "react";

const BreadcrumbContext = createContext();

const BreadcrumbProvider = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const updateBreadcrumbs = (newBreadcrumbs) => {
    setBreadcrumbs(newBreadcrumbs);
  };

  return (
    <BreadcrumbContext.Provider value={{ breadcrumbs, updateBreadcrumbs }}>
      {children}
    </BreadcrumbContext.Provider>
  );
};

const useBreadcrumbs = () => useContext(BreadcrumbContext);

export { BreadcrumbProvider, useBreadcrumbs };
