import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HoverIcon = ({ defaultIcon, hoverIcon, className }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <FontAwesomeIcon
      icon={isHovered ? hoverIcon : defaultIcon}
      className={className}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    />
  );
};

export default HoverIcon;
