import React, { useEffect } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LOGO, MENU } from "constants/menu";
import { usePermissions } from "hooks/usePermission";
import useActivityLogger from "hooks/useActivity";
import { useSelector } from "react-redux";

const NavComponent = ({ menu, isCollapsed }) => {
  const hasPemrisison = usePermissions([menu.permission]);
  const logActivity = useActivityLogger();

  const sendLog = (activity, name) => {
    logActivity({
      page: "navigation",
      activity,
      name,
    });
  };

  if (hasPemrisison)
    return (
      <Nav.Item>
        <Nav.Link
          as={NavLink}
          to={menu.link}
          className={`ellipsis ${isCollapsed ? "collapsed" : ""}`}
          onClick={() => sendLog("menu-click", menu.name)}
        >
          <FontAwesomeIcon
            icon={menu.icon}
            className={`nav-icon ${isCollapsed ? "icon-collapsed" : ""}`}
          />
          {!isCollapsed && menu.name}
        </Nav.Link>
      </Nav.Item>
    );
};

const SideNavbar = ({ isCollapsed }) => {
  const location = useLocation();
  const { version } = useSelector((state) => state.dashboard);

  useEffect(() => {
    const newLocation = MENU.find((menu) => menu.link === location.pathname);
    localStorage.setItem("page", newLocation?.name || "Dashboard");
  }, [location]);

  return (
    <Nav
      className={` sidebar p-3 ${isCollapsed ? "collapsed" : ""}`}
      activeKey={location.pathname}
    >
      <div className="sidebar-sticky">
        <Navbar.Brand as={Link} to="/" className="">
          <img
            src={LOGO} // Replace with your logo image URL
            alt="Logo"
            height="40"
            className={`d-inline-block align-top ${
              isCollapsed ? "logo-collapsed" : ""
            }`}
          />
        </Navbar.Brand>
        {MENU.map((menu) => (
          <NavComponent menu={menu} key={menu.name} isCollapsed={isCollapsed} />
        ))}
      </div>
      <p className="mb-0 text-center w-100">
        <small>
          {!isCollapsed && "App Version :"} {version}
        </small>
      </p>
    </Nav>
  );
};

export default SideNavbar;
