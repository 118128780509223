import React from "react";
import { Container, Card, ListGroup, Row, Col, Modal } from "react-bootstrap";
import { CopyToClipboard, DetailsView } from "../../components";
import { ISP_PROVIDER_INFO } from "../../constants/listInfoFields";
import maskPassword from "utils/maskPassword";

const SingleView = ({ isp, removeIsp, showModal, closeModal }) => {
  return (
    <Modal show={showModal} onHide={closeModal} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          {isp?.site_name}{" "}
          {/* <FontAwesomeIcon
              icon={host?.active ? faCircleCheck : faCircleMinus}
              className={`${host?.active ? "green" : "red"} cl`}
            /> */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg">
        <Container>
          <Card className="mb-3">
            {/* <Card.Header></Card.Header> */}
            <Card.Body>
              <Row>
                <Col>
                  <span>Data Center</span>
                  {" : "}
                  <b>{isp?.data_center_name}</b>
                </Col>
                <Col>
                  <span>Arista SW IP:</span> {" : "}
                  <b>{isp?.arista_sw_ip}</b>
                </Col>
                <Col>
                  <span>Arista SW Name:</span> {" : "}
                  <b>{isp?.arista_sw_name}</b>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          {isp?.connectivity_providers?.map((provider) => (
            <Card key={provider._id} className="mb-4">
              <Card.Header>
                <b>{provider.isp}</b>
              </Card.Header>
              <Card.Body>
                <Row className="details">
                  <Col>
                    <Row>
                      {ISP_PROVIDER_INFO.map((info) => (
                        <Col sm={6} className="mb-3">
                          <span>{info.label}:</span>
                          <br /> <b>{provider[info.value]}</b>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                  <Col md={7}>
                    <h6>
                      <b>Contacts</b>
                    </h6>
                    {provider?.contacts?.map((contact) => (
                      <Card className="mb-2">
                        {/* <Card.Header>
                          <Row>
                            <Col> {contact.type}</Col>
                            <Col className="col-auto"></Col>
                          </Row>
                        </Card.Header> */}
                        <Card.Body className="p-2 px-3">
                          {contact.type === "website" ? (
                            <>
                              {contact?.values?.map((val) => (
                                <Row>
                                  <Col>
                                    <span>Location : </span>
                                    <br /> <b>{contact.location}</b>
                                  </Col>
                                  <Col>
                                    <span>Website : </span>
                                    <br /> <b>{val.link}</b>
                                  </Col>
                                  <Col>
                                    <span>Username : </span>
                                    <br /> <b>{val.username}</b>{" "}
                                    <CopyToClipboard text={val.username} />
                                  </Col>
                                  <Col>
                                    <span>Password : </span>
                                    <br /> <b>
                                      {maskPassword(val.password)}
                                    </b>{" "}
                                    <CopyToClipboard text={val.password} />
                                  </Col>
                                </Row>
                              ))}
                            </>
                          ) : (
                            <Row>
                              <Col>
                                <span>Location : </span>
                                <br /> <b>{contact?.location}</b>
                              </Col>
                              <Col>
                                <span>{contact?.type} : </span>
                                <br /> <b>{contact?.value}</b>{" "}
                                <CopyToClipboard text={contact?.value} />
                              </Col>
                            </Row>
                          )}
                        </Card.Body>
                      </Card>
                    ))}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          ))}
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default SingleView;
