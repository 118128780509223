import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useBreadcrumbs } from "contexts";
import { fetchDCs } from "stores/actions";
import useActivityLogger from "hooks/useActivity";
import DcMap from "components/Map/dcMap";
import { Card, Col, Row } from "react-bootstrap";
import DCListView from "./List";

const DataCenter = () => {
  const dispatch = useDispatch();
  const { updateBreadcrumbs } = useBreadcrumbs();
  const logActivity = useActivityLogger();
  const { dataCenters } = useSelector((state) => state.dataCenter);

  const sendLog = (page, activity, name) => {
    logActivity({
      page,
      activity,
      name,
    });
  };

  useEffect(() => {
    updateBreadcrumbs([{ text: "Home", url: "/dashboard" }]);
    sendLog("Data Center", "page-load", "DCs");
    dispatch(fetchDCs());
  }, []);

  return (
    <>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              {dataCenters.length > 0 && (
                <DcMap
                  dataCenters={dataCenters}
                  mapConfig={{
                    width: "100%",
                    height: "50vh", // Full height map
                  }}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <div className="middle-section">
        <div className="my-2">
          <h5>
            <b>Data Centers</b>
          </h5>
          <small className="sub-info">
            List of all Data centers and associated devices
          </small>
        </div>
      </div>
      <Row className="my-2">
        <Col>
          <Card>
            <Card.Body>
              <DCListView sendLog={sendLog} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DataCenter;
