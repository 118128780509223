import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { saveActivity } from "stores/actions";

const useActivityLogger = () => {
  const dispatch = useDispatch();

  const logActivity = useCallback(
    async (data) => {
      try {
        const userAgent = navigator.userAgent;
        const pageUrl = window.location.href;
        const referrerUrl = document.referrer;
        const browserLanguage = navigator.language;
        const user = JSON.parse(localStorage.getItem("user"));

        dispatch(
          saveActivity({
            user: user?.email,
            userAgent,
            pageUrl,
            referrerUrl,
            browserLanguage,
            ...data,
          })
        );
      } catch (error) {
        console.error("Error logging activity:", error);
      }
    },
    [dispatch]
  );

  return logActivity;
};

export default useActivityLogger;
