// CategoryForm.js
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Button, Modal, Card } from "react-bootstrap";
import { CATEGORIES_OPTIONS } from "constants/selectOptions";
import { saveCategory } from "stores/actions";

const CategoryForm = () => {
  const dispatch = useDispatch();
  const categories = CATEGORIES_OPTIONS;
  const initData = { name: "", type: "" };
  const [formData, setFormData] = useState(initData);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  function handleClose() {
    setShowModal(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await dispatch(saveCategory(formData));
    setFormData(initData);
    handleClose();
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add New Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Type:</Form.Label>
              <Form.Control
                as="select"
                name="type"
                value={formData.type}
                onChange={handleChange}
              >
                <option value="">Select Type</option>
                {categories?.map((category) => (
                  <option key={category.value} value={category?.value}>
                    {category?.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formHeadendCategoryName" className="mb-3">
              <Form.Label>Name:</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter name"
              />
            </Form.Group>

            <Button
              className="float-right w-25 mb-3"
              variant="success"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Card className="text-center" onClick={() => setShowModal(true)}>
        <Card.Body className="">
          <h1> +</h1>
          New <br />
          Category
        </Card.Body>
      </Card>
    </>
  );
};

export default CategoryForm;
