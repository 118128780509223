import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext"; // Adjust the path to where your AuthContext is
import { Layout } from "../components";
import { usePermissions } from "../hooks/usePermission";
import Loader from "components/Loader";

const withLayout = (Component) => {
  return (props) => (
    <Layout>
      <Component {...props} />
    </Layout>
  );
};

const RouteWithLayout = ({ component: Component, permissions, ...rest }) => {
  const { user, loading, login } = useAuth();
  const ComponentWithLayout = withLayout(Component);
  const hasPermissions = usePermissions(permissions);

  useEffect(() => {
    if (!loading && !user) {
      login();
    }
  }, [loading, user, login]);

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return hasPermissions ? (
    <ComponentWithLayout {...rest} />
  ) : (
    <Navigate to="/unauthorized" />
  );
};

export default RouteWithLayout;
