import Highlight from "components/HighlightText";
import OrgSingleView from "../../Tenants/SingleOrgView";

import { useState } from "react";
const {
  Accordion,
  Badge,
  ListGroup,
  Card,
  Col,
  Row,
} = require("react-bootstrap");

const OrganisationResult = ({ organisations, sendLog, query }) => {
  const [selectedOrg, setSelectedOrg] = useState(null);

  return (
    <Accordion.Item eventKey="organisations">
      <Accordion.Header>
        Tenants :
        <Badge pill bg="info">
          {organisations?.length}
        </Badge>
      </Accordion.Header>
      <Accordion.Body>
        <Row>
          {organisations?.map((org) => (
            <Col sm={3} className="mb-3">
              <Card>
                <Card.Body className="p-0">
                  <ListGroup variant="flush">
                    <ListGroup.Item className="mb-1">
                      <span>Name</span>

                      <b
                        className="link"
                        onClick={() => {
                          sendLog("org-click", org?.name);
                          setSelectedOrg(org);
                        }}
                      >
                        <Highlight text={org?.name} highlight={query} />
                      </b>
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Accordion.Body>
      <OrgSingleView
        organisation={selectedOrg}
        removeOrg={setSelectedOrg}
        showModal={selectedOrg?._id}
        closeModal={() => setSelectedOrg(null)}
      />
    </Accordion.Item>
  );
};

export default OrganisationResult;
