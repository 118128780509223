import React from "react";
import { ProgressBar } from "react-bootstrap";
import { percentageVariant } from "utils/percentageVariant";

const DiskUsageSection = ({ diskUsageData = [] }) => {
  return (
    <div className="mt-2">
      {diskUsageData.map((disk, index) => (
        <div key={index} className="mb-3">
          <p className="mb-2">
            Partition: <strong>{disk.partition}</strong> | Total:{" "}
            <strong>{disk.size}</strong> | Free: <strong>{disk.free}</strong>
          </p>
          <ProgressBar
            now={disk.usedRatio}
            label={`${disk.usedRatio.toFixed(1)} %`}
            variant={percentageVariant(disk.usedRatio)}
          />
        </div>
      ))}
    </div>
  );
};

export default DiskUsageSection;
