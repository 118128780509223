import React, { useMemo } from "react";

import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import { Card, Tabs } from "react-bootstrap";
import { Tab } from "bootstrap";

const UsersView = ({ tenantUsers = [], adminUsers = [] }) => {
  const allTenantUsers = useMemo(() => {
    return tenantUsers.map((user) => {
      return { ...user, twoFactor: user.twoFactor ? "success" : "failed" };
    });
  }, [tenantUsers]);

  const allAdminUsers = useMemo(() => {
    return adminUsers.map((user) => {
      return { ...user, twoFactor: user.twoFactor ? "success" : "failed" };
    });
  }, [adminUsers]);

  return (
    <>
      <div className="my-3">
        <h5>
          <b>Users</b>
        </h5>
        <small className="sub-info">List of all Users</small>
      </div>
      <Card>
        <Card.Body>
          <Tabs
            defaultActiveKey="tenant"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab
              eventKey="tenant"
              title={`Tenant Users(${tenantUsers.length})`}
            >
              <PaginatedTable
                data={allTenantUsers}
                columns={COLUMNS["headendUsers"]}
                itemsPerPage={10}
              />
            </Tab>
            <Tab eventKey="admin" title={`Admin Users(${adminUsers.length})`}>
              <PaginatedTable
                data={allAdminUsers}
                columns={COLUMNS["headendUsers"]}
                itemsPerPage={10}
              />
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};

export default UsersView;
