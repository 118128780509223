import React from "react";
import { ListGroup } from "react-bootstrap";

const LoginActivities = ({ loginsToday, loading }) => (
  <div>
    <div className="mt-2 mb-3">
      <h5>
        <b>Users Logged in Today</b>
      </h5>
    </div>
    {!loading && (
      <ListGroup>
        {loginsToday?.map((data) => (
          <ListGroup.Item key={data?._id}>
            {data?.user}:{data?.createdAt}
          </ListGroup.Item>
        ))}
      </ListGroup>
    )}
  </div>
);

export default LoginActivities;
