// reducers/hostSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { fetchDC, fetchDCs } from "../actions";

const dataCenterSlice = createSlice({
  name: "dataCenter",
  initialState: {
    dataCenters: [],
    dataCenter: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDCs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDCs.fulfilled, (state, action) => {
        state.loading = false;
        state.dataCenters = action.payload;
      })
      .addCase(fetchDCs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchDC.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDC.fulfilled, (state, action) => {
        state.loading = false;
        state.dataCenter = action.payload;
      })
      .addCase(fetchDC.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default dataCenterSlice.reducer;
