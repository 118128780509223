import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHeadends } from "stores/actions";
import { useNavigate } from "react-router-dom";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import { usePermissions } from "hooks/usePermission";
import useActivityLogger from "hooks/useActivity";

const HeadendList = ({ redirectToManage, isSearch = false }) => {
  const { headends, loading } = useSelector((state) => state.headend);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logActivity = useActivityLogger();

  useEffect(() => {
    if (!isSearch) dispatch(fetchHeadends());
  }, [isSearch]);

  const headendData = useMemo(() => {
    return headends.map((head) => {
      return {
        id: head?._id,
        name: `${head?.code || ""}-${head?.name}`,
        // code: head?.code,
        infra: head?.infra?.name,
        category: head?.category?.name,
        fqdn: head?.fqdn,
        active: head?.active ? "Yes" : "No",
      };
    });
  }, [headends]);

  const onView = (item) => {
    logActivity({
      page: "dashboard",
      activity: "view-headend",
      name: item?.name,
    });
    navigate(`/headends/${item.id}`);
  };
  const onEdit = (item) => {
    redirectToManage("edit-headend", item);
  };

  return (
    <div className="mt-2">
      <PaginatedTable
        data={headendData}
        columns={COLUMNS["headend"]}
        perPage={20}
        onView={usePermissions(["HEADENDS_VIEW"]) && onView}
        onEdit={usePermissions(["HEADENDS_EDIT"]) && onEdit}
        isLoading={loading["fetchHeadends"]}
      />
    </div>
  );
};

export default HeadendList;
