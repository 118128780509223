import React, { useMemo, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import BackupAuditSingleView from "../components/BackupAuditSingleView";
import useActivityLogger from "hooks/useActivity";
import BackupStatusCard from "../components/BackupStatusCard";

const BackupAuditReport = () => {
  const logActivity = useActivityLogger();
  const { backupReport } = useSelector((state) => state.audit);
  const [data, setData] = useState({});
  const [dataKey, setDataKey] = useState("director");
  const onView = (item) => {
    logActivity({
      page: "monitor-audit",
      activity: "click-view",
      item: item?.name,
    });
    setDataKey("director");
    setData(item);
  };

  const cardData = useMemo(() => {
    if (!backupReport || Object.keys(backupReport).length === 0) {
      return [];
    }

    return Object.keys(backupReport).flatMap((key) => backupReport[key]);
  }, [backupReport]);

  return (
    <Row>
      <Col>
        <Row>
          <Col md={9}>
            <div className="mb-3">
              <h4 className="">
                <b>Audit Reports</b>
              </h4>
              <small className="sub-info">
                Categorised backup audit details
              </small>
            </div>
          </Col>
          <Col>
            <BackupStatusCard data={cardData} />
          </Col>
        </Row>
        <Card className="mb-3">
          <Card.Body>
            <BackupAuditSingleView
              data={backupReport}
              dataKey={dataKey}
              setDataKey={setDataKey}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default BackupAuditReport;
