import { createSlice } from "@reduxjs/toolkit";
import {
  fetchPages,
  fetchPermissions,
  fetchRoles,
  fetchUsers,
  fetchUser,
  register,
} from "../actions";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    users: [],
    roles: [],
    pages: [],
    permissions: [],
    currentUser: {},
    loading: {
      fetchUser: false,
      fetchUsers: false,
      fetchRoles: false,
      fetchPages: false,
      fetchPermissions: false,
    },
  },
  reducers: {
    login: (state) => {
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.isAuthenticated = false;
    },
    setCurrentUser: (state, action) => {
      state.currentuser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.loading["register"] = true;
        state.error = null;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading["register"] = false;
        state.currentUser = action.payload;
        localStorage.setItem("user", JSON.stringify(action.payload));
      })
      .addCase(register.rejected, (state, action) => {
        state.loading["register"] = false;
        state.error = action.payload;
      })
      .addCase(fetchUsers.pending, (state) => {
        state.loading["fetchUsers"] = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading["fetchUsers"] = false;
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading["fetchUsers"] = false;
        state.error = action.payload;
      })
      .addCase(fetchUser.pending, (state) => {
        state.loading["fetchUser"] = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.loading["fetchUser"] = false;
        state.currentUser = action.payload[0];
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading["fetchUser"] = false;
        state.error = action.payload;
      })
      .addCase(fetchRoles.pending, (state) => {
        state.loading["fetchRoles"] = true;
        state.error = null;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.loading["fetchRoles"] = false;
        state.roles = action.payload;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.loading["fetchRoles"] = false;
        state.error = action.payload;
      })
      .addCase(fetchPages.pending, (state) => {
        state.loading["fetchPages"] = true;
        state.error = null;
      })
      .addCase(fetchPages.fulfilled, (state, action) => {
        state.loading["fetchRoles"] = false;
        state.pages = action.payload;
      })
      .addCase(fetchPages.rejected, (state, action) => {
        state.loading["fetchPages"] = false;
        state.error = action.payload;
      })
      .addCase(fetchPermissions.pending, (state) => {
        state.loading["fetchPermissions"] = true;
        state.error = null;
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        state.loading["fetchPermissions"] = false;
        state.permissions = action.payload;
      })
      .addCase(fetchPermissions.rejected, (state, action) => {
        state.loading["fetchPermissions"] = false;
        state.error = action.payload;
      });
  },
});

export const { login, logout, setCurrentUser } = authSlice.actions;

export default authSlice.reducer;
