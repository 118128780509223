import { Tab } from "bootstrap";
import { PaginatedTable } from "components";
import COLUMNS from "constants/columns";
import { useState } from "react";
import { Card, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";

const DetailedView = ({ headends, tenants, users, subscriptions }) => {
  const { orchestrator } = useSelector((state) => state.orchestrators);
  const [key, setKey] = useState("tenants");
  const renderTabContent = (title, data, columns) => (
    <>
      <div className="mb-3">
        <h5>
          <b>{title}</b>
        </h5>
        <small className="sub-info">{`Lists all ${title.toLowerCase()} managed by the orchestrator`}</small>
      </div>
      {data?.length > 0 ? (
        <PaginatedTable
          data={data}
          columns={COLUMNS[columns]}
          itemsPerPage={10}
        />
      ) : (
        <center>No {title} found</center>
      )}
    </>
  );
  return (
    <Card>
      <Card.Body>
        <Tabs
          id="controlled-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="p-0"
        >
          <Tab eventKey="tenants" title="Tenants" className="p-3">
            {renderTabContent("Tenants", tenants, "tenants")}
          </Tab>
          <Tab eventKey="headends" title="Headends" className="p-3">
            {renderTabContent("Headends", headends, "headend")}
          </Tab>
          <Tab eventKey="users" title="Users" className="p-3">
            {renderTabContent("Users", users, "orchUsers")}
          </Tab>
          <Tab eventKey="subscription" title="Subscriptions" className="p-3">
            {renderTabContent(
              "Subscriptions",
              subscriptions || [],
              "subscriptions"
            )}
          </Tab>
          <Tab eventKey="nodes" title="Nodes" className="p-3">
            <div className="mb-3">
              <h5>
                <b>Nodes</b>
              </h5>
              <small className="sub-info">
                List of all nodes under the Orchestrator
              </small>
            </div>
            {orchestrator?.nodes?.map((node) => (
              <Card className="mb-3" key={node.fqdn}>
                <Card.Header>{node.fqdn}</Card.Header>
                <Card.Body>
                  <PaginatedTable
                    data={node.hosts.map((host) => ({
                      name: host.name,
                      fqdn: host.fqdn,
                      region: host.location,
                      host,
                    }))}
                    columns={COLUMNS["orchestratorHost"]}
                    perPage={25}
                    paginationRequired={false}
                    searchRequired={false}
                    filterRequired={false}
                  />
                </Card.Body>
              </Card>
            ))}
          </Tab>
        </Tabs>
      </Card.Body>
    </Card>
  );
};

export default DetailedView;
