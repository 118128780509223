import { WebStorageStateStore } from "oidc-client";
import { Log } from "oidc-client";

const {
  REACT_APP_OAUTH_CLIENT_ID,
  REACT_APP_DASHBOARD_URL,
  REACT_APP_OAUTH_SCOPE,
  REACT_APP_AUTH_URL,
} = window?.appConfig;

Log.logger = console;
Log.level = Log.DEBUG;

const userConfigDetails = {
  userStore: new WebStorageStateStore({ store: localStorage }),
  client_id: REACT_APP_OAUTH_CLIENT_ID,
  post_logout_redirect_uri: `${REACT_APP_DASHBOARD_URL}/login`,
  redirect_uri: `${REACT_APP_DASHBOARD_URL}/callback`,
  silent_redirect_uri: `${REACT_APP_DASHBOARD_URL}/silent-renew`,
  silentRequestTimeout: 20000, // Increased timeout to 20 seconds
  response_type: "code",
  scope: `${REACT_APP_OAUTH_SCOPE}`,
  authority: REACT_APP_AUTH_URL,
  automaticSilentRenew: true,
  accessTokenExpiringNotificationTime: 60,
  filterProtocolClaims: true,
  loadUserInfo: true,
  revokeTokensOnSignout: true,
  revokeTokenTypes: ["access_token", "refresh_token"],

  // Enable debug logging to get more details on silent renew behavior
  logger: console, // Only if the OIDC library supports it, e.g., oidc-client
};

// Optional: Enable debug level logs if you are using oidc-client
if (window.Log) {
  window.Log.setLevel(window.Log.DEBUG);
  window.Log.logger = console;
}

export default userConfigDetails;
