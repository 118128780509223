import React from "react";
import {
  Card,
  Col,
  ListGroup,
  OverlayTrigger,
  Popover,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";

const MonitorHeadendView = ({ data, dataKey, setDataKey }) => {
  // General component to render each Tab with its respective data
  const renderTabContent = (tabKey, title, dataKey, description) => (
    <Tab eventKey={tabKey} title={title}>
      <Row>
        {data?.[dataKey] &&
          data?.[dataKey].map((tabData) => (
            <Col md={4}>
              <OverlayTrigger
                trigger="click"
                rootClose
                placement="auto"
                overlay={
                  <Popover
                    id="popover-trigger-click-root-close"
                    title="Popover"
                    style={{ minWidth: "25vw", padding: "10px" }}
                  >
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <span>Name</span>

                        <b>{tabData?.name}</b>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <span>Frequency</span>

                        <b>{tabData?.frequency}</b>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <span>Script</span>

                        <b>{tabData?.script}</b>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <p>Parameter Monitored</p>

                        <ul className="m-0 p-0">
                          {tabData.parameters_monitored.map((param, idx) => (
                            <li key={idx} className="">
                              <strong>{param.parameter}</strong> - Threshold:{" "}
                              {param.threshold} ({param.monitoring_type})
                            </li>
                          ))}
                        </ul>

                        {/* <b>{tabData?.script}</b> */}
                      </ListGroup.Item>
                    </ListGroup>
                  </Popover>
                }
              >
                <Card style={{ cursor: "pointer" }} className="mb-2 hover-item">
                  <Card.Body className="link p-0">
                    <p className="ellipsis m-2 p-2">{tabData?.name}</p>
                  </Card.Body>
                </Card>
              </OverlayTrigger>
            </Col>
          ))}
      </Row>
    </Tab>
  );

  return (
    <>
      <Tabs
        id="dashboard-tabs"
        activeKey={dataKey}
        onSelect={(k) => setDataKey(k)}
        className="mb-3"
      >
        {data?.director &&
          renderTabContent(
            "director",
            "Director",
            "director",
            "All audit related to Director monitoring"
          )}
        {data?.controller &&
          renderTabContent(
            "controller",
            "Controller",
            "controller",
            "All audit related to Controller monitoring"
          )}
        {data?.vms &&
          renderTabContent(
            "vms",
            "VMS",
            "vms",
            "All audit related to VMS monitoring"
          )}
        {data?.analytics &&
          renderTabContent(
            "analytics",
            "Analytics",
            "analytics",
            "All audit related to Analytics monitoring"
          )}
      </Tabs>
    </>
  );
};

export default MonitorHeadendView;
