// pages/Unauthorized.js
import useActivityLogger from "hooks/useActivity";
import React, { useEffect } from "react";
import { Card } from "react-bootstrap";

const Unauthorized = () => {
  const logActivity = useActivityLogger();

  useEffect(() => {
    logActivity({
      page: "not-found",
      activity: "browse-different-url",
    });
  }, []);

  return (
    <div
      className="bg-image"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Card className="p-4 w-50">
        <Card.Body>
          <h1>404 Not found</h1>
          <p>You seems lost, please check the URL.</p>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Unauthorized;
