import React, { useEffect, useMemo } from "react";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useBreadcrumbs, useAuth } from "contexts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faBell,
  faCircleUser,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import useActivityLogger from "hooks/useActivity";

const TopNavbar = ({ onToggleMenu, isMenuCollapsed }) => {
  const { user, logout } = useAuth();
  const { breadcrumbs } = useBreadcrumbs();
  const location = useLocation();
  const logActivity = useActivityLogger();

  const sendLog = (activity, name) => {
    logActivity({
      page: "navigation",
      activity,
      name,
    });
  };

  const locationName = useMemo(
    () => location.pathname.split("/")[1],
    [location.pathname]
  );

  const userInfoIcon = (username) => {
    return (
      <>
        <FontAwesomeIcon
          icon={faCircleUser}
          className="link"
          style={{
            fontSize: "1rem",
            marginRight: "10px",
            verticalAlign: "sub",
          }}
        ></FontAwesomeIcon>
        Welcome, {username}
      </>
    );
  };

  return (
    <Navbar className="">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Button
            // variant="outline-primary"
            onClick={onToggleMenu}
            className="toggle-button"
          >
            <FontAwesomeIcon icon={faBars} />
            {/* {isMenuCollapsed ? "Expand Menu" : "Collapse Menu"} */}
          </Button>
          {breadcrumbs.length > 0 && (
            <Breadcrumb>
              {breadcrumbs.map((crumb, index) => (
                <Breadcrumb.Item key={index} href={crumb.url}>
                  {crumb.text}
                </Breadcrumb.Item>
              ))}
              &nbsp;&nbsp;{"/"}
            </Breadcrumb>
          )}
          <h5 className="ml-2">{locationName}</h5>
        </Nav>

        <Nav className="ms-auto">
          <NavDropdown
            title={userInfoIcon(user?.profile?.name)}
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item
              href="/profile"
              onClick={() => sendLog("menu-click", "profile")}
            >
              Profile
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
              onClick={() => {
                sendLog("menu-click", "logout");
                logout();
              }}
            >
              Logout
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default TopNavbar;
