import Highlight from "components/HighlightText";
import IspSingleView from "../../Isps/SingleIspView";
import { useState } from "react";
const {
  Accordion,
  Badge,
  ListGroup,
  Card,
  Col,
  Row,
} = require("react-bootstrap");

const IspResult = ({ isps, sendLog, query }) => {
  const [selectedIsp, setSelectedIsp] = useState(null);
  const [showModal, setShowmodal] = useState(false);
  return (
    <Accordion.Item eventKey="isps">
      <Accordion.Header>
        ISP :
        <Badge pill bg="info">
          {isps?.length}
        </Badge>
      </Accordion.Header>
      <Accordion.Body>
        <Row>
          {isps?.map((isp) => (
            <Col sm={3} className="mb-3">
              <Card>
                <Card.Body className="p-0">
                  <ListGroup variant="flush">
                    <ListGroup.Item className="mb-1">
                      <span>Site Name</span>

                      <b
                        className="link"
                        onClick={() => {
                          sendLog("isp-click", isp?.site_name);
                          setSelectedIsp(isp);
                          setShowmodal(true);
                        }}
                      >
                        <Highlight text={isp?.site_name} highlight={query} />
                      </b>
                    </ListGroup.Item>
                    <ListGroup.Item className="mb-1">
                      <span>DC Name</span>

                      <b>
                        <Highlight
                          text={isp?.data_center_name}
                          highlight={query}
                        />
                      </b>
                    </ListGroup.Item>
                    <ListGroup.Item className="mb-1">
                      <span>Arista Switch IP</span>

                      <b className="ellipsis text-uppercase">
                        <Highlight text={isp?.arista_sw_ip} highlight={query} />
                      </b>
                    </ListGroup.Item>
                    <ListGroup.Item className="mb-1">
                      <span>Arista Switch Name</span>

                      <b className="ellipsis text-uppercase">
                        <Highlight
                          text={isp?.arista_sw_name}
                          highlight={query}
                        />
                      </b>
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Accordion.Body>
      <IspSingleView
        isp={selectedIsp}
        removeHost={setSelectedIsp}
        showModal={showModal}
        closeModal={() => setShowmodal(false)}
      />
    </Accordion.Item>
  );
};

export default IspResult;
