import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const PageVisits = ({ pageVisits, loading }) => (
  <div>
    <div className="mt-2 mb-3">
      <h5>
        <b>Page Visits</b>
      </h5>
    </div>
    {!loading && (
      <BarChart width={600} height={300} data={pageVisits}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="_id" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="count" fill="#8884d8" />
      </BarChart>
    )}
  </div>
);

export default PageVisits;
