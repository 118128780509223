// AlertComponent.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "react-bootstrap";
import { hideAlert } from "../stores/slices/alertSlice";

const AlertComponent = () => {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (alert.show) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
        setTimeout(() => {
          dispatch(hideAlert());
        }, 500); // Match this with transition duration
      }, 3000); // Auto-close after 3 seconds

      return () => clearTimeout(timer); // Cleanup the timer on unmount or when alert changes
    }
  }, [alert, dispatch]);

  return (
    alert.show && (
      <Alert
        className={`bottom-right-alert ${
          visible ? "alert-visible" : "alert-hidden"
        }`}
        variant={alert.variant}
        onClose={() => {
          setVisible(false);
          setTimeout(() => {
            dispatch(hideAlert());
          }, 500); // Match this with transition duration
        }}
        dismissible
      >
        {alert.message}
      </Alert>
    )
  );
};

export default AlertComponent;
