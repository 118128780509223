import React, { useEffect, useMemo, useState } from "react";
import createOrgTree from "utils/createOrgTree";
import SingleView from "pages/Tenants/SingleOrgView";
import { useDispatch, useSelector } from "react-redux";
import { fetchTenantSummary } from "stores/actions";
import { ButtonGroup, Col, Row, Button, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolderTree,
  faTable,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { PaginatedTable } from "components";
import COLUMNS from "constants/columns";

const OrganisationView = ({ tenants }) => {
  const dispatch = useDispatch();
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedView, setSelectedView] = useState("tree");
  const [showViewModal, setShowViewModal] = useState(false);
  const [expandedOrgs, setExpandedOrgs] = useState({});
  const { orgSummary } = useSelector((state) => state.organisation);

  const allOrganisations = useMemo(() => {
    return createOrgTree(tenants);
  }, [tenants]);

  const tenantsData = useMemo(() => {
    return tenants
      .filter((org) => org?.name !== "none" || org?.name !== undefined)
      .map((org) => {
        return {
          id: org._id,
          name: org?.name,
          parentOrg: org?.parentOrg || "",
          contacts: org?.contacts?.length || "",
          plan: org?.plan,
          headends: org?.headends
            ?.map((headend) => headend?.code || headend?.name)
            .join(", "),
        };
      });
  }, [tenants]);

  useEffect(() => {
    if (selectedOrg?._id) dispatch(fetchTenantSummary(selectedOrg?._id));
  }, [selectedOrg]);

  const onView = (organisation) => {
    setSelectedOrg(organisation);
    setShowViewModal(true);
  };

  const toggleExpandCollapse = (orgId) => {
    setExpandedOrgs((prevState) => ({
      ...prevState,
      [orgId]: !prevState[orgId],
    }));
  };

  const renderOrganisation = (organisation, orgIndex) => {
    const hasSubtenants = organisation.tenants?.length > 0;
    const isExpanded = expandedOrgs[organisation._id];

    return (
      <div key={orgIndex} className="tree-card">
        <div className="tree-body">
          <label className="link">
            {hasSubtenants && (
              <FontAwesomeIcon
                icon={isExpanded ? faChevronDown : faChevronRight}
                onClick={() => toggleExpandCollapse(organisation._id)}
                className="mr-2"
                style={{ marginRight: "10px" }}
              />
            )}
            <b onClick={() => onView(organisation)}>{organisation.name}</b>
            {hasSubtenants && (
              <Badge pill bg="info" className="mx-2">
                {organisation.tenants?.length}
              </Badge>
            )}
          </label>
          {isExpanded &&
            hasSubtenants &&
            organisation.tenants.map((tenant, tenantIndex) => (
              <div key={tenantIndex} className="tree-node ml-4">
                {renderOrganisation(
                  tenant,
                  `${orgIndex}.tenants[${tenantIndex}]`
                )}
              </div>
            ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <Row>
        <Col>
          <div className="my-3">
            <h5>
              <b>Tenants</b>
            </h5>
            <small className="sub-info">
              Complete tenants under the headend
            </small>
          </div>
        </Col>
        <Col className="col-auto">
          <ButtonGroup>
            <Button
              variant={selectedView === "tree" ? "primary" : " "}
              onClick={() => setSelectedView("tree")}
            >
              <FontAwesomeIcon icon={faFolderTree} />
            </Button>
            <Button
              variant={selectedView === "table" ? "primary" : " "}
              onClick={() => setSelectedView("table")}
            >
              <FontAwesomeIcon icon={faTable} />
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      {selectedView === "tree" ? (
        allOrganisations?.map((organisation, orgIndex) => (
          <div key={orgIndex} className="mb-3">
            {renderOrganisation(organisation, orgIndex)}
          </div>
        ))
      ) : (
        <>
          <PaginatedTable
            data={tenantsData}
            columns={COLUMNS["orgs"]}
            perPage={10}
            onView={onView}
          />
        </>
      )}
      <SingleView
        organisation={selectedOrg}
        removeOrg={setSelectedOrg}
        summary={orgSummary}
        showModal={showViewModal}
        closeModal={() => setShowViewModal(false)}
      />
    </>
  );
};

export default OrganisationView;
