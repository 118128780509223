import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { deleteCategory } from "stores/actions";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import { CATEGORIES_OPTIONS } from "constants/selectOptions";

const CategoryList = ({ categories, type, searchTerm }) => {
  const dispatch = useDispatch();

  const filteredCategories = useMemo(() => {
    return (
      categories?.filter((category) =>
        category?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
      ) || []
    );
  }, [categories, searchTerm]);

  const deleteConfirmation = (item) => {
    console.log("Delete", item._id);
    if (window.confirm("Are you sure?")) dispatch(deleteCategory(item._id));
  };

  const selectedCategory = useMemo(() => {
    return CATEGORIES_OPTIONS.find((cat) => cat.value === type);
  }, [type]);

  return (
    <div>
      <div className="mb-3">
        <h5>
          <b>{selectedCategory?.name}</b>
        </h5>
        <small className="sub-info">
          List all {selectedCategory?.name} and their details
        </small>
      </div>
      <PaginatedTable
        data={filteredCategories}
        columns={COLUMNS["category"]}
        itemsPerPage={10}
        onDelete={deleteConfirmation}
      />
    </div>
  );
};

export default CategoryList;
