import { faComments } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { sendFeedback } from "stores/actions";

const Feedback = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [sending, setSending] = useState(false);
  const [page, setPage] = useState("");
  useEffect(() => {
    setPage(localStorage.getItem("page"));
  }, [show]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleSubmit = async () => {
    // Handle the feedback submission logic
    const user = JSON.parse(localStorage.getItem("user"));

    if (feedback.length > 0) {
      await dispatch(
        sendFeedback({
          page,
          feedback,
          email: user?.email,
          name: user?.name,
          pageUrl: window.location.href,
        })
      );
      setFeedback("");
      handleClose();
    }
  };

  return (
    <>
      <Button
        variant="primary"
        className="floating-button"
        onClick={handleShow}
      >
        Feedback <FontAwesomeIcon icon={faComments} />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Feedback for {page}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="feedbackForm.ControlTextarea">
              <Form.Label>Your Feedback</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={feedback}
                onChange={handleFeedbackChange}
                placeholder="Your feedback..."
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={sending}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Feedback;
