import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import React from "react";

try {
  autoTable(jsPDF);
} catch (error) {}

const extractText = (value) => {
  if (typeof value === "string" || typeof value === "number") {
    return value;
  } else if (Array.isArray(value)) {
    return value.map(extractText).join(" ");
  } else if (React.isValidElement(value)) {
    return extractText(value.props.children);
  }
  return "";
};

const downloadPDF = (columns, data, fileName) => {
  const doc = new jsPDF();

  // Prepare table headers and body
  const tableHeaders = [columns.map((col) => col.text)];
  const tableBody = data.map((item) =>
    columns.map((col) => extractText(item[col.dataField]))
  );

  // Generate PDF using autoTable
  doc.autoTable({
    head: tableHeaders,
    body: tableBody,
  });

  // Save the PDF with the provided file name
  doc.save(`${fileName}.pdf`);
};

const convertToCSV = (columns, data) => {
  // Prepare CSV headers
  const csvHeaders = columns.map((col) => col.text).join(",");

  // Prepare CSV body by extracting text from each data field
  const csvBody = data
    .map((item) =>
      columns.map((col) => `"${extractText(item[col.dataField])}"`).join(",")
    )
    .join("\n");

  // Combine headers and body
  return `${csvHeaders}\n${csvBody}`;
};

const downloadCSV = (columns, data, fileName) => {
  const csv = convertToCSV(columns, data);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.setAttribute("download", `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const convertToText = (columns, data) => {
  // Prepare headers
  const textHeaders = columns.map((col) => col.text).join("\t");

  // Prepare body by extracting text from each data field
  const textBody = data
    .map((item) =>
      columns.map((col) => extractText(item[col.dataField])).join("\t")
    )
    .join("\n");

  // Combine headers and body
  return `${textHeaders}\n${textBody}`;
};

const downloadText = (columns, data, fileName) => {
  const textData = convertToText(columns, data);
  const blob = new Blob([textData], { type: "text/plain;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.setAttribute("download", `${fileName}.txt`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export { downloadCSV, downloadPDF, downloadText };
