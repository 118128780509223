import Highlight from "components/HighlightText";

const {
  Accordion,
  Badge,
  ListGroup,
  Card,
  Col,
  Row,
} = require("react-bootstrap");

const UsersResult = ({ users, sendLog, query }) => {
  return (
    <Accordion.Item eventKey="orchestrators">
      <Accordion.Header>
        User :
        <Badge pill bg="info">
          {users?.length}
        </Badge>
      </Accordion.Header>
      <Accordion.Body>
        <Row>
          {users?.map((user) => (
            <Col sm={3} className="mb-3">
              <Card>
                <Card.Body className="p-0">
                  <ListGroup variant="flush">
                    <ListGroup.Item className="mb-1">
                      <span>Name</span>

                      <b className="link">
                        <Highlight text={user.user?.name} highlight={query} />
                      </b>
                    </ListGroup.Item>
                    <ListGroup.Item className="mb-1">
                      <span>Headend</span>

                      <b>
                        <Highlight
                          text={`${user.headendCode}-${user.headendName}`}
                          highlight={query}
                        />
                      </b>
                    </ListGroup.Item>
                    <ListGroup.Item className="mb-1">
                      <span>Role</span>

                      <b>
                        <Highlight text={user.user.role} highlight={query} />
                      </b>
                    </ListGroup.Item>
                    <ListGroup.Item className="mb-1">
                      <span>Tenant</span>

                      <b>
                        <Highlight text={user.user.tenant} highlight={query} />
                      </b>
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default UsersResult;
