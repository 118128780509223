import React, { useCallback, useState } from "react";

import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import SingleView from "pages/Host/SingleHostView";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";

const AnalyticsView = ({ analytics = [] }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedAnalytics, setSelectedAnalytics] = useState(null);
  const { regions } = useSelector((state) => state.dashboard);

  const getAnalyticsNodes = useCallback(
    (analytic) => {
      const newData = [];

      analytic?.nodes?.forEach((aData) => {
        let data = {
          ...aData,
          name: aData.name,
          analyticsName: analytic.name,
          region: regions.find((region) => region._id === analytic?.region)
            ?.name,
          id: aData?._id,
          cluster: aData?.analyticName,
          active: aData?.active ? "Yes" : "No",
        };
        newData.push(data);
      });

      return newData;
    },
    [analytics]
  );

  const onView = (item) => {
    setSelectedAnalytics(item);
    setShowModal(true);
  };
  return (
    <>
      <div className="my-3">
        <h5>
          <b>Analytics</b>
        </h5>
        <small className="sub-info">List of all Analytics nodes</small>
      </div>
      {analytics?.map((analytic) => (
        <Card key={analytic?.name}>
          <Card.Header>{analytic?.name}</Card.Header>
          <Card.Body>
            <PaginatedTable
              data={getAnalyticsNodes(analytic)}
              columns={COLUMNS["analytics"]}
              itemsPerPage={10}
              onView={onView}
            />
          </Card.Body>
        </Card>
      ))}

      <SingleView
        host={selectedAnalytics}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      />
    </>
  );
};

export default AnalyticsView;
