const Loader = ({ content = "" }) => {
  return (
    <div>
      <center>
        <span className="loader"></span>
        {content && (
          <span className="loader-content">
            {"   "}
            {content}
          </span>
        )}
      </center>
    </div>
  );
};

export default Loader;
