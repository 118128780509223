const CATEGORIES_OPTIONS = [
  {
    name: "Headend Category",
    value: "headend",
  },
  {
    name: "Headend Infra Type",
    value: "infra",
  },
  {
    name: "Headend Environment",
    value: "environment",
  },
  {
    name: "Orchestrators",
    value: "orchestrator",
  },
  {
    name: "Appliance Category",
    value: "appliance",
  },
  {
    name: "Host Category",
    value: "host",
  },
  {
    name: "Analytics Category",
    value: "analytics",
  },
];

const CONTACT_OPTIONS = [
  {
    label: "Email",
    value: "emails",
  },
  {
    label: "Phone",
    value: "phone",
  },
  {
    label: "Website",
    value: "website",
  },
];

export { CATEGORIES_OPTIONS, CONTACT_OPTIONS };
