import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "services/api";

const createTenant = createAsyncThunk(
  "tenants/createTenant",
  async (tenantData, { rejectWithValue }) => {
    try {
      const response = await API.post("/tenants", tenantData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchTenants = createAsyncThunk(
  "tenants/fetchTenants",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/tenants`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchTenantById = createAsyncThunk(
  "tenants/fetchTenantById",
  async (tenantId, { rejectWithValue }) => {
    try {
      const response = await API.get(`/tenants/${tenantId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchTenantSummary = createAsyncThunk(
  "tenants/fetchTenantSummary",
  async (id, { rejectWithValue }) => {
    try {
      const response = await API.get(`/tenants/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export { createTenant, fetchTenants, fetchTenantById, fetchTenantSummary };
