import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "services/api";

const saveActivity = createAsyncThunk(
  "user-activities/saveActivity",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`/user-activities`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchLoggedInToday = createAsyncThunk(
  "user-activities/loginsToday",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/user-activities/logins-today`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchUniqueUsers = createAsyncThunk(
  "user-activities/unique-users",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/user-activities/unique-users`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchActivityFrequency = createAsyncThunk(
  "user-activities/activity-frequency",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/user-activities/activity-frequency`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchLoginFrequency = createAsyncThunk(
  "user-activities/login-frequency",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/user-activities/login-frequency`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchNavigationFlow = createAsyncThunk(
  "user-activities/navigation-flow",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/user-activities/navigation-flow`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchPageVisit = createAsyncThunk(
  "user-activities/page-visits",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/user-activities/page-visits`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchUserActions = createAsyncThunk(
  "user-activities/user-actions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/user-activities/user-actions`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchBrowserUsage = createAsyncThunk(
  "user-activities/browser-usage",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/user-activities/browser-usage`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchLanguageUsage = createAsyncThunk(
  "user-activities/language-usage",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/user-activities/language-usage`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchPageActivity = createAsyncThunk(
  "user-activities/page-activity",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/user-activities/page-activity`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export {
  saveActivity,
  fetchLoggedInToday,
  fetchUniqueUsers,
  fetchActivityFrequency,
  fetchLoginFrequency,
  fetchNavigationFlow,
  fetchPageVisit,
  fetchUserActions,
  fetchBrowserUsage,
  fetchLanguageUsage,
  fetchPageActivity,
};
