// reducers/hostSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchLoggedInToday,
  fetchUniqueUsers,
  fetchActivityFrequency,
  fetchLoginFrequency,
  fetchNavigationFlow,
  fetchPageVisit,
  fetchUserActions,
  fetchBrowserUsage,
  fetchLanguageUsage,
  fetchPageActivity,
} from "../actions";

const userActivitiesSlice = createSlice({
  name: "userActivities",
  initialState: {
    loginsToday: [],
    uniqueUsers: [],
    activityFrequency: [],
    loginFrequency: [],
    navigationFlow: [],
    pageVisits: [],
    userActions: [],
    browserUsage: [],
    languageUsage: [],
    pageActivity: [],
    loading: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoggedInToday.pending, (state) => {
        state.loading["fetchLoggedInToday"] = true;
        state.error = null;
      })
      .addCase(fetchLoggedInToday.fulfilled, (state, action) => {
        state.loading["fetchLoggedInToday"] = false;
        state.loginsToday = action.payload;
      })
      .addCase(fetchLoggedInToday.rejected, (state, action) => {
        state.loading["fetchLoggedInToday"] = false;
        state.error = action.payload;
      })

      .addCase(fetchUniqueUsers.pending, (state) => {
        state.loading["fetchUniqueUsers"] = true;
        state.error = null;
      })
      .addCase(fetchUniqueUsers.fulfilled, (state, action) => {
        state.loading["fetchUniqueUsers"] = false;
        state.uniqueUsers = action.payload;
      })
      .addCase(fetchUniqueUsers.rejected, (state, action) => {
        state.loading["fetchUniqueUsers"] = false;
        state.error = action.payload;
      })

      .addCase(fetchActivityFrequency.pending, (state) => {
        state.loading["fetchActivityFrequency"] = true;
        state.error = null;
      })
      .addCase(fetchActivityFrequency.fulfilled, (state, action) => {
        state.loading["fetchActivityFrequency"] = false;
        state.activityFrequency = action.payload;
      })
      .addCase(fetchActivityFrequency.rejected, (state, action) => {
        state.loading["fetchActivityFrequency"] = false;
        state.error = action.payload;
      })

      .addCase(fetchLoginFrequency.pending, (state) => {
        state.loading["fetchLoginFrequency"] = true;
        state.error = null;
      })
      .addCase(fetchLoginFrequency.fulfilled, (state, action) => {
        state.loading["fetchLoginFrequency"] = false;
        state.loginFrequency = action.payload;
      })
      .addCase(fetchLoginFrequency.rejected, (state, action) => {
        state.loading["fetchLoginFrequency"] = false;
        state.error = action.payload;
      })

      .addCase(fetchNavigationFlow.pending, (state) => {
        state.loading["fetchNavigationFlow"] = true;
        state.error = null;
      })
      .addCase(fetchNavigationFlow.fulfilled, (state, action) => {
        state.loading["fetchNavigationFlow"] = false;
        state.navigationFlow = action.payload;
      })
      .addCase(fetchNavigationFlow.rejected, (state, action) => {
        state.loading["fetchNavigationFlow"] = false;
        state.error = action.payload;
      })

      .addCase(fetchPageVisit.pending, (state) => {
        state.loading["fetchPageVisit"] = true;
        state.error = null;
      })
      .addCase(fetchPageVisit.fulfilled, (state, action) => {
        state.loading["fetchPageVisit"] = false;
        state.pageVisits = action.payload;
      })
      .addCase(fetchPageVisit.rejected, (state, action) => {
        state.loading["fetchPageVisit"] = false;
        state.error = action.payload;
      })

      .addCase(fetchUserActions.pending, (state) => {
        state.loading["fetchUserActions"] = true;
        state.error = null;
      })
      .addCase(fetchUserActions.fulfilled, (state, action) => {
        state.loading["fetchUserActions"] = false;
        state.userActions = action.payload;
      })
      .addCase(fetchUserActions.rejected, (state, action) => {
        state.loading["fetchUserActions"] = false;
        state.error = action.payload;
      })

      .addCase(fetchBrowserUsage.pending, (state) => {
        state.loading["fetchBrowserUsage"] = true;
        state.error = null;
      })
      .addCase(fetchBrowserUsage.fulfilled, (state, action) => {
        state.loading["fetchBrowserUsage"] = false;
        state.browserUsage = action.payload;
      })
      .addCase(fetchBrowserUsage.rejected, (state, action) => {
        state.loading["fetchBrowserUsage"] = false;
        state.error = action.payload;
      })

      .addCase(fetchLanguageUsage.pending, (state) => {
        state.loading["fetchLanguageUsage"] = true;
        state.error = null;
      })
      .addCase(fetchLanguageUsage.fulfilled, (state, action) => {
        state.loading["fetchLanguageUsage"] = false;
        state.languageUsage = action.payload;
      })
      .addCase(fetchLanguageUsage.rejected, (state, action) => {
        state.loading["fetchLanguageUsage"] = false;
        state.error = action.payload;
      })

      .addCase(fetchPageActivity.pending, (state) => {
        state.loading["fetchPageActivity"] = true;
        state.error = null;
      })
      .addCase(fetchPageActivity.fulfilled, (state, action) => {
        state.loading["fetchPageActivity"] = false;
        state.pageActivity = action.payload;
      })
      .addCase(fetchPageActivity.rejected, (state, action) => {
        state.loading["fetchPageActivity"] = false;
        state.error = action.payload;
      });
  },
});

export const {} = userActivitiesSlice.actions;
export default userActivitiesSlice.reducer;
