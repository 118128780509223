import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import MonitorStatusCards from "./MonitorStatusCard";

const MonitorDashboard = ({ monitorAudit, showHeadend = true }) => {
  const [selectedHeadend, setSelectedHeadend] = useState(
    monitorAudit[0]?.headend
  ); // Default to the first headend

  // Handle headend selection change
  const handleHeadendChange = (event) => {
    setSelectedHeadend(event.target.value);
  };

  useEffect(() => {
    if (monitorAudit.length > 0) setSelectedHeadend(monitorAudit[0]?.headend);
  }, [monitorAudit]);

  // Get the selected headend data
  const selectedData = monitorAudit.find(
    (headend) => headend.headend === selectedHeadend
  );

  return (
    <>
      {showHeadend && (
        <Card className="mt-3 mb-3">
          <Card.Body className=" mb-0">
            <Form.Group controlId="headendSelect">
              <Form.Select
                value={selectedHeadend}
                onChange={handleHeadendChange}
              >
                {monitorAudit.map((headend, index) => (
                  <option key={index} value={headend.headend}>
                    {headend.headend}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Card.Body>
        </Card>
      )}
      {selectedData && (
        <Card>
          <Card.Body>
            <Row className="mt-0 p-0">
              <Col sm={12}>
                <Row>
                  <Col></Col>
                  <Col className="col-auto">
                    <b className="color-box red bg"></b>Critical
                    <b className="color-box orange bg"></b>Mismatch
                    <b className="color-box green bg"></b>Ok
                  </Col>
                </Row>
              </Col>
              <Col md={12} className="mb-3">
                <h6>Director</h6>
                <MonitorStatusCards
                  data={selectedData["director"]}
                  labelRequired={false}
                />
              </Col>
              <Col md={12} className="mb-3">
                <h6>Controller</h6>
                <MonitorStatusCards
                  data={selectedData["controller"]}
                  labelRequired={false}
                />
              </Col>
              <Col md={12} className="">
                <h6>Analytics</h6>
                <MonitorStatusCards
                  data={selectedData["analytics"]}
                  labelRequired={false}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default MonitorDashboard;
