// pages/PageManagement.js
import React, { useContext, useState } from "react";
import { useAuth } from "contexts";
import { createPage, updatePage, deletePage } from "stores/actions";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import PaginatedTable from "../../../components/PaginatedTable";
import COLUMNS from "../../../constants/columns";

const Pages = () => {
  const dispatch = useDispatch();
  const { pages, permissions } = useSelector((state) => state.auth);

  const [newPageName, setNewPageName] = useState("");
  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const handlePageChange = (item) => {
    // const pageId = event.target.value;
    const page = pages.find((page) => page._id === item._id);
    setSelectedPage(page);
    setSelectedPermissions(
      page ? page.permissions.map((perm) => perm._id) : []
    );
  };

  const handlePermissionChange = (event) => {
    const { value, checked } = event.target;
    setSelectedPermissions((prev) =>
      checked ? [...prev, value] : prev.filter((perm) => perm !== value)
    );
  };

  const handleCreate = async () => {
    const newPage = await dispatch(
      createPage({
        name: newPageName,
        permissions: selectedPermissions,
      })
    );
    // setPages([...pages, newPage]);
    setNewPageName("");
  };

  const handleSave = async () => {
    console.log("selected groiup", selectedPage);
    if (selectedPage) {
      await dispatch(
        updatePage({
          pageId: selectedPage._id,
          updatedPage: {
            permissions: selectedPermissions,
          },
        })
      );
      //   setPages(
      //     pages.map((page) =>
      //       page._id === selectedPage._id ? updated : page
      //     )
      //   );
    }
  };

  return (
    <Container>
      <div className="mb-3">
        <h5>
          <b>Page Management</b>
        </h5>
        <small className="sub-info">
          Manage all pages and related permission
        </small>
      </div>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <PaginatedTable
                columns={COLUMNS["roles"]}
                data={pages}
                onView={handlePageChange}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Form.Group controlId="newPageName">
            <Form.Label>New Page Name</Form.Label>
            <Form.Control
              type="text"
              value={newPageName}
              onChange={(e) => setNewPageName(e.target.value)}
              placeholder="Enter new page name"
            />
          </Form.Group>
          <Button className="mt-2" onClick={handleCreate}>
            Create Page
          </Button>
          {selectedPage && (
            <Card className="mt-4">
              <Card.Body>
                <h2>Manage Permissions for {selectedPage.name}</h2>
                <Row>
                  <Col>
                    {permissions.map((permission) => (
                      <Form.Check
                        key={permission._id}
                        type="checkbox"
                        label={permission.name}
                        value={permission._id}
                        checked={selectedPermissions.includes(permission._id)}
                        onChange={handlePermissionChange}
                      />
                    ))}
                  </Col>
                </Row>
                <Button className="mt-3" onClick={handleSave}>
                  Save Changes
                </Button>
                <Button
                  className="mt-3 ml-2"
                  variant="danger"
                  onClick={() => dispatch(deletePage(selectedPage._id))}
                >
                  Delete Page
                </Button>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Pages;
