import React, { useEffect, useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import SingleView from "./SingleIspView";
import { fetchIsps } from "stores/actions";
import PaginatedTable from "../../components/PaginatedTable";
import COLUMNS from "../../constants/columns";
import IspForm from "./AddForm";
import { usePermissions } from "../../hooks/usePermission";

const IspListView = ({ sendLog }) => {
  const dispatch = useDispatch();
  const { isps } = useSelector((state) => state.isps);
  const [selectedIsp, setSelectedIsp] = useState(null);
  const [ispToEdit, setIspToEdit] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);

  const ispData = useMemo(() => {
    return isps.map((isp) => {
      return {
        id: isp._id,
        name: isp?.site_name,
        dcName: isp.data_center_name,
        aristaSwName: isp?.arista_sw_name,
        aristaSwIp: isp?.arista_sw_ip,
      };
    });
  }, [isps]);

  const onView = (item) => {
    const selected = isps.find((isp) => item.id === isp._id);
    sendLog("Isps", "click-view-isp", selected?.name);
    setSelectedIsp(selected);
    setShowViewModal(true);
  };
  const onEdit = (item) => {
    const selected = isps.find((isp) => item.id === isp._id);
    sendLog("Isps", "click-edit-isp", selected?.name);
    setIspToEdit(selected);
    setShowModal(true);
  };
  const onDelete = (item) => {};

  return (
    <div>
      <PaginatedTable
        data={ispData}
        columns={COLUMNS["isp"]}
        itemsPerPage={25}
        onView={usePermissions(["ISPS_VIEW"]) && onView}
        onEdit={usePermissions(["ISPS_EDIT"]) && onEdit}
        // onDelete={onDelete}
      />
      <IspForm
        isp={ispToEdit}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      />
      <SingleView
        isp={selectedIsp}
        removeIsp={setSelectedIsp}
        showModal={showViewModal}
        closeModal={() => setShowViewModal(false)}
      />
    </div>
  );
};

export default IspListView;
