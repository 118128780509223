// HeadendGatewaysForm.js
import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GATEWAY_INIT } from "constants/headendInit";
import HostForm from "../../Host/AddForm";
import deepClone from "utils/deepClone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

const HeadendGatewaysForm = ({
  gateways,
  updateFormData,
  updateHostInformation,
  isEdit,
}) => {
  const { hosts } = useSelector((state) => state.host);
  const [showModal, setShowModal] = useState(false);
  const [hostToEdit, setEditToHost] = useState(null);
  const handleGatewayChange = (index, e) => {
    const { name, value } = e.target;
    const updatedGateways = [...gateways];
    updatedGateways[index] = { ...updatedGateways[index], [name]: value };
    updateFormData(updatedGateways);
  };

  const addGateway = () => {
    updateFormData([...gateways, GATEWAY_INIT]);
  };

  const onEdit = (item) => {
    console.log("ONEDIT ", item);
    setEditToHost(item);
    setShowModal(true);
  };

  const updateHost = (hostData) => {
    console.log("UPDATE HOST", hostData);
    const updatedGateways = deepClone(gateways);
    const index = updatedGateways.findIndex(
      (dir) => dir?.host.name === hostData.name
    );
    if (index > -1) {
      updatedGateways[index].host = hostData;
    }
    updateFormData(updatedGateways);
  };

  return (
    <Container>
      {gateways?.map((gateway, index) => (
        <Card key={index} className="mb-3">
          <Card.Header>Gateway {index + 1}</Card.Header>
          <Card.Body>
            <Form>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Host:</Form.Label>
                    <Row>
                      <Col>
                        <Form.Control
                          type="text"
                          name="name"
                          value={gateway?.host?.name}
                          // onChange={(e) => handleGatewayChange(index, e)}
                          className="disabled"
                          disabled={true}
                        />
                      </Col>
                      <Col sm={2}>
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="link"
                          onClick={() => onEdit(gateway?.host)}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group>
                    <Form.Label>Tag:</Form.Label>
                    <Form.Control
                      type="text"
                      name="tag"
                      value={gateway.tag}
                      onChange={(e) => handleGatewayChange(index, e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Eth IP:</Form.Label>
                    <Form.Control
                      type="text"
                      name="ethIp"
                      value={gateway.ethIp}
                      onChange={(e) => handleGatewayChange(index, e)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Wan IP:</Form.Label>
                    <Form.Control
                      type="text"
                      name="wanIp"
                      value={gateway.wanIp}
                      onChange={(e) => handleGatewayChange(index, e)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>FDQN:</Form.Label>
                    <Form.Control
                      type="text"
                      name="fdqn"
                      value={gateway.fdqn}
                      onChange={(e) => handleGatewayChange(index, e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      ))}
      {!isEdit && (
        <Button size="sm" variant="primary" onClick={addGateway}>
          + Gateway
        </Button>
      )}

      <HostForm
        host={hostToEdit}
        removeHost={setEditToHost}
        showModal={showModal}
        isEdit={true}
        updateHostDetails={updateHost}
        closeModal={() => {
          setEditToHost(null);
          setShowModal(false);
        }}
      />
    </Container>
  );
};

export default HeadendGatewaysForm;
