// HeadendAnalyticsForm.js
import React, { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ANALYTICS_INIT } from "constants/headendInit";
import deepClone from "utils/deepClone";
import HostForm from "../../Host/AddForm";
import { updateHost } from "stores/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
const HeadendAnalyticsForm = ({ analytics, updateFormData, isEdit }) => {
  const { categories } = useSelector((state) => state.categories);
  const { hosts } = useSelector((state) => state.host);
  const [showModal, setShowModal] = useState(false);
  const [hostToEdit, setEditToHost] = useState(null);

  const handleAnalyticsChange = (index, e) => {
    const { name, value } = e.target;
    const updatedAnalytics = deepClone(analytics);
    updatedAnalytics[index] = { ...updatedAnalytics[index], [name]: value };
    updateFormData(updatedAnalytics);
  };

  const dispatch = useDispatch();

  const updateAnalyticsHost = async (analyticsIndex, nodeIndex, e) => {
    const { name, value } = e.target;

    // Deep clone analytics to avoid direct mutation
    const updatedAnalytics = deepClone(analytics);

    // Update the value locally in the cloned object
    if (updatedAnalytics[analyticsIndex]?.nodes[nodeIndex]) {
      updatedAnalytics[analyticsIndex].nodes[nodeIndex][name] = value;
    }

    // Assume host is the updated node object, not clear from provided code
    const host = updatedAnalytics[analyticsIndex]?.nodes[nodeIndex];

    if (host) {
      // Dispatch updateHost action and await the result
      const updatedHost = await dispatch(updateHost(host));

      // Update the node with the result of the dispatch
      updatedAnalytics[analyticsIndex].nodes[nodeIndex] = updatedHost;
    }

    // Update the form data with the newly updated analytics object
    updateFormData(updatedAnalytics);
  };

  const onEdit = (host) => {
    setEditToHost(host);
    setShowModal(true);
  };

  const addAnalytics = () => {
    updateFormData([...analytics, ANALYTICS_INIT]);
  };

  return (
    <div>
      {analytics?.map((analytic, analyticsIndex) => (
        <Card key={analyticsIndex} className="mb-3">
          <Card.Header>
            {analytic.name || `Analytics ${analyticsIndex + 1}`}
          </Card.Header>
          <Card.Body>
            <Form>
              <Row className="mb-3">
                <Col>
                  <Form.Group>
                    <Form.Label>Nodes:</Form.Label>

                    {analytic?.nodes?.map((host, nodeIndex) => (
                      <Row className="mb-2">
                        <Col>
                          <Form.Control
                            as="select"
                            name="analyticType"
                            value={host.analyticType}
                            onChange={(e) =>
                              updateAnalyticsHost(analyticsIndex, nodeIndex, e)
                            }
                          >
                            {categories?.analytics?.map((cat) => (
                              <option key={cat._id} value={cat._id}>
                                {cat.name}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                        <Col>
                          <Form.Control
                            type="text"
                            name="name"
                            value={host.name}
                            className="disabled"
                            disabled={true}
                          />
                        </Col>
                        <Col>
                          <FontAwesomeIcon
                            icon={faEdit}
                            className="link"
                            onClick={() => onEdit(host)}
                          />
                        </Col>
                      </Row>
                    ))}
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group>
                    <Form.Label>Region:</Form.Label>
                    <Form.Control
                      type="text"
                      name="region"
                      value={analytic?.region}
                      onChange={(e) => handleAnalyticsChange(analyticsIndex, e)}
                      placeholder="Enter Region"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      ))}
      {!isEdit && (
        <Button size="sm" variant="primary" onClick={addAnalytics}>
          + Analytics
        </Button>
      )}

      <HostForm
        host={hostToEdit}
        removeHost={setEditToHost}
        showModal={showModal}
        isEdit={true}
        updateHostDetails={updateHost}
        closeModal={() => {
          setEditToHost(null);
          setShowModal(false);
        }}
      />
    </div>
  );
};

export default HeadendAnalyticsForm;
