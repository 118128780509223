import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "services/api";
import React from "react";

const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/categories`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const saveCategory = createAsyncThunk(
  "categories/saveCategory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`/categories`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const deleteCategory = createAsyncThunk(
  "categories/deleteCategory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await API.delete(`/categories/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export { fetchCategories, saveCategory, deleteCategory };
