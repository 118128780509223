import React, { useEffect } from "react";
import { useAuth } from "contexts";

const SilentRenew = () => {
  const { silentRenew } = useAuth();
  useEffect(() => {
    silentRenew();
  }, []);

  return <div>Silent Renew in Progress...</div>;
};

export default SilentRenew;
