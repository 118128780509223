// src/context/WebSocketContext.js

import React, { createContext, useContext, useEffect, useState } from "react";
import websocketService from "../services/ws";

const WebSocketContext = createContext(null);

const WebSocketProvider = ({ children }) => {
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    websocketService.connect();
    setConnected(true);
  }, []);

  return (
    <WebSocketContext.Provider value={{ websocketService, connected }}>
      {children}
    </WebSocketContext.Provider>
  );
};

const useWebSocket = () => useContext(WebSocketContext);

export { WebSocketProvider, useWebSocket };
