// reducers/CategorySlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  deleteCategory,
  fetchCategories,
  saveCategory,
} from "../actions/categories";

const categoryMap = {
  environment: "environments",
  infra: "infraCategories",
  headend: "headendCategories",
  host: "hostCategories",
  analytics: "analyticsCategories",
  appliance: "appliancesCategories",
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    headendCategories: [],
    appliancesCategories: [],
    analyticsCategories: [],
    infraCategories: [],
    hostCategories: [],
    environments: [],
    heModes: [],
    orchestrator: [],
    loading: false,
    error: null,
    categories: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.loading = false;
        const { type, _id } = action.payload;
        if (categoryMap[type]) {
          state[categoryMap[type]] = state[categoryMap[type]].filter(
            (env) => env._id !== _id
          );
        }
      })
      .addCase(saveCategory.fulfilled, (state, action) => {
        state.loading = false;
        const { type, _id } = action.payload;

        if (categoryMap[type]) {
          state[categoryMap[type]].push(action.payload);
        }
      });
  },
});

export default categoriesSlice.reducer;
