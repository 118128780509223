const percentageVariant = (percentage) => {
  if (percentage > 70) return "danger";
  if (percentage > 60) return "warning";
  return "success";
};

const convertToGB = (memory) => {
  if (memory.toLowerCase().endsWith("gb")) {
    return parseFloat(memory); // Already in GB
  } else if (memory.toLowerCase().endsWith("mb")) {
    return parseFloat(memory) / 1024; // Convert MB to GB
  }
  return 0;
};

export { percentageVariant, convertToGB };
