import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PaginatedTable } from "components";
import Loader from "components/Loader";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

const ReportTable = () => {
  // Define columns that match the fields in your reports
  const { nmap, loading } = useSelector((state) => state.security);

  const columns = [
    { dataField: "name", text: "Name" },
    { dataField: "fqdn", text: "FQDN" },
    { dataField: "reachable", text: "Reachable" },
    { dataField: "ports", text: "Ports" },
    { dataField: "state", text: "State" },
    { dataField: "services", text: "Services" },
  ];

  // Format the reports data to match the expected structure of PaginatedTable
  const formatPortsData = (report, field) => {
    if (report.ports.length > 0) {
      return report.ports.map((port, index) => (
        <React.Fragment key={index}>
          {port[field]}
          {report.ports.length > 1 && <br />}
        </React.Fragment>
      ));
    }
    return report.reachable ? "Restricted" : "";
  };

  const formattedData = nmap?.reports?.map((report) => ({
    ...report,
    reachable: report.reachable ? "completed" : "failed",
    ports: formatPortsData(report, "port"),
    state: formatPortsData(report, "state"),
    services: formatPortsData(report, "service"),
  }));

  return (
    <>
      <Row className=" mt-3 mb-3">
        <Col>
          <div>
            <h5>
              <b>Nmap Report</b>
            </h5>
            <small className="sub-info">
              All Nmap scanned reports are available here
            </small>
          </div>
        </Col>
        <Col className="col-auto">
          <div className="updated-box">
            {loading["nmap"] ? (
              <Loader />
            ) : (
              <>
                <FontAwesomeIcon
                  icon={faClockRotateLeft}
                  className="green cl"
                />{" "}
                <span>last scanned : </span>
                <b>{nmap?.lastUpdated}</b>
              </>
            )}
          </div>
        </Col>
      </Row>
      <Card>
        <Card.Body>
          {loading["nmap"] ? (
            <Loader />
          ) : formattedData?.length > 0 ? (
            <PaginatedTable
              data={formattedData || []}
              columns={columns}
              perPage={25}
              downloadRequired={true}
              fileName={`nmap-report-${nmap.lastUpdated}`}
              mergeCells={["name", "fqdn", "reachable"]}
            />
          ) : (
            <div></div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default ReportTable;
