import React, { useCallback, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import OrchestratorsForm from "./AddForm";
import { fetchOrchestratorsById } from "stores/actions";
import { usePermissions } from "hooks/usePermission";
import { useNavigate } from "react-router-dom";

const OrchestratorsListView = ({ sendLog }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const headends = useSelector((state) => state.headend.headends, shallowEqual);
  const orchestrators = useSelector(
    (state) => state.orchestrators.orchestrators,
    shallowEqual
  );

  const [orchestratorToEdit, setOrchestratorsToEdit] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const orchestratorData = useMemo(() => {
    return orchestrators.map((orchestrator) => ({
      id: orchestrator._id,
      name: orchestrator?.name,
      headends: orchestrator.headends
        .map(
          (headend) =>
            `${headend.code ? headend.code + "-" : ""}${headend.name}`
        )
        .join(", "),
      category: orchestrator?.category?.name,
    }));
  }, [orchestrators, headends]);

  const onView = useCallback(
    (item) => {
      const selected = orchestrators.find((isp) => item.id === isp._id);
      dispatch(fetchOrchestratorsById(item.id));
      sendLog("Orchestrators", "click-view-orchestrators", selected?.name);
      navigate(`/orchestrators/${selected?._id}`);
    },
    [dispatch, orchestrators]
  );

  const onEdit = useCallback(
    (item) => {
      const selected = orchestrators.find(
        (orchestrator) => item.id === orchestrator._id
      );
      dispatch(fetchOrchestratorsById(item.id));
      sendLog("Orchestrators", "click-edit-orchestrators", selected?.name);
      setOrchestratorsToEdit(selected);
      setShowModal(true);
    },
    [orchestrators]
  );

  return (
    <div>
      <PaginatedTable
        data={orchestratorData}
        columns={COLUMNS["orchestrators"]}
        itemsPerPage={25}
        onView={usePermissions(["WRAPPERS_VIEW"]) && onView}
        onEdit={usePermissions(["WRAPPERS_EDIT"]) && onEdit}
        // onDelete={onDelete}
      />
      <OrchestratorsForm
        orchestrators={orchestratorToEdit}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        // updateHostDetails={}
      />
    </div>
  );
};

export default React.memo(OrchestratorsListView);
