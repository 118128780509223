// slices/tenantSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createTenant, fetchTenants, fetchTenantSummary } from "../actions";

const tenantSlice = createSlice({
  name: "organisations",
  initialState: {
    tenants: [], // Initial state for tenants
    loading: {
      fetchTenants: false,
      create: false,
      summary: false,
    },
    error: null,
    orgSummary: {},
  },

  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTenants.pending, (state) => {
        state.loading["fetchTenants"] = true;
        state.error = null;
      })
      .addCase(fetchTenants.fulfilled, (state, action) => {
        state.loading["fetchTenants"] = false;
        state.tenants = action.payload;
      })
      .addCase(fetchTenants.rejected, (state, action) => {
        state.loading["fetchTenants"] = false;
        state.error = action.payload;
      })
      .addCase(fetchTenantSummary.pending, (state) => {
        state.loading["create"] = true;
      })
      .addCase(fetchTenantSummary.fulfilled, (state, action) => {
        state.loading["create"] = false;
        state.orgSummary = action.payload;
      })
      .addCase(fetchTenantSummary.rejected, (state, action) => {
        state.loading["create"] = false;
        state.error = action.payload;
      })
      .addCase(createTenant.pending, (state) => {
        state.loading["create"] = true;
        state.error = null;
      })
      .addCase(createTenant.fulfilled, (state, action) => {
        state.loading["create"] = false;
        state.tenants = [action.payload, ...state.tenants];
      })
      .addCase(createTenant.rejected, (state, action) => {
        state.loading["create"] = false;
        state.error = action.payload;
      });
  },
});

export const {} = tenantSlice.actions;

export default tenantSlice.reducer;
