// reducers/hostSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchOrchestratorsById,
  fetchOrchestrators,
  saveOrchestrators,
  updateOrchestrators,
  onboardConcerto,
} from "../actions";

const orchestratorSlice = createSlice({
  name: "orchestrators",
  initialState: {
    orchestrators: [],
    orchestrator: {},
    loading: {
      fetchOrchestrators: false,
      fetchOrchestrators: false,
      saveOrchestrators: false,
      onboardConcerto: false,
    },
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrchestrators.pending, (state) => {
        state.loading["fetchOrchestrators"] = true;
      })
      .addCase(fetchOrchestrators.fulfilled, (state, action) => {
        state.loading["fetchOrchestrators"] = false;
        state.orchestrators = action.payload;
      })
      .addCase(fetchOrchestrators.rejected, (state, action) => {
        state.loading["fetchOrchestrators"] = false;
        state.error = action.payload;
      })
      .addCase(fetchOrchestratorsById.pending, (state) => {
        state.loading["fetchOrchestrators"] = true;
      })
      .addCase(fetchOrchestratorsById.fulfilled, (state, action) => {
        state.loading["fetchOrchestrators"] = false;
        state.orchestrator = action.payload;
      })
      .addCase(fetchOrchestratorsById.rejected, (state, action) => {
        state.loading["fetchOrchestrators"] = false;
        state.error = action.payload;
      })
      .addCase(saveOrchestrators.pending, (state) => {
        state.loading["saveOrchestrators"] = true;
      })
      .addCase(saveOrchestrators.fulfilled, (state, action) => {
        state.loading["saveOrchestrators"] = false;
        state.orchestrators = [action.payload, ...state.orchestrators];
      })
      .addCase(saveOrchestrators.rejected, (state, action) => {
        state.loading["saveOrchestrators"] = false;
        state.error = action.payload;
      })
      .addCase(updateOrchestrators.pending, (state) => {
        state.loading["updateOrchestrators"] = true;
      })
      .addCase(updateOrchestrators.fulfilled, (state, action) => {
        state.loading["updateOrchestrators"] = false;
        state.orchestrators = state.orchestrators.map((wrap) => {
          if (wrap._id === action?.payload?._id) {
            return action?.payload;
          } else return wrap;
        });
      })
      .addCase(updateOrchestrators.rejected, (state, action) => {
        state.loading["updateOrchestrators"] = false;
        state.error = action.payload;
      })
      .addCase(onboardConcerto.pending, (state) => {
        state.loading["onboardConcerto"] = true;
      })
      .addCase(onboardConcerto.fulfilled, (state, action) => {
        state.loading["onboardConcerto"] = false;
      })
      .addCase(onboardConcerto.rejected, (state, action) => {
        state.loading["onboardConcerto"] = false;
      });
  },
});

export const {} = orchestratorSlice.actions;
export default orchestratorSlice.reducer;
