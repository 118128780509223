import React, { useEffect, useMemo, useState } from "react";
import {
  Container,
  Card,
  ListGroup,
  Modal,
  Row,
  Col,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import { useDispatch, useSelector } from "react-redux";
import { fetchTenantSummary } from "stores/actions";

const SingleView = ({ organisation, showModal, closeModal }) => {
  const [key, setKey] = useState("tenants");
  const dispatch = useDispatch();
  const { orgSummary: summary } = useSelector((state) => state.organisation);

  useEffect(() => {
    if (organisation?._id) {
      dispatch(fetchTenantSummary(organisation._id));
    }
  }, [organisation?._id, dispatch]);

  const formatData = (list, formatter) => list?.map(formatter) || [];

  const tenantsData = useMemo(
    () =>
      formatData(summary?.childOrganisations?.list, (org) => ({
        id: org._id,
        name: org?.name,
        parentOrg: org?.parentOrg,
        plan: org?.plan,
      })),
    [summary?.childOrganisations]
  );

  const headendData = useMemo(
    () =>
      formatData(summary?.headends?.list, (head) => ({
        id: head?._id,
        name: head?.name,
        infra: head?.infra?.name,
        category: head?.category?.name,
        fqdn: head?.fqdn,
        active: head?.active ? "Yes" : "No",
      })),
    [summary?.headends]
  );

  const hostsData = useMemo(
    () =>
      formatData(summary?.hosts?.list, (host) => ({
        id: host._id,
        name: host?.name,
        headend: host?.headendName,
        category: host?.category?.name,
        status: host?.status,
        owner: host?.ownerOrgName,
        active: host?.active ? "Yes" : "No",
      })),
    [summary?.hosts]
  );

  const renderCard = (title, count, color) => (
    <Col className="mb-3" sm={12} md={4}>
      <Card className={`box ${color} bg`}>
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <h2>{count}</h2>
          <Card.Text>Total</Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );

  const renderTabContent = (title, data, columns) => (
    <>
      <Card.Title>
        <Row>
          <Col>
            <b>{title}</b>
          </Col>
          <Col className="col-auto">
            <Badge>{data.length}</Badge>
          </Col>
        </Row>
      </Card.Title>
      <Card.Text>{`Lists all ${title.toLowerCase()} owned by the organisation`}</Card.Text>
      {data.length > 0 ? (
        <PaginatedTable
          data={data}
          columns={COLUMNS[columns]}
          itemsPerPage={10}
        />
      ) : (
        <center>No {title} found</center>
      )}
    </>
  );

  const renderContactDetails = (contacts) => (
    <Card className="mb-4">
      <Card.Body>
        <Card.Title>
          <Row>
            <Col>
              <b>Contact Details</b>
            </Col>
            <Col className="col-auto">
              <Badge>{contacts.length}</Badge>
            </Col>
          </Row>
        </Card.Title>
        <Card.Text>List provides all contact details</Card.Text>
        {contacts.map((contact) => (
          <ListGroup variant="flush" key={contact._id}>
            <ListGroup.Item>
              <b>Type of contact: {contact.contact_type}</b>
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Name: {contact.name}</b>
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Email: {contact.email.join(", ")}</b>
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Timezone: {contact.timezone}</b>
            </ListGroup.Item>
          </ListGroup>
        ))}
      </Card.Body>
    </Card>
  );

  return (
    <Modal show={showModal} onHide={closeModal} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{organisation?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg">
        <Container>
          <Row>
            <Col>
              <Card className="mb-3">
                <Row>
                  <Col md={4}>
                    <Card.Body>
                      <Card.Title>
                        <b>Basic Info</b>
                      </Card.Title>
                    </Card.Body>
                  </Col>
                  <Col md={8}>
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        Name:<b> {organisation?.name}</b>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        Parent Org: <b> {organisation?.parentOrg}</b>
                      </ListGroup.Item>
                    </ListGroup>
                  </Col>
                </Row>
              </Card>
              {organisation?.contacts?.length > 0 &&
                renderContactDetails(organisation.contacts)}
            </Col>
            <Col>
              <Row>
                {renderCard(
                  "Tenants",
                  summary?.childOrganisations?.total,
                  "blue"
                )}
                {renderCard("Headends", summary?.headends?.total, "red")}
                {renderCard("Hosts", summary?.hosts?.total, "orange")}
              </Row>
            </Col>
          </Row>
          <Card>
            <Card.Body>
              <Tabs
                id="controlled-tabs"
                activeKey={key}
                onSelect={setKey}
                className="p-0"
              >
                <Tab eventKey="tenants" title="Tenants" className="p-3">
                  {renderTabContent("Tenants", tenantsData, "tenants")}
                </Tab>
                <Tab eventKey="headends" title="Headends" className="p-3">
                  {renderTabContent("Headends", headendData, "headend")}
                </Tab>
                <Tab eventKey="hosts" title="Hosts" className="p-3">
                  {renderTabContent("Hosts", hostsData, "host")}
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default SingleView;
