import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Tab,
  Nav,
  Pagination,
} from "react-bootstrap";
import LoginActivities from "./Components/LoginActivities";
import UniqueUsers from "./Components/UniqueUsers";
import ActivityFrequency from "./Components/ActivityFrequency";
import LoginFrequency from "./Components/LoginFrequency";
import NavigationFlow from "./Components/NavigationFlow";
import PageVisits from "./Components/PageVisits";
import UserActions from "./Components/UserActions";
import BrowserUsage from "./Components/BrowserUsage";
import LanguageUsage from "./Components/LanguageUsage";
import PageActivity from "./Components/PageActivity";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchActivityFrequency,
  fetchBrowserUsage,
  fetchLanguageUsage,
  fetchLoggedInToday,
  fetchLoginFrequency,
  fetchNavigationFlow,
  fetchPageActivity,
  fetchPageVisit,
  fetchUniqueUsers,
  fetchUserActions,
} from "stores/actions";

const Activities = () => {
  const dispatch = useDispatch();
  const {
    loginsToday,
    uniqueUsers,
    activityFrequency,
    loginFrequency,
    navigationFlow,
    pageVisits,
    userActions,
    browserUsage,
    languageUsage,
    pageActivity,
    loading,
  } = useSelector((state) => state.userActivities);

  useEffect(() => {
    dispatch(fetchActivityFrequency());
    dispatch(fetchLoggedInToday());
    dispatch(fetchUniqueUsers());
    dispatch(fetchLoginFrequency());
    dispatch(fetchNavigationFlow());
    dispatch(fetchPageVisit());
    dispatch(fetchUserActions());
    dispatch(fetchBrowserUsage());
    dispatch(fetchLanguageUsage());
    dispatch(fetchPageActivity());
  }, []);

  return (
    <Container fluid>
      <div className=" mt-3 mb-3">
        <h5>
          <b>User Acitivty</b>
        </h5>
        <small className="sub-info">
          Displays all activities done by the users
        </small>
      </div>
      <Card>
        <Card.Body>
          <Tab.Container id="left-tabs-example" defaultActiveKey="logins-today">
            <Row className="">
              <Col sm={2}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="logins-today">Login Today</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="unique-users">Unique Users</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="activity-frequency">
                      Activity Frequency
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="login-frequency">
                      Login Frequency
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="navigation-flow">
                      Navigation Flow
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="page-visits">Page Visits</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="user-actions">User Actions</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="browser-usage">Browser Usage</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="language-usage">
                      Language Usage
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="page-activity">Page Activity</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={10}>
                <Tab.Content>
                  <Tab.Pane eventKey="logins-today">
                    <LoginActivities loginsToday={loginsToday} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="unique-users">
                    <UniqueUsers uniqueUsers={uniqueUsers} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="activity-frequency">
                    <ActivityFrequency activityFrequency={activityFrequency} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="login-frequency">
                    <LoginFrequency loginFrequency={loginFrequency} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="navigation-flow">
                    <NavigationFlow navigationFlow={navigationFlow} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="page-visits">
                    <PageVisits pageVisits={pageVisits} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="user-actions">
                    <UserActions userActions={userActions} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="browser-usage">
                    <BrowserUsage browserUsage={browserUsage} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="language-usage">
                    <LanguageUsage languageUsage={languageUsage} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="page-activity">
                    <PageActivity pageActivity={pageActivity} />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Activities;
