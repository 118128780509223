import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone, faCopy } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { showAlert } from "stores/slices/alertSlice";

const CopyToClipboard = ({ text }) => {
  const dispatch = useDispatch();
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(text);

      dispatch(
        showAlert({
          message: `"${text}" cpoied`,
          variant: "success",
        })
      );
    } catch (err) {
      console.error("Failed to copy:", err);
      dispatch(
        showAlert({
          message: "Failed to copy text to clipboard.",
          variant: "danger",
        })
      );
    }
  };

  return (
    <FontAwesomeIcon
      onClick={copyToClipboard}
      icon={faClone}
      className="copy-btn"
    />
  );
};

export default CopyToClipboard;
