import React from "react";
import { Sankey, Tooltip, ResponsiveContainer } from "recharts";

const defaultData = {
  nodes: [
    { name: "Start" },
    { name: "Dashboard" },
    { name: "Profile" },
    { name: "Settings" },
    { name: "Logout" },
  ],
  links: [
    { source: 0, target: 1, value: 100 },
    { source: 1, target: 2, value: 80 },
    { source: 2, target: 3, value: 50 },
    { source: 3, target: 4, value: 30 },
  ],
};

const isValidSankeyData = (data) => {
  return (
    data &&
    Array.isArray(data.nodes) &&
    Array.isArray(data.links) &&
    data.nodes.every(
      (node) => node && typeof node.name === "string" && node.name.trim() !== ""
    ) &&
    data.links.every(
      (link) =>
        link &&
        typeof link.source === "number" &&
        typeof link.target === "number" &&
        typeof link.value === "number"
    )
  );
};

const NavigationFlow = ({ navigationFlow }) => {
  const data = isValidSankeyData(navigationFlow) ? navigationFlow : defaultData;

  return (
    <div>
      <div className="mt-2 mb-3">
        <h5>
          <b>Navigation Flow</b>
        </h5>
      </div>
      <ResponsiveContainer width="100%" height={500}>
        <Sankey
          width={960}
          height={500}
          data={data}
          nodePadding={10}
          margin={{
            top: 20,
            bottom: 20,
            left: 20,
            right: 20,
          }}
        >
          <Tooltip />
        </Sankey>
      </ResponsiveContainer>
    </div>
  );
};

export default NavigationFlow;
