import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { getUser, login, logout, silentRenew } from "../services/auth";
import { useDispatch } from "react-redux";
import { fetchUser } from "stores/actions";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const fetchTheUser = useCallback(
    async (email) => {
      if (!localStorage.getItem("user")) {
        const user = await dispatch(fetchUser(email));
        localStorage.setItem("user", JSON.stringify(user?.payload[0]));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    console.log("LOADING", user);

    getUser()
      .then((user) => {
        setUser(user);

        localStorage.setItem("token", user?.access_token);
        if (user?.profile?.email) fetchTheUser(user?.profile?.email);
        setLoading(false);
      })
      .catch(() => {
        setUser(null);
        setLoading(false);
      });
  }, [fetchTheUser]);

  return (
    <AuthContext.Provider
      value={{ user, loading, login, logout, silentRenew, setUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
