import React, { useEffect } from "react";
import { useAuth } from "contexts/AuthContext";
import { Card } from "react-bootstrap";

const Login = () => {
  const { login } = useAuth();

  useEffect(() => {
    login();
  }, [login]);

  return (
    <div
      className="bg-image"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Card className="p-4 w-50">
        <Card.Body>
          <center>Redirecting to login...</center>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Login;
