import "./App.scss";
import AlertComponent from "./components/Alert";
import ClientRoutes from "./routes";
function App() {
  return (
    <div className="App">
      <AlertComponent />
      <ClientRoutes />
    </div>
  );
}

export default App;
