import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import { usePermissions } from "hooks/usePermission";
import { useNavigate } from "react-router-dom";

const DCListView = ({ sendLog }) => {
  const navigate = useNavigate();
  const { dataCenters } = useSelector((state) => state.dataCenter);

  const dcData = useMemo(() => {
    return dataCenters.map((dc) => {
      return {
        ...dc,
        name: dc?.data_center,
        aristaSwName: dc?.arista_sw?.name,
        gatewayNames: dc?.gateway?.map((gateway) => gateway?.name).join(","),
      };
    });
  }, [dataCenters]);

  const onView = (item) => {
    sendLog("Data Center", "click-view-dc", item?.name);
    navigate(`/dc/${item?._id}`);
  };

  return (
    <div>
      <PaginatedTable
        data={dcData}
        columns={COLUMNS["dc"]}
        itemsPerPage={25}
        onView={usePermissions(["DC_VIEW"]) && onView}
      />
    </div>
  );
};

export default DCListView;
