import randomNumber from "utils/randomNumber";

const DIRECTOR_INIT = {
  host: "",
  isPrimary: true,
  fqdn: "",

  guiCredentials: [
    {
      user_type: "", //local/tacacs
      username: "",
      password: "",
      keyfile: "",
      jumpServer: "", // Reference to different hosts
      isTacacs: false,
    },
  ],
  sshCredentials: [
    {
      username: "",
      password: "",
      keyfile: "",
      port: 22, //default port
      passphrase: "",
    },
  ],
};

const CONTROLLER_INIT = {
  host: "",
  ip: "",
  remoteId: "",
  remoteKey: "",
  localId: "",
  localKey: "",
};

const ANALYTICS_INIT = {
  name: "",
  analytics: [],
  search: [],
};

const APPLIANCES_INIT = {
  host: "",
  isManaged: true,
  category: "",
};

const GATEWAY_INIT = {
  host: "",
  name: "",
  tag: "",
  ethIp: "",
  wanIp: "",
  fdqn: "",
};

const ORG_INIT = {
  id: randomNumber(),
  org: "",
  tenants: [],
};

const HEADEND_INIT = {
  category: "",
  name: "",
  envType: "",
  infra: "",
  active: true,
  organisations: [ORG_INIT],
  directors: [DIRECTOR_INIT],
  controllers: [CONTROLLER_INIT],
  vms: [
    {
      host_id: "",
      sshCredentials: {
        username: "",
        password: "",
        keyfile: "",
        passphrase: "",
      },
    },
  ],
  analytics: [ANALYTICS_INIT],
  appliances: [APPLIANCES_INIT],
  gateways: [GATEWAY_INIT],
};

const HOST_INIT = {
  name: "",
  active: true,
  ips: [{ type: "Management", private_ip: "", public_ip: "" }],
  wanIps: [{ type: "WAN", private_ip: "", public_ip: "" }],
  sshCredentials: [
    {
      username: "",
      password: "",
      keyFile: "",
      passhPhrase: "",
    },
  ],
  ipmi_ip: "",
  cpu: "",
  no_of_cpu: "",
  ram: "",
  hard_disk: "",
  type: "",
  provider: "",
  region: "",
  memory: "",
  location: "",
  model: "",
  softwareVersion: "",
};

const PROVIDER_INIT = {
  isp: "",
  bandwidth: "",
  local_as: "",
  remote_as: "",
  versa_side_ip: "",
  isp_side_ip: "",
  circuit_id: "",
  contacts: [
    {
      type: "",
      location: "",
      values: "",
      web_link: "",
      username: "",
      password: "",
    },
  ],
};

const ISP_INIT = {
  site_name: "",
  data_center_name: "",
  local_ip: "",
  arista_sw_ip: "",
  connectivity_providers: [PROVIDER_INIT],
};

const CONTACT_INIT = {
  contact_type: "",
  name: "",
  email: [""], // Initialize with an empty email
  // phone: "",
  timezone: "",
};

const ORGNAISATION_INIT = {
  name: "",
  contacts: [CONTACT_INIT],
};

export {
  HOST_INIT,
  HEADEND_INIT,
  ORG_INIT,
  DIRECTOR_INIT,
  CONTROLLER_INIT,
  ANALYTICS_INIT,
  APPLIANCES_INIT,
  GATEWAY_INIT,
  PROVIDER_INIT,
  ISP_INIT,
  CONTACT_INIT,
  ORGNAISATION_INIT,
};
